import {constructPolygons} from "./UtilityFunctions";

function computeOmbriereSimple(instalationInfo) {
    // google variable api
    const google = window.google;

    // points of polygon
    const p1 = new google.maps.LatLng({
        lat: instalationInfo.coordinates[0].lat,
        lng: instalationInfo.coordinates[0].lng
    });
    const p2 = new google.maps.LatLng({
        lat: instalationInfo.coordinates[1].lat,
        lng: instalationInfo.coordinates[1].lng
    });
    const p4 = new google.maps.LatLng({
        lat: instalationInfo.coordinates[3].lat,
        lng: instalationInfo.coordinates[3].lng
    });

    // distances between points
    const distance12 = google.maps.geometry.spherical.computeDistanceBetween(p1, p2);
    const distance14 = google.maps.geometry.spherical.computeDistanceBetween(p1, p4);

    const formatPaysage = instalationInfo.paysage;

    let width = 0; // metre 10 * 5
    let height = 0;
    const margeLargeur = instalationInfo.margeBetweenLinesOfModules;
    const margeHauteur = instalationInfo.margeBetweenModules;

    // parallele coté long
    if (distance12 < distance14 && !formatPaysage) {
        width = instalationInfo.height;
        height = instalationInfo.width;
    } else if (distance12 > distance14 && formatPaysage) {
        width = instalationInfo.width;
        height = instalationInfo.height;
    } else if (distance12 > distance14 && !formatPaysage) {
        width = instalationInfo.height;
        height = instalationInfo.width;
    } else if (distance12 < distance14 && formatPaysage) {
        width = instalationInfo.width;
        height = instalationInfo.height;
    }

    const heading = google.maps.geometry.spherical.computeHeading(p1, p2);
    const headingHauteur = google.maps.geometry.spherical.computeHeading(p1, p4);

    let boolLargeur = true;
    let boolHauteur = true;
    let listOfPoints = [];
    let lineListOfPoints = [];
    let i = 0;
    let j = 0;
    let nextPoint = "";
    lineListOfPoints.push(p1);
    let nextPointHauteur = "";
    let conteur = !formatPaysage ? 5 : 11;
    const valueToAddCounteur = !formatPaysage ? 6 : 12;
    let valueToMultiply = !formatPaysage ? 3 : 6;

    while (boolHauteur) {
        while (boolLargeur) {
            if (i % 2 === 0) {
                nextPoint = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng({
                    lat: lineListOfPoints[i].lat(),
                    lng: lineListOfPoints[i].lng()
                }), width, heading);
                lineListOfPoints.push(nextPoint);
                i = i + 1;
            } else {
                if (i === conteur) {
                    nextPoint = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng({
                        lat: lineListOfPoints[i].lat(),
                        lng: lineListOfPoints[i].lng()
                    }), margeLargeur, heading);
                    if (google.maps.geometry.spherical.computeDistanceBetween(lineListOfPoints[0], google.maps.geometry.spherical.computeOffset(new google.maps.LatLng({
                        lat: nextPoint.lat(),
                        lng: nextPoint.lng()
                    }), width * valueToMultiply, heading)) < distance12) {
                        conteur = conteur + valueToAddCounteur;
                    } else {
                        break;
                    }
                } else {
                    nextPoint = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng({
                        lat: lineListOfPoints[i].lat(),
                        lng: lineListOfPoints[i].lng()
                    }), margeHauteur, heading);
                }
                if (google.maps.geometry.spherical.computeDistanceBetween(lineListOfPoints[0], google.maps.geometry.spherical.computeOffset(new google.maps.LatLng({
                    lat: nextPoint.lat(),
                    lng: nextPoint.lng()
                }), width, heading)) < distance12) {
                    lineListOfPoints.push(nextPoint);
                    i = i + 1;
                } else {
                    boolLargeur = false;
                }
            }
        }
        listOfPoints.push(lineListOfPoints);
        lineListOfPoints = [];
        i = 0;
        conteur = !formatPaysage ? 5 : 11;
        valueToMultiply = !formatPaysage ? 3 : 6;

        if (j % 2 === 0) {
            nextPointHauteur = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng({
                lat: listOfPoints[j][0].lat(),
                lng: listOfPoints[j][0].lng()
            }), height, headingHauteur);
        } else {
            nextPointHauteur = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng({
                lat: listOfPoints[j][0].lat(),
                lng: listOfPoints[j][0].lng()
            }), margeHauteur, headingHauteur);
        }

        if (google.maps.geometry.spherical.computeDistanceBetween(listOfPoints[0][0], nextPointHauteur) < distance14) {
            lineListOfPoints.push(nextPointHauteur);
            j = j + 1;
        } else {
            boolHauteur = false;
        }
        boolLargeur = true;
    }

    return constructPolygons(listOfPoints);
}

export default computeOmbriereSimple;