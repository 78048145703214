import React from 'react'
import {springboot} from "../config/api"

/**
 * Composant qui calcule un productible : A partir du formulaire stocker dans le store redux, je récupère les orientations
 * et les inclinaison de tous les champs. Je met les inclinaisons et les orientation dans les states prevOrientation et prevInclinaison
 * et finalement je boucle sur ces derniers et j'appel l'api productible le nombre de fois = nombre de champs.
 */
/*
const equal = require('fast-deep-equal');


function CalculateProductible1() {
    const reduxForms = useSelector(state => state.modulesReducer.forms);
    const reduxPower = useSelector(state => state.headerProjectReducer.power);
    const reduxUser = useSelector(state => state.userReducer.user);
    const reduxMap = useSelector(state => state.mapReducer.map?.center);

    const [prevOrientation, setPrevOrientation] = useState([]);
    const [prevInclinaison, setPrevInclinaison] = useState([]);
    const [prevApi, setPrevApi] = useState([]);
    const [prevLoss, setPrevLoss] = useState([]);
    const [prevMountingPlace, setPrevMountingPlace] = useState([]);
    const [responseApi, setResponseApi] = useState(undefined);
    const [pvOutTab, setPvOutTab] = useState([]);
    const [powersState, setPowersState] = useState([]);

    const dispatch = useDispatch();

    let formActive = [];

    useEffect(() => {
        for (let i = 0; i < reduxForms.length; i++) {
            if (reduxForms[i].form && Object.keys(reduxForms[i].form).length !== 0)
                formActive.push(reduxForms[i]);
        }
        console.log("FORM ACTIF : ", formActive)
    }, []);


    useEffect(() => {
        try {
            let orientations = [];
            let inclinaisons = [];
            let powers = [];
            let implatationType = [];
            let apis = [];
            let losses = [];
            let mountingPlaces = [];
            formActive.map((form, i) => { //i
                if (form.form && Object.keys(form.form).length !== 0) {
                    implatationType.push(form?.form?.implantationType);
                    /**
                     * si form?.form?.implantationType === "new Implatation Type" ou === "chapeau chinois"{
                     * orientations.push([form?.form?.orientation, form?.form?.orientation - 180])
                     * inclinaisons.push([form?.form?.inclinaison, form?.form?.inclinaison ])
                     * powers.push([reduxPower[i]/2 , reduxPower[i]/2])
                     * }
                     *
                     * sinon la suite

                    //if(form?.form?.implantationType === "Hangar Symétrique" || form?.form?.implantationType === "Toiture terrasse Est-Ouest"){
                    if (form?.form?.implantationType === "Hangar Symétrique") {
                        if (form?.form?.orientation >= 180) {
                            orientations.push(form?.form?.orientation, form?.form?.orientation - 180);
                        } else {
                            orientations.push(form?.form?.orientation, form?.form?.orientation + 180);
                        }
                        inclinaisons.push(form?.form?.inclinaison, form?.form?.inclinaison);
                        //orientations.push(form?.form?.orientation)
                        //orientations.push(form?.form?.orientation - 180)
                        //console.log("hangar inclinaison = ", inclinaisons)
                        //console.log("hangar reduxPower = ", reduxPower)
                        //console.log("hangar reduxPower[i] = ", i, [reduxPower[i]/2 , reduxPower[i]/2])
                        powers.push(reduxPower[i] / 2, reduxPower[i] / 2);
                        //console.log("hangar powers = ", powers)
                        //powers.push([reduxPower/2 , reduxPower/2])
                        apis.push(form?.form?.api, form?.form?.api);
                        losses.push(form?.form?.loss, form?.form?.loss);
                        mountingPlaces.push(form?.form?.mountingPlace, form?.form?.mountingPlace);
                    } else {
                        orientations.push(form?.form?.orientation);
                        inclinaisons.push(form?.form?.inclinaison);
                        apis.push(form?.form?.api);
                        losses.push(form?.form?.loss);
                        mountingPlaces.push(form?.form?.mountingPlace);
                        powers.push(reduxPower[i]);
                        //powers.push(reduxPower)
                    }
                }
            })

            // si powers not equals powersState
            //setPower(powers)
            //console.log("hangar powersState, powers 1: ",powersState, powers)
            if (!equal(powersState, powers)) {
                //console.log("hangar powersState, powers 2: ",powersState, powers)
                setPowersState(powers);
                //console.log("hangar powersState, powers 3: ",powersState, powers)
            }
            if (!equal(prevOrientation, orientations)) {
                setPrevOrientation(orientations);
            }
            if (!equal(prevInclinaison, inclinaisons)) {
                setPrevInclinaison(inclinaisons);
            }
            if (!equal(prevApi, apis)) {
                setPrevApi(apis);
            }
            if (!equal(prevLoss, losses)) {
                setPrevLoss(losses);
            }
            if (!equal(prevMountingPlace, mountingPlaces)) {
                setPrevMountingPlace(mountingPlaces);
            }
        } catch (error) {
            console.log("error", error)
        }
        //console.log("*********** FROMULAIRE USE EFFECT************", reduxForms)
        //}, [])
    }, [formActive, reduxPower]);

    const callApi = async (orientation, inclinaison, power) => {
        let orientationParam = 0;
        if (orientation !== 360) {
            orientationParam = orientation;
        }
        //console.log("*********** FROMULAIRE ************", reduxForms[0].form)
        //console.log("*********** FROMULAIRE API ************", reduxForms)
        let i = 0
        while (i < formActive.length && formActive[i].form === undefined) {
            i = i + 1
        }
        console.log("i = ", i)
        if (formActive[i].form.api === "Solargis") {
            console.log("formActive[0].form.coordinates.lat", reduxMap.lat, reduxMap.lng, orientationParam, inclinaison)
            const result = await springboot.get(`/api/solargis/getProductible?lat=${reduxMap.lat}&lng=${reduxMap.lng}&orientation=${orientationParam}&inclinaison=${inclinaison}&power=${power}`,
                {headers: {Authorization: `Bearer ${reduxUser.token}`}})
            console.log("result solargis", result)

            let ghi = 0;
            for (let i = 0; i < 12; i++) {
                ghi = ghi + (+result.data.irradiation.reference.ghm.monthlies[i]);
            }
            dispatch(getGhi(ghi.toFixed(2)))
            return result;

        } else {
            // call pvgis api
            //console.log("dans callAPI/PVGis")
            orientationParam = orientationParam - 180;
            let pvgisResult = await springboot.get(`/api/pvgis/getProductible?lat=${reduxMap.lat}&lon=${reduxMap.lng}&angle=${inclinaison}&aspect=${orientationParam}&peakpower=${power}&loss=${formActive[i].form.loss}&mountingplace=${formActive[i].form.mountingPlace}`,
                {headers: {Authorization: `Bearer ${reduxUser.token}`}})
            console.log("result pvgis", pvgisResult)
            return pvgisResult;
        }

    }

    useEffect(() => {
        setPvOutTab([])
        if (prevInclinaison.length !== 0 && prevOrientation.length !== 0 && powersState.length !== 0 && prevApi.length !== 0 && prevLoss.length !== 0 && prevMountingPlace.length !== 0) { // && powersState.length !== 0
            //console.log("hangar taille de prevInclimaison orientation et powerstate ",prevInclinaison, prevOrientation, powersState)
            for (let pas = 0; pas < prevInclinaison.length; pas++) {
                // changer reduxPower[pas] avec powersState[pas]
                //console.log("hangar pas, powerstate ",pas, prevOrientation.length, prevOrientation)
                //console.log("hangar pas, prevorientation ",pas,prevOrientation[pas])
                //console.log("****** formActive pas = ",pas," --- powersState = ",powersState)
                //f(isNaN(powersState[pas])){
                //    while(pas <)
                //}
                if (!isNaN(powersState[pas])) {
                    let i = 0
                    while (i < formActive.length && formActive[i].form === undefined) {
                        i = i + 1
                    }
                    console.log("****** reduxForms = ", formActive, "---- i = ", i)
                    if (formActive[i].form.api === "Solargis") {
                        // Solargis API
                        //console.log("call api solargis");
                        callApi(prevOrientation[pas], prevInclinaison[pas], parseFloat(powersState[pas])).then(res => setResponseApi({
                            pvout: res.data.calculation?.output?.esm?.yearly,
                            power: parseFloat(powersState[pas])
                        }))
                    } else {
                        // Pvgis API
                        //console.log("call api pvgis");
                        callApi(prevOrientation[pas], prevInclinaison[pas], parseFloat(powersState[pas])).then(res => setResponseApi({
                            pvout: res.data.outputs.totals?.fixed?.E_y / parseFloat(powersState[pas]),
                            power: parseFloat(powersState[pas])
                        }))
                    }
                }
            }
        }
    }, [prevOrientation, prevInclinaison, powersState, prevApi, prevLoss, prevMountingPlace, formActive]); // powersState

    useEffect(() => {
        if (responseApi !== undefined) {
            setPvOutTab([...pvOutTab, responseApi])
        }
        if (pvOutTab.length === reduxPower?.length) { //reduxPower -> powersState ???
            setResponseApi(undefined)
        }
    }, [responseApi])

    useEffect(() => {
        //console.log("hangar pvouttab, powersState : ", pvOutTab, powersState)
        if ((pvOutTab?.length !== 0) && (pvOutTab?.length === powersState?.length)) { // à la place de reduxPower mettre powersState
            //console.log("hangar pvouttab, powersState : ", pvOutTab, powersState)
            let dividende = 0;
            let diviseur = 0;
            pvOutTab.forEach(element => {
                dividende = dividende + (element.pvout * element.power);
                diviseur = diviseur + (element.power)
            });
            dispatch(getProductible((dividende / diviseur).toFixed(2)))
            // une fois le calcule est fait j'initialise le state
            //C'est ce "setPvOutTab([])" ci-dessous qui part en boucle infinie
            setPvOutTab([])
        }
        //}, [])
    }, [pvOutTab])

    return (
        <></>
    )
}
 */

const CalculateProductible = async (reduxForms, reduxPower, reduxUser, reduxMap) => {
    const exceptions = ["Hangar Symétrique 60m/26m", "Hangar Symétrique 51m/26m", "Hangar Asymétrique 60m/26m", "Hangar Asymétrique 51m/26m", "Hangar cantale 60m/28m", "Toiture terrasse Est-Ouest"];

    let totalProd = [];
    let dividend = 0;
    let divider = 0;

    const implementationExceptions = async (form, index, orientation) => {
        let moduleSpec = [];
        // for the two side
        for (let i = 0; i < 2; i++) {
            const response = await callApi(orientation, form.inclinaison, parseFloat(reduxPower[index][i]),
                form.loss, form.mountingPlace);
            moduleSpec.push({
                pvout: response.data.outputs.totals?.fixed?.E_y / parseFloat(reduxPower[index][i]),
                power: parseFloat(reduxPower[index][i])
            });
            // inverse the rotation for the other side
            if (orientation >= 0)
                orientation -= 180;
            else
                orientation += 180;
        }

        return {
            pvout: ((moduleSpec[0].pvout * moduleSpec[0].power) + (moduleSpec[1].pvout * moduleSpec[1].power)) / (moduleSpec[0].power + moduleSpec[1].power),
            power: (moduleSpec[0].power + moduleSpec[1].power)
        };
    };

    const callApi = async (orientation, inclinaison, power, loss, mountingPlace) => {
        let pvgisResult = await springboot.get(`/api/pvgis/getProductible?lat=${reduxMap.lat}&lon=${reduxMap.lng}&angle=${inclinaison}&aspect=${orientation}&peakpower=${power}&loss=${loss}&mountingplace=${mountingPlace}`,
            {headers: {Authorization: `Bearer ${reduxUser.token}`}})
        console.log("result pvgis", pvgisResult);
        return pvgisResult;
    }

    for (const [index, form] of reduxForms.entries()) {
        if (form.form && Object.keys(form.form).length !== 0 && reduxPower.length > 0) {

            let orientation = form.form.orientation;
            if(orientation < 0)
                orientation += 180;
            else
                orientation -= 180;

            if (exceptions.includes(form.form.implantationType)) {
                totalProd.push(await implementationExceptions(form.form, index, orientation));
            } else {
                const response = await callApi(orientation, form.form.inclinaison, parseFloat(reduxPower[index]),
                    form.form.loss, form.form.mountingPlace);
                totalProd.push({
                    pvout: response.data.outputs.totals?.fixed?.E_y / parseFloat(reduxPower[index]),
                    power: parseFloat(reduxPower[index])
                });
            }
        }
    }

    totalProd.forEach(element => {
        dividend += (element.pvout * element.power);
        divider += element.power;
    });

    console.log("Prod : ", (dividend / divider).toFixed(2));
    console.log("Total rec : ", totalProd.length);
    return (dividend / divider).toFixed(2);
};

export default CalculateProductible;
