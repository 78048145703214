/**
 * Returns a value in degrees from a value in radians.
 * @param degrees {number}
 * @returns {number}
 */
function toRadians(degrees) {
    return degrees * (Math.PI / 180);
}

/**
 * Returns a value in radians from a value in degrees.
 * @param radians {number}
 * @returns {number}
 */
function toDegrees(radians) {
    return radians * (180 / Math.PI);
}

/**
 * Returns the distance and the bearing calculated from one LatLng to another.
 * @param startLat {number}
 * @param startLng {number}
 * @param endLat {number}
 * @param endLng {number}
 * @returns {{distance: number, bearing: number}}
 */
function getDistanceAndBearing(startLat, startLng, endLat, endLng) {
    const lat1 = toRadians(startLat);
    const lng1 = toRadians(startLng);
    const lat2 = toRadians(endLat);
    const lng2 = toRadians(endLng);

    // calculate the distance
    const R = 6371e3; // radius of the Earth in metres
    const dLat = lat2 - lat1;
    const dLng = lng2 - lng1;

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1) * Math.cos(lat2) *
        Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;

    // calculate the bearing
    const y = Math.sin(lng2 - lng1) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) -
        Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);
    const bearing = (toDegrees(Math.atan2(y, x)) + 360) % 360; // result in degrees between 0 and 360

    return {
        distance: distance,
        bearing: bearing
    };
}

/**
 * Returns an array of the coordinates of all the modules of the selected rectangle after applying a vector calculated with the old and new coordinates.
 * @param oldCoords {{lng: number, lat: number}} - Old coordinates of the selected rectangle
 * @param newCoords {{lng: number, lat: number}} - Actual coordinates of the selected rectangle
 * @param modules {array} - Array of modules of the selected rectangle
 * @returns {array} - The rotated Array of modules
 */
export function calculateTranslation(oldCoords, newCoords, modules) {
    const google = window.google;

    const distanceAndBearing = getDistanceAndBearing(oldCoords.lat, oldCoords.lng, newCoords.lat, newCoords.lng);

    function translateCoords(coords) {
        const newCoords = google.maps.geometry.spherical.computeOffset(coords, distanceAndBearing.distance, distanceAndBearing.bearing);
        return {lat: newCoords.lat(), lng: newCoords.lng()};
    }

    if (modules && Array.isArray(modules[0][0])) { // if it has two orientations

        modules = modules.map((rectangleSide) => {
            return rectangleSide.map(coordsTab => {
                return coordsTab.map((coords) => {
                    return translateCoords(coords);
                });
            });
        });
    } else {
        modules = modules.map(coordsTab => { // if it has one orientation
            return coordsTab.map((coords) => {
                return translateCoords(coords);
            });
        });
    }

    return modules;
}

/**
 * Returns the new coordinates of a polygon after its rotation.
 * @param polygon {google.maps.Polygon} - Polygon that represents the selected rectangle
 * @param angle {number} - Rotation angle in degrees
 * @returns {{lng: number, lat: number}[]} - Array of rotated LatLng coordinates
 */
export function rotatePolygon(polygon, angle) {
    const prj = polygon.getMap().getProjection();
    const origin = prj.fromLatLngToPoint(polygon.getPath().getAt(0)); //rotate around first point

    return polygon.getPath().getArray().map(function (latLng) {
        const point = prj.fromLatLngToPoint(latLng);
        const rotatedLatLng = prj.fromPointToLatLng(rotatePoint(point, origin, angle));
        return {lat: rotatedLatLng.lat(), lng: rotatedLatLng.lng()};
    });
}

/**
 * Returns the new coordinates of a point after its rotation.
 * @param point {{x: number, y: number}} - Point on which the rotation is applied
 * @param origin {{x: number, y: number}} - Origin point
 * @param angle {number} - Rotation angle in degrees
 * @returns {{x: number, y: number}} - Rotated point
 */
function rotatePoint(point, origin, angle) {
    const angleRad = angle * (Math.PI / 180.0);

    return {
        x: Math.cos(angleRad) * (point.x - origin.x) - Math.sin(angleRad) * (point.y - origin.y) + origin.x,
        y: Math.sin(angleRad) * (point.x - origin.x) + Math.cos(angleRad) * (point.y - origin.y) + origin.y
    };
}

/**
 * Returns the new coordinates of a point after its rotation.
 * @param point {{x: number, y: number}} - Point on which the rotation is applied
 * @param origin {{x: number, y: number}} - Origin point
 * @param vector {{x: number, y: number}} - Vector representing the rotation
 * @returns {{x: number, y: number}} - Rotated point
 */
function rotatePointWithVector(point, origin, vector) {
    return {
        x: vector.x * (point.x - origin.x) - vector.y * (point.y - origin.y) + origin.x,
        y: vector.y * (point.x - origin.x) + vector.x * (point.y - origin.y) + origin.y
    };
}

/**
 * Rotates a polygon and its inner rectangles.
 * @param polygon {google.maps.Polygon} - Polygon that represents the selected rectangle
 * @param modules {Array} - Array of modules of the selected rectangle
 * @param angle {number} - Rotation angle in degrees
 * @returns {Array} - The rotated Array of modules
 */
export function rotatePolygonAndRectangles(polygon, modules, angle) {
    const google = window.google;
    const prj = polygon.getMap().getProjection();

    // Get the polygon's path as an array of LatLng
    const origin = prj.fromLatLngToPoint(polygon.getPath().getAt(0));

    // Calculate the rotation vector
    const angleRad = angle * (Math.PI / 180.0);
    const vector = {
        x: Math.cos(angleRad),
        y: Math.sin(angleRad)
    };

    // Rotate the rectangles
    if (modules && Array.isArray(modules[0][0])) { // if it has two orientations
        modules = modules.map((rectangleSide) => {
            return rectangleSide.map(coordsTab => {
                return coordsTab.map((coords) => {
                    const point = prj.fromLatLngToPoint(new google.maps.LatLng(coords.lat, coords.lng));
                    const rotatedPoint = prj.fromPointToLatLng(rotatePointWithVector(point, origin, vector));
                    return { lat: rotatedPoint.lat(), lng: rotatedPoint.lng() };
                });
            });
        });
    } else {
        modules = modules.map(coordsTab => { // if it has one orientation
            return coordsTab.map((coords) => {
                const point = prj.fromLatLngToPoint(new google.maps.LatLng(coords.lat, coords.lng));
                const rotatedPoint = prj.fromPointToLatLng(rotatePointWithVector(point, origin, vector));
                return { lat: rotatedPoint.lat(), lng: rotatedPoint.lng() };
            });
        });
    }
console.log(modules)
    return modules;
}