import {Checkbox, FormControlLabel, Paper } from '@material-ui/core'
import React, { useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getCableHtaUnderground, getPostHta , getCableBtAerial, getCableBtUnderground, getCableHtaAerial } from '../_actions';

function CardEnedisApi({posteHta, lignecablehta, lignecablebt, setPosteHta, setLignecablebt, setLignecablehta, hidden, setCardEnedis}) {

    const geoFilterPolygon =  useSelector(state => state.mapReducer.map?.geoFilterPolygon);
    const zoom =  useSelector(state => state.mapReducer.map?.zoom);

    const dispatch = useDispatch();


    useEffect(() => {
        if(posteHta && zoom > 17){ //50m
            dispatch(getPostHta(geoFilterPolygon));
        }
        if(lignecablehta && zoom > 17){ //50m
            dispatch(getCableHtaUnderground(geoFilterPolygon));
            dispatch(getCableHtaAerial(geoFilterPolygon));
        }
        if(lignecablebt && zoom > 19){ //10m
            dispatch(getCableBtUnderground(geoFilterPolygon));
            dispatch(getCableBtAerial(geoFilterPolygon));
        }
    }, [geoFilterPolygon, posteHta, lignecablehta, lignecablebt]);


    return (
        <Paper elevation={3} style={{display: hidden === true ? 'none' : 'flex', flexDirection: 'column', justifyContent:'center',  width: 200, height: 140, marginLeft: 10, marginTop: 270}}>
            {console.log("return header Card ENEDIs")}
            <div style={{paddingLeft: 10}}>
                <FormControlLabel
                    control={ <Checkbox checked={posteHta} name="postehta" color="primary" onClick={()=>{setPosteHta(!posteHta)}} />}
                    label="Poste HTA"
                />
                <FormControlLabel
                    control={ <Checkbox checked={lignecablehta}  name="lignecablehta" color="primary" onClick={()=>{setLignecablehta(!lignecablehta)}} />}
                    label="Ligne et cable HTA"
                />
                <FormControlLabel
                    control={ <Checkbox checked={lignecablebt} name="lignecablebt" color="primary" onClick={()=>{setLignecablebt(!lignecablebt)}} />}
                    label="Ligne et cable BT"
                />
            </div>
        </Paper>
    );
}

export default CardEnedisApi;