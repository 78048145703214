import React, {useEffect} from 'react';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Tooltip} from '@material-ui/core';
import useGET from "../hooks/useGET"
import {springboot} from "../config/api"
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {saveAddress, saveIdProject, saveProjectName, saveProjectState} from '../_actions/headerProjectAction';
import {registerMapInformation} from '../_actions/mapAction';
import {creationOfRequirement, sendRectanglesToStore} from '../_actions';
import {addModulesAndFormsToReduxFromDb} from '../_actions/modulesAction';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MUIDataTable from "mui-datatables";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {usePOST} from "../hooks";

export default function MuiTable({data}) {
    const colors = {
        STARTED: '#BE1616',
        IN_PROGRESS: '#FF9300',
        FINISHED: '#7FF775',
        GRAY: '#626262'
    };

    const reduxUser = useSelector(state => state.userReducer.user);
    const [project, setUrl] = useGET("");
    const {response, errorPost, sendPostRequest: setRequest} = usePOST(); // Error not handled

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const goToProjectEdit = (e, projectid) => {
        e.preventDefault();
        console.log("value id project", projectid)

        setUrl({
            url: `/api/project/getProjectById?idProject=${projectid}`,
            data: {},
            headers: {Authorization: `Bearer ${reduxUser.token}`},
            api: springboot
        });
    }

    const handleRemove = (e, projectId) => {
        e.preventDefault();

        document.getElementById("started_" + projectId).style.color = "red";
        document.getElementById("inProgress_" + projectId).style.color = "red";
        document.getElementById("finished_" + projectId).style.color = "red";

        setRequest({
            url: `/api/project/deactivateProject?idProject=${projectId}`,
            data: {},
            headers: {Authorization: `Bearer ${reduxUser.token}`},
            api: springboot
        });
    }

    const changeState = (e, projectid, state) => {
        e.preventDefault();

        document.getElementById("started_" + projectid).style.color = "#626262";
        document.getElementById("inProgress_" + projectid).style.color = "#626262";
        document.getElementById("finished_" + projectid).style.color = "#626262";

        switch (state) {
            case "STARTED" :
                document.getElementById("started_" + projectid).style.color = "#BE1616";
                break;
            case "IN_PROGRESS" :
                document.getElementById("inProgress_" + projectid).style.color = "#FF9300";
                break;
            case "FINISHED" :
                document.getElementById("finished_" + projectid).style.color = "#7FF775";
                break;
        }

        setRequest({
            url: `/api/project/updateProjectState?idProject=${projectid}`,
            data: {projectState: state},
            headers: {Authorization: `Bearer ${reduxUser.token}`},
            api: springboot
        });
    }


    useEffect(() => {
        if (project !== undefined) {
            dispatch(saveIdProject(project.idProject));
            dispatch(registerMapInformation(JSON.parse(project.map)));
            dispatch(addModulesAndFormsToReduxFromDb(JSON.parse(project.forms), JSON.parse(project.modules)));

            JSON.parse(project.rectangle).map(element => {
                dispatch(sendRectanglesToStore(element));
                if(element.idRectangle) dispatch(creationOfRequirement(element.idRectangle));
            });

            dispatch(saveProjectName(project.name));
            dispatch(saveProjectState(project.projectState));
            dispatch(saveAddress(JSON.parse(project.completedAddress)));

            navigate('/Project');
        }

    }, [project])


    const headCells = [
        {id: 'projectname', name: 'projectname', nemeric: false, align: 'left', disablePadding: true, label: 'Projet'},
        {id: 'username', name: 'username', nemeric: false, align: 'left', disablePadding: false, label: 'Utilisateur'},
        {
            id: 'date', name: 'date', nemeric: true, align: 'right', disablePadding: false, label: 'Date',
            options: {sortDirection: 'desc'}
        },
        {id: 'power', name: 'power', nemeric: true, align: 'right', disablePadding: false, label: 'Puissance'},
        {id: 'address', name: 'address', nemeric: true, align: 'right', disablePadding: false, label: 'Code postal'},
        {
            id: 'projectState',
            name: 'projectState',
            label: 'État du projet',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <Tooltip title="Initial">
                                <FiberManualRecordIcon
                                    id={'started_' + tableMeta.rowData[6]}
                                    type='button'
                                    style={{
                                        color: value === 'STARTED' ? colors[value] : colors['GRAY'],
                                        fontSize: "large"
                                    }}
                                    onClick={e => changeState(e, tableMeta.rowData[6], "STARTED")}
                                />
                            </Tooltip>
                            <Tooltip title="En cours">
                                <FiberManualRecordIcon
                                    id={'inProgress_' + tableMeta.rowData[6]}
                                    type='button'
                                    style={{
                                        color: value === 'IN_PROGRESS' ? colors[value] : colors['GRAY'],
                                        fontSize: "large"
                                    }}
                                    onClick={e => changeState(e, tableMeta.rowData[6], "IN_PROGRESS")}
                                />
                            </Tooltip>
                            <Tooltip title="Validé">
                                <FiberManualRecordIcon
                                    id={'finished_' + tableMeta.rowData[6]}
                                    type='button'
                                    style={{
                                        color: value === 'FINISHED' ? colors[value] : colors['GRAY'],
                                        fontSize: "large"
                                    }}
                                    onClick={e => changeState(e, tableMeta.rowData[6], "FINISHED")}
                                />
                            </Tooltip>
                        </div>
                    )
                },
                empty: true,
            }
        },
        {
            id: 'action',
            name: 'id',
            label: 'Actions',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <Tooltip title="Modifier">
                                <EditIcon
                                    type='button'
                                    onClick={e => goToProjectEdit(e, value)}
                                    size="small"
                                    style={{color: "#FF9300"}}
                                />
                            </Tooltip>
                            <Tooltip title="Supprimer">
                                <DeleteIcon
                                    style={{color: "#FF9300"}}
                                    onClick={e => handleRemove(e, tableMeta.rowData[6])}
                                />
                            </Tooltip>
                        </div>
                    )
                },
                filter: false,
                sort: false,
                empty: true,
                //customHeadRender: ()=>null,
            }
        },
    ];

    const options = {
        //filterType: 'checkbox',
        textLabels: {
            body: {
                noMatch: "Désolé, aucune correspondance trouvée",
                toolTip: "Ordonner",
                columnHeaderTooltip: column => `Ordonner par ${column.label}`
            },
            pagination: {
                next: "Page Suivante",
                previous: "Page Précédente",
                rowsPerPage: "Lignes par pages:",
                displayRows: "sur",
            },
            toolbar: {
                search: "Chercher",
                downloadCsv: "Télécharger CSV",
                print: "Imprimer",
                viewColumns: "Voir les colonnes",
                filterTable: "Filtrer",
            },
            filter: {
                all: "Tous",
                title: "Filtres",
                reset: "Reset",
            },
            viewColumns: {
                title: "Afficher la colonne",
                titleAria: "Afficher/Cacher les colonnes",
            },
            selectedRows: {
                text: "projet(s) sélectionnée(s)",
                delete: "Supprimer",
                deleteAria: "Supprimer les projets sélectionnés",
            },
        },
        responsive: "scroll",
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 15, 20, 100],
        selectableRows: false,
    };

    const rows = data.map((row) => {
        if (row.active === true) {
            return {
                id: row.id,
                projectState: row.projectState,
                address: row.address ? row.address.postalCode : "non renseignée !",
                projectname: row.projectname,
                username: row.username,
                date: row.date,
                power: row.power ? row.power : 0,
            };
        }
    });

    const getMuiTheme = createTheme({
        components: {
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: '#FF9300'
                    }
                }
            },
            MuiPaper: {
                elevation4: {
                    border: "1px solid #000",
                    boxShadow: "0px"
                }
            }
        }
    });

    return (
        <ThemeProvider theme={getMuiTheme}>
            <MUIDataTable
                data={rows}
                columns={headCells}
                options={options}
            />
        </ThemeProvider>
    );
}