import {LOGIN_USER, PROJECT_MAP_INFORMATION} from "../_types"
import { EMPTY_USER_STORE } from "../_types/userType";

const initialState = {
    user: undefined
}



const userReducer = (state = initialState , action) => {

    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                user: action.payload
            }
        case EMPTY_USER_STORE: 
            return {
                initialState
            }
        default :
            return state;
    }
}

export default userReducer;