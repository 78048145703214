import React, {useEffect, useState} from 'react';
import {Header} from "../components"
import {MapHome} from '../components';
import {Grid, Typography} from '@material-ui/core'
import {TableProject} from "../components"
import {makeStyles} from '@material-ui/core/styles';
import Copyright from "../components/Copyright"
import useGET from "../hooks/useGET"
import {useSelector} from 'react-redux';
import {springboot} from "../config/api"

const RenderHomePage = () => {

    const classes = useStyles();

    const [dataProject, setDataProject] = useState([]);

    const reduxUser = useSelector(state => state.userReducer.user);

    // dans use Effect
    const [projects, setUrl] = useGET("");


    useEffect(() => {
        console.log('reduxUser', reduxUser);

        setUrl({
            url: `/api/project/getProjectsByCompany?idUser=${reduxUser.id}`,
            data: {},
            headers: {Authorization: `Bearer ${reduxUser.token}`},
            api: springboot
        });
    }, []);

    useEffect(() => {
        if (projects !== undefined) {
            let tabDataProject = [];

            projects.map((project) => {
                console.log(project);
                const object = {
                    id: project.idProject,
                    projectState: project.projectState,
                    active: project.active,
                    date: project.date.split('T')[0],
                    address: JSON.parse(project.completedAddress),
                    username: project.firstName + " " + project.lastName,
                    projectname: project.name,
                    power: project.power,
                    userId: project.userId,
                };

                tabDataProject.push(object);
            });

            setDataProject(tabDataProject);
        }

    }, [projects]);

    return (
        <Grid container>
            <Grid item lg={7} sm={10} style={{padding: 5}}>
                <Typography className={classes.title}>VOS PROJETS</Typography>

                <div style={{margin: 10}}>
                    {dataProject && <TableProject data={dataProject}/>}
                </div>
            </Grid>
            <Grid item lg={5} sm={10} style={{padding: 5}}>
                <Typography className={classes.title}>LOCALISATION</Typography>

                <div style={{margin: 10, border: "1px solid #000"}}>
                    {dataProject && <MapHome listData={dataProject}/>}
                </div>
            </Grid>
        </Grid>
    );
}

function Home() {

    const classes = useStyles();

    return (
        <>
            <Grid container direction='column'>
                <Grid item>
                    <Header/>
                </Grid>
                <Grid item style={{marginTop: 20}}>
                    <RenderHomePage/>
                </Grid>
            </Grid>
            <div className={classes.footer}>
                <Copyright/>
            </div>
        </>

    );
}

export default Home

const useStyles = makeStyles({
    title: {
        textAlign: 'center',

        fontWeight: 'bold',
        color: "#000",
        fontSize: 28,
        marginBottom: 20,
    },
    footer: {
        marginTop: '5%'
    },

});