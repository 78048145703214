import {
    IS_DRAWING_TRENCH,
    DELETE_TRENCH,
    CHARGIN_POINT_ACTIVE,
    DELETE_CHARGIN_POINT,
    DELETE_DISPLAY_SCREEN,
    DISPLAY_SCREEN_ACTIVE,
    ENERGY_METER_ACTIVE,
    DELETE_ENERGY_METER,
    LOCAL_INVERTER_ACTIVE,
    DELETE_LOCAL_INVERTER,
    ROOF_INSTALLATION_ACTIVE,
    DELETE_ROOF_INSTALLATION,
    SOLAR_PANEL_ACTIVE,
    DELETE_SOLAR_PANEL,
    WRITE_TEXT_ACTIVE, DELETE_WRITE_TEXT
} from '../_types';

export const setIsDrawingTrench = (trench) => {
    return {
        type : IS_DRAWING_TRENCH ,
        payload: trench,
    };
}

export const deleteTrench = () => {
    return{
        type: DELETE_TRENCH
    }
}

export const charginPointActive = (charginPoint) => {
    return {
        type : CHARGIN_POINT_ACTIVE ,
        payload: charginPoint,
    };
}

export const deleteCharginPoint = () => {
    return{
        type: DELETE_CHARGIN_POINT
    }
}

export const displayScreenActive = (displayScreen) => {
    return {
        type : DISPLAY_SCREEN_ACTIVE ,
        payload: displayScreen,
    };
}

export const deleteDisplayScreen = () => {
    return{
        type: DELETE_DISPLAY_SCREEN
    }
}

export const energyMeterActive = (eneryMeter) => {
    return {
        type : ENERGY_METER_ACTIVE ,
        payload: eneryMeter,
    };
}

export const deleteEnergyMeter = () => {
    return{
        type: DELETE_ENERGY_METER
    }
}

export const localInverterActive = (localInverter) => {
    return {
        type : LOCAL_INVERTER_ACTIVE ,
        payload: localInverter,
    };
}

export const deleteLocalInverter = () => {
    return{
        type: DELETE_LOCAL_INVERTER
    }
}

export const roofInstallationActive = (roofInstallation) => {
    return {
        type : ROOF_INSTALLATION_ACTIVE ,
        payload: roofInstallation,
    };
}

export const deleteRoofInstallation = () => {
    return{
        type: DELETE_ROOF_INSTALLATION
    }
}

export const solarPanelActive = (solarPanel) => {
    return {
        type : SOLAR_PANEL_ACTIVE ,
        payload: solarPanel,
    };
}

export const deleteSolarPanel = () => {
    return{
        type: DELETE_SOLAR_PANEL
    }
}

export const writeTextActive = (writeText) => {
    return {
        type : WRITE_TEXT_ACTIVE ,
        payload: writeText,
    };
}

export const deleteWriteText = () => {
    return{
        type: DELETE_WRITE_TEXT
    }
}