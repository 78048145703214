import {useState, useEffect, useCallback} from 'react'
import axios from 'axios'

/**
 * Hook personaliser : Pour tous les requete post vers les apis.
 */
function usePOST() {
    const [error, setError] = useState({isError: false, error:''});
    const [response, setResponse] = useState(null);

    const sendPostRequest = useCallback(async ({url, data, headers, api}) => {
        setError({isError: false, error: ''});
        setResponse(null);
        let result = {};

        try {
            if (api === "solargis-proxy") {
                result = await axios.put(url, data, headers);
                if(result.data)
                    setResponse(result.data);
            } else {
                console.log(url);
                result = await api.post(url, data, {
                    headers: headers
                });
            }
            if(result)
                setResponse(result.data);

        } catch (err) {
            console.log(err)
            setError({isError: true, error: err ? err.response.data : 'Something when wrong'});
        }

    }, []);

    return {
        response,
        error,
        sendPostRequest
    };
}

export default usePOST