import  { Polyline} from '@react-google-maps/api'
import React from 'react'
import {shallowEqual, useSelector} from 'react-redux';

function PolylineCadastre(props) {

    const cadastre = useSelector(state => state.cadastreApiReducer.data, shallowEqual)
    const map = props.map;
    const google = window.google;


    function responseToMapInputCadastre(response) {
        let geometry = [];
        const result = [];
        response.features.forEach(feature => {
                geometry.push(feature.geometry.coordinates[0][0])
            let coordinates = [];
            geometry[0].map(geo => coordinates.push({lat: geo[1], lng: geo[0]}))
                result.push(coordinates)
                geometry = []
            }
        );
        return result;
    }

    let cadastreData;
    let clickListener;
    let cadastreInfoDiv = document.getElementById("cadastre-info");
    if(map !== null ){
        if(cadastre){
            console.log("cadastre rechargé")
            cadastreInfoDiv.style.visibility = 'visible';
            const marker = new google.maps.Marker({
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    strokeColor: "#ff8a65",
                    scale: 4,
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(0, 0),
                    labelOrigin: new google.maps.Point(0, 0)
                },
                map: map,
            });

            clickListener = google.maps.event.addListener(map,'click', function (e) {
                console.log("cadastre rechargé demander")
                const url = "https://geocodage.ign.fr/look4/parcel/reverse?searchGeom={%22type%22:%22Point%22,%22coordinates%22:[" + e.latLng.lng() + "," + e.latLng.lat() + "]}";
                const x = new XMLHttpRequest();
                x.open("GET", url, true);
                x.responseType = 'json';
                x.send();

                let codeCom;
                let codeLet;

                x.onload = function () {
                    const response = x.response;
                    console.log("cadastre rechargé recu")
                    if(response.features[0] !== undefined){
                        document.getElementById("parcelNumber").innerText = "  " + response.features[0].properties.numero;
                        document.getElementById("section").innerText = "  " + response.features[0].properties.section;
                        document.getElementById("feuille").innerText = "  " + response.features[0].properties.feuille;

                        codeCom = response.features[0].properties.codeCommuneAbs
                        codeLet = response.features[0].properties.section

                        let codeInsee = response.features[0].properties.codeDepartement;
                        if (response.features[0].properties.codeArrondissement === '000') {
                            codeInsee += response.features[0].properties.codeCommune;
                        } else {
                            codeInsee += response.features[0].properties.codeArrondissement;
                        }

                        document.getElementById("dxf").href = "https://cadastre.data.gouv.fr/data/dgfip-pci-vecteur/latest/dxf/feuilles/" + response.features[0].properties.codeDepartement + "/" + codeInsee + "/dxf-" + codeInsee + codeCom + codeLet + "01.tar.bz2"
                    } else {
                        document.getElementById("parcelNumber").innerText = " ";
                        document.getElementById("section").innerText = " ";
                        document.getElementById("feuille").innerText = " ";
                    }
                };
            })

            cadastreData = new google.maps.ImageMapType({
                maxZoom: 19,
                minZoom: 0,
                tileSize: new google.maps.Size(256, 256),
                getTileUrl: function (coord, zoom) {
                    //Pour PROD
                    //return 'https://wxs.ign.fr/0skh99buqiuj9k125e4d5q9h/geoportail/wmts?layer=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&style=PCI%20vecteur&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=' + zoom + "&TileCol=" + coord.x + "&TileRow=" + coord.y;

                    //Pour DEV
                    return 'https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?layer=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&style=PCI%20vecteur&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=' + zoom + "&TileCol=" + coord.x + "&TileRow=" + coord.y;
                }
            });
            console.log("affichage cadastre")
            map.overlayMapTypes.insertAt(0, cadastreData);

        }else{
            google.maps.event.removeListener(clickListener);
            cadastreInfoDiv.style.visibility = 'hidden';
            map.overlayMapTypes.clear();
        }

    }

    return (
        <div>
            {cadastre
            && responseToMapInputCadastre(cadastre).map((coordinateLine) => {
                return (
                    <Polyline
                        path={coordinateLine}
                        options={{strokeColor: '#ff8a65', strokeWeight: 5}}
                    />
                )
            })
            }
        </div>
    )
}

export default React.memo(PolylineCadastre)



