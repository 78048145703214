import {Button, Grid, makeStyles, Tooltip} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import CardEnedisApi from './CardEnedisApi';
import CardCardApi from './CardCardApi';
import {
    deleteCableHtaUnderground,
    deletePostHta,
    deleteCableBtAerial,
    deleteCableBtUnderground,
    deleteCableHtaAerial,
    deleteCadastre,
    deleteGeoportail,
    deleteGeoportail5065,
    deleteGeoportail0005,
    deleteGeoportail0610,
    deleteGeoportail1115,
    deleteTrench,
    deleteCharginPoint,
    deleteDisplayScreen,
    deleteEnergyMeter,
    deleteLocalInverter,
    deleteRoofInstallation, deleteSolarPanel, deleteWriteText
} from '../_actions';
import {useDispatch} from 'react-redux';
import GeoCardApi from "./GeoCardApi";
import DesignCardApi from "./DesignCardApi";
import CarteIcon from "../assets/icons/Picto-Gamma-PV-carte.png";
import CadastreIcon from "../assets/icons/Picto-cadastre.png";
import DesignIcon from "../assets/icons/Picto-Gamma-PV-design.png";
import EnedisIcon from "../assets/icons/Picto-Gamma-PV-enedis-02.png";
import PDFIcon from "../assets/icons/Picto-pdf-2.png";

function SideBare() {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [cardEnedis, setCardEnedis] = useState(false)
    const [cardCard, setCardCard] = useState(false)
    const [geoCard, setGeoCard] = useState(false)
    const [designCard, setDesignCard] = useState(false)

    const [posteHta, setPosteHta] = useState(false)
    const [lignecablehta, setLignecablehta] = useState(false)
    const [lignecablebt, setLignecablebt] = useState(false)

    const [cadastre, setCadastre] = useState(false)

    const [geoportail, setGeoportail] = useState(false)
    const [geoportail5065, setGeoportail5065] = useState(false)
    const [geoportail0005, setGeoportail0005] = useState(false)
    const [geoportail0610, setGeoportail0610] = useState(false)
    const [geoportail1115, setGeoportail1115] = useState(false)

    const [trench, setTrench] = useState(false)
    const [charginPoint, setCharginPoint] = useState(false);
    const [displayScreen, setDisplayScreen] = useState(false);
    const [energyMeter, setEnergyMeter] = useState(false);
    const [localInverter, setLocalInverter] = useState(false);
    const [roofInstallation, setRoofInstallation] = useState(false);
    const [solarPanel, setSolarPanel] = useState(false);
    const [writeText, setWriteText] = useState(false);

    useEffect(() => {
        if (!posteHta) {
            dispatch(deletePostHta())
        }
    }, [posteHta])

    useEffect(() => {
        if (!lignecablehta) {
            dispatch(deleteCableHtaUnderground())
            dispatch(deleteCableHtaAerial())
        }
    }, [lignecablehta])

    useEffect(() => {
        if (!lignecablebt) {
            dispatch(deleteCableBtUnderground())
            dispatch(deleteCableBtAerial())
        }
    }, [lignecablebt])

    useEffect(() => {
        if (!cadastre) {
            dispatch(deleteCadastre())
        }
    }, [cadastre])

    useEffect(() => {
        if (!geoportail) {
            dispatch(deleteGeoportail())
        }
    }, [geoportail])

    useEffect(() => {
        if (!geoportail5065) {
            dispatch(deleteGeoportail5065())
        }
    }, [geoportail5065])

    useEffect(() => {
        if (!geoportail0005) {
            dispatch(deleteGeoportail0005())
        }
    }, [geoportail0005])

    useEffect(() => {
        if (!geoportail0610) {
            dispatch(deleteGeoportail0610())
        }
    }, [geoportail0610])

    useEffect(() => {
        if (!geoportail1115) {
            dispatch(deleteGeoportail1115())
        }
    }, [geoportail1115])

    useEffect(() => {
        if(!trench){
            dispatch(deleteTrench())
        }
    }, [trench])

    useEffect( () => {
        if(!charginPoint){
            dispatch(deleteCharginPoint())
        }
    }, [charginPoint])

    useEffect( () => {
        if(!displayScreen){
            dispatch(deleteDisplayScreen())
        }
    }, [displayScreen])

    useEffect( () => {
        if(!energyMeter){
            dispatch(deleteEnergyMeter())
        }
    }, [energyMeter])

    useEffect( () => {
        if(!localInverter){
            dispatch(deleteLocalInverter())
        }
    }, [localInverter])

    useEffect( () => {
        if(!roofInstallation){
            dispatch(deleteRoofInstallation())
        }
    }, [roofInstallation])

    useEffect( () => {
        if(!solarPanel){
            dispatch(deleteSolarPanel())
        }
    }, [solarPanel])

    useEffect( () => {
        if(!writeText){
            dispatch(deleteWriteText())
        }
    }, [writeText])

    // use selector and call api

    const onClickCard = () => {
        setCardCard(!cardCard);
        setCardEnedis(false);
        setGeoCard(false);
        setDesignCard(false);
    }

    const onClickGeo = () => {
        setGeoCard(!geoCard);
        setCardCard(false);
        setCardEnedis(false);
        setDesignCard(false);
    }

    const onClickDesign = () => {
        setDesignCard(!designCard);
        setGeoCard(false);
        setCardCard(false);
        setCardEnedis(false);
    }

    const onClickEnedis = () => {
        setCardEnedis(!cardEnedis);
        setCardCard(false);
        setGeoCard(false);
        setDesignCard(false);
    }


    return (
        <div>
            <Grid container spacing={2} justifyContent='center' className={classes.sideBar}>
                <Grid style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Tooltip title="Cartes" placement={"top"}>
                        <Button onClick={onClickGeo} className={classes.geo}/>
                    </Tooltip>

                    <Tooltip title="Cadastre"  placement={"top"}>
                        <Button onClick={onClickCard} className={classes.cadastre}/>
                    </Tooltip>

                    <Tooltip title="Design" placement={"top"}>
                        <Button 
                        onClick={onClickDesign} 
                        className={classes.design}
                        />
                    </Tooltip>

                    <Tooltip title="Enedis" placement={"top"}>
                        <Button onClick={onClickEnedis} className={classes.enedis}/>
                    </Tooltip>

                    <Tooltip title="Exporter en PDF" placement={"top"}>
                        <Button id="print-pdf" className={classes.pdf}/>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid className={classes.carte}>
                {cardEnedis ?
                    <>
                        <CardEnedisApi posteHta={posteHta} setPosteHta={setPosteHta} lignecablehta={lignecablehta}
                                       setLignecablehta={setLignecablehta}
                                       lignecablebt={lignecablebt} setLignecablebt={setLignecablebt}
                                       setCardEnedis={setCardEnedis}/>
                        <CardCardApi hidden={true} cadastre={cadastre} setCadastre={setCadastre}
                                     setCardCard={setCardCard}/>
                        <GeoCardApi hidden={true} geoportail={geoportail} setGeoportail={setGeoportail}
                                    geoportail5065={geoportail5065} setGeoportail5065={setGeoportail5065}
                                    geoportail0005={geoportail0005} setGeoportail0005={setGeoportail0005}
                                    geoportail0610={geoportail0610} setGeoportail0610={setGeoportail0610}
                                    geoportail1115={geoportail1115} setGeoportail1115={setGeoportail1115}/>
                        <DesignCardApi hidden={true} trench={trench} setTrench={setTrench}
                                    charginPoint={charginPoint} setCharginPoint={setCharginPoint}
                                    displayScreen={displayScreen} setDisplayScreen={setDisplayScreen}
                                    energyMeter={energyMeter} setEnergyMeter={setEnergyMeter}
                                    localInverter={localInverter} setLocalInverter={setLocalInverter}
                                    roofInstallation={roofInstallation} setRoofInstallation={setRoofInstallation}
                                    solarPanel={solarPanel} setSolarPanel={setSolarPanel}
                                    writeText={writeText} setWriteText={setWriteText}/>
                    </> :
                    cardCard ? <>
                            <CardCardApi cadastre={cadastre} setCadastre={setCadastre} setCardCard={setCardCard}/>
                            <GeoCardApi hidden={true} geoportail={geoportail} setGeoportail={setGeoportail}
                                        geoportail5065={geoportail5065} setGeoportail5065={setGeoportail5065}
                                        geoportail0005={geoportail0005} setGeoportail0005={setGeoportail0005}
                                        geoportail0610={geoportail0610} setGeoportail0610={setGeoportail0610}
                                        geoportail1115={geoportail1115} setGeoportail1115={setGeoportail1115}/>
                            <CardEnedisApi hidden={true} posteHta={posteHta} setPosteHta={setPosteHta}
                                           lignecablehta={lignecablehta} setLignecablehta={setLignecablehta}
                                           lignecablebt={lignecablebt} setLignecablebt={setLignecablebt}
                                           setCardEnedis={setCardEnedis}/>
                            <DesignCardApi hidden={true} trench={trench} setTrench={setTrench}
                                           charginPoint={charginPoint} setCharginPoint={setCharginPoint}
                                           displayScreen={displayScreen} setDisplayScreen={setDisplayScreen}
                                           energyMeter={energyMeter} setEnergyMeter={setEnergyMeter}
                                           localInverter={localInverter} setLocalInverter={setLocalInverter}
                                           roofInstallation={roofInstallation} setRoofInstallation={setRoofInstallation}
                                           solarPanel={solarPanel} setSolarPanel={setSolarPanel}
                                           writeText={writeText} setWriteText={setWriteText}/>
                        </> :
                        designCard ? <>
                                <CardCardApi hidden={true} cadastre={cadastre} setCadastre={setCadastre} setCardCard={setCardCard}/>
                                <GeoCardApi hidden={true} geoportail={geoportail} setGeoportail={setGeoportail}
                                            geoportail5065={geoportail5065} setGeoportail5065={setGeoportail5065}
                                            geoportail0005={geoportail0005} setGeoportail0005={setGeoportail0005}
                                            geoportail0610={geoportail0610} setGeoportail0610={setGeoportail0610}
                                            geoportail1115={geoportail1115} setGeoportail1115={setGeoportail1115}/>
                                <CardEnedisApi hidden={true} posteHta={posteHta} setPosteHta={setPosteHta}
                                               lignecablehta={lignecablehta} setLignecablehta={setLignecablehta}
                                               lignecablebt={lignecablebt} setLignecablebt={setLignecablebt}
                                               setCardEnedis={setCardEnedis}/>
                                <DesignCardApi trench={trench} setTrench={setTrench}
                                               charginPoint={charginPoint} setCharginPoint={setCharginPoint}
                                               displayScreen={displayScreen} setDisplayScreen={setDisplayScreen}
                                               energyMeter={energyMeter} setEnergyMeter={setEnergyMeter}
                                               localInverter={localInverter} setLocalInverter={setLocalInverter}
                                               roofInstallation={roofInstallation} setRoofInstallation={setRoofInstallation}
                                               solarPanel={solarPanel} setSolarPanel={setSolarPanel}
                                               writeText={writeText} setWriteText={setWriteText}/>
                            </> :
                        geoCard ? <>
                                <CardCardApi hidden={true} cadastre={cadastre} setCadastre={setCadastre} setCardCard={setCardCard}/>
                                <GeoCardApi  geoportail={geoportail} setGeoportail={setGeoportail}
                                             geoportail5065={geoportail5065} setGeoportail5065={setGeoportail5065}
                                             geoportail0005={geoportail0005} setGeoportail0005={setGeoportail0005}
                                             geoportail0610={geoportail0610} setGeoportail0610={setGeoportail0610}
                                             geoportail1115={geoportail1115} setGeoportail1115={setGeoportail1115}/>
                                <CardEnedisApi hidden={true} posteHta={posteHta} setPosteHta={setPosteHta}
                                               lignecablehta={lignecablehta} setLignecablehta={setLignecablehta}
                                               lignecablebt={lignecablebt} setLignecablebt={setLignecablebt}
                                               setCardEnedis={setCardEnedis}/>
                                <DesignCardApi hidden={true} trench={trench} setTrench={setTrench}
                                               charginPoint={charginPoint} setCharginPoint={setCharginPoint}
                                               displayScreen={displayScreen} setDisplayScreen={setDisplayScreen}
                                               energyMeter={energyMeter} setEnergyMeter={setEnergyMeter}
                                               localInverter={localInverter} setLocalInverter={setLocalInverter}
                                               roofInstallation={roofInstallation} setRoofInstallation={setRoofInstallation}
                                               solarPanel={solarPanel} setSolarPanel={setSolarPanel}
                                               writeText={writeText} setWriteText={setWriteText}/>
                            </> :
                        <>
                            <CardCardApi hidden={true} cadastre={cadastre} setCadastre={setCadastre}
                                         setCardCard={setCardCard}/>
                            <GeoCardApi hidden={true} geoportail={geoportail} setGeoportail={setGeoportail}
                                        geoportail5065={geoportail5065} setGeoportail5065={setGeoportail5065}
                                        geoportail0005={geoportail0005} setGeoportail0005={setGeoportail0005}
                                        geoportail0610={geoportail0610} setGeoportail0610={setGeoportail0610}
                                        geoportail1115={geoportail1115} setGeoportail1115={setGeoportail1115}/>
                            <CardEnedisApi hidden={true} posteHta={posteHta} setPosteHta={setPosteHta}
                                           lignecablehta={lignecablehta} setLignecablehta={setLignecablehta}
                                           lignecablebt={lignecablebt} setLignecablebt={setLignecablebt}
                                           setCardEnedis={setCardEnedis}/>
                            <DesignCardApi hidden={true} trench={trench} setTrench={setTrench}
                                           charginPoint={charginPoint} setCharginPoint={setCharginPoint}
                                           displayScreen={displayScreen} setDisplayScreen={setDisplayScreen}
                                           energyMeter={energyMeter} setEnergyMeter={setEnergyMeter}
                                           localInverter={localInverter} setLocalInverter={setLocalInverter}
                                           roofInstallation={roofInstallation} setRoofInstallation={setRoofInstallation}
                                           solarPanel={solarPanel} setSolarPanel={setSolarPanel}
                                           writeText={writeText} setWriteText={setWriteText}/>
                        </>}
            </Grid>
        </div>

    )
}


const useStyles = makeStyles((theme) => ({
    sideBar: {
         position: 'absolute', backgroundColor: '#fff', width: 98, bottom: 0, top: '145px'
    },
    carte: {position: 'absolute', top: 120, marginLeft: 80},
    icon: {color: '#f1f1f1'},
    iconActive: {color: '#f76540'},
    geo: {
        backgroundImage: 'url(' + CarteIcon + ')', backgroundColor: "white", backgroundPosition: 'center', backgroundSize: 'cover', height: 60,marginTop: 20, backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    cadastre: {
        backgroundImage: 'url(' + CadastreIcon + ')',backgroundColor: "white", backgroundPosition: 'center', backgroundSize: 'cover', height: 60,marginTop: 20, backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    design:{
        backgroundImage: 'url(' + DesignIcon + ')',backgroundColor: "white", backgroundPosition: 'center', backgroundSize: 'cover', height: 60,marginTop: 20, backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    enedis:{
        backgroundImage: 'url(' + EnedisIcon + ')',backgroundColor: "white", backgroundPosition: 'center', backgroundSize: 'cover', height: 60,marginTop: 20, backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    pdf:{
        backgroundImage: 'url(' + PDFIcon + ')',backgroundColor: "white", backgroundPosition: 'center', backgroundSize: 'cover', height: 60,marginTop: 20, backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    }
}));
export default SideBare
