import { Marker } from '@react-google-maps/api'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import uuid from 'react-uuid'

function MarkerPosteHta() {

    const postHta =  useSelector(state => state.posteHtaReducer.data, shallowEqual)    
    console.log(postHta)

    return (
        <>
        { postHta && postHta.map(position=> {
            console.log("render marker post hta")
            return (
              <Marker
                key={uuid()}
                position={position}
                icon={"https://i.ibb.co/Qfd3SbW/Picto-transfo.png"}
              />
            )
          })}
          </>
    )
}

export default React.memo(MarkerPosteHta)
