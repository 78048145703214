import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux"
import {Button, Grid} from '@material-ui/core';
import {springboot} from '../config/api';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import SaveProjectIcon from "../assets/icons/Picto-disquette.png";
import usePOST from "../hooks/usePOST";

export default function ButtonRegisterProject() {
    const reduxUser = useSelector(state => state.userReducer.user);
    const idProject = useSelector(state => state.headerProjectReducer.idProject);
    const map = useSelector(state => state.mapReducer.map);
    const completedAddress = useSelector(state => state.headerProjectReducer.addressProject);
    const projectName = useSelector(state => state.headerProjectReducer.projectName);
    const modules = useSelector(state => state.modulesReducer.modules);
    const forms = useSelector(state => state.modulesReducer.forms);
    const rectangle = useSelector(state => state.drawPolygonReducer.rectangles);
    const power = useSelector(state => state.headerProjectReducer.power);

    const [open, setOpen] = useState(false);

    const {response, error: errorPost, sendPostRequest: setRequest} = usePOST();

    const registerProject = () => {
        let totalPower = 0;
        for (let i = 0; i < power.length; i++) {
            if (Array.isArray(power[i])) {
                totalPower += +power[i][0];
                totalPower += +power[i][1];
                console.log(totalPower)
            } else
                totalPower += +power[i];
        }

        const data = {
            idProject: idProject,
            map: JSON.stringify(map),
            completedAddress: JSON.stringify(completedAddress),
            name: projectName === undefined ? "" : projectName,
            rectangle: JSON.stringify(rectangle),
            modules: JSON.stringify(modules),
            forms: JSON.stringify(forms),
            power: totalPower,
        };

        setRequest({
            url: `/api/project/saveProject?idUser=${reduxUser.id}`,
            data: data,
            headers: {Authorization: `Bearer ${reduxUser.token}`},
            api: springboot
        });
    }

    useEffect(() => {
        if (errorPost.isError || response) {
            setOpen(true);
        }
    }, [response, errorPost]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    console.log("Enregistrement : " + response);

    return (
        <Grid>
            <Button onClick={() => {
                registerProject()
            }}
                    style={{
                        backgroundImage: 'url(' + SaveProjectIcon + ')',
                        backgroundColor: "white",
                        backgroundPosition: 'center',
                        backgroundSize: '70%',
                        height: 40,
                        backgroundRepeat: 'no-repeat'
                    }}>
            </Button>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {errorPost.isError || response !== "ACCEPTED" ? "Erreur lors de l'enregistrement du projet !" : "Le projet est enregistré avec succès !"}
                </Alert>
            </Snackbar>
        </Grid>
    );
}