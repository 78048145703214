import {
    GET_GHI,
    GET_PRODUCTIBLE,
    REORIENTE_RECTANGLE,
    SAVE_ADDRESS_PROJECT,
    SAVE_ID_PROJECT,
    SAVE_MODULES_AND_POWER,
    SAVE_PROJECT_NAME,
    SAVE_PROJECT_STATE,
    SET_ORIENTATION
} from "../_types"

export const saveIdProject = (id) => {
    return {
        type: SAVE_ID_PROJECT,
        id: id
    }
}

export const setOrientation = ( orientation) => {
    return {
        type : SET_ORIENTATION ,
        payload: orientation,
    }
}

export const saveAddress = ( address ) => {
    return {
        type : SAVE_ADDRESS_PROJECT ,
        payload: address,
    }
}

export const saveProjectName = ( name ) => {
    return {
        type : SAVE_PROJECT_NAME ,
        payload: name,
    }
}

export const saveProjectState = ( projectState ) => {
    return {
        type : SAVE_PROJECT_STATE ,
        payload: projectState,
    }
}

export const saveModulesAndPower = ( modulesAndPower ) => {
    return {
        type : SAVE_MODULES_AND_POWER ,
        payload: modulesAndPower,
    }
}

export const getProductible = ( productible ) => {
    return {
        type : GET_PRODUCTIBLE ,
        payload: productible,
    }
}

export const getGhi = ( ghi ) => {
    return {
        type : GET_GHI ,
        payload: ghi,
    }
}

export const reorienteRectangle = ( oldAndNewOrientation ) => {
    return {
        type : REORIENTE_RECTANGLE ,
        payload: oldAndNewOrientation,
    }
}