import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {localInverterActive} from "../../_actions";

function LocalInverterMarker(props){

    const dispatch = useDispatch();

    const localInverter = useSelector(state => state.localInverterReducer.localInverter , shallowEqual)
    const google = window.google;
    const map = props.map;
    let localInverterMarker;
    let dragLocalInverterMarkerListener;
    let clickLocalInverterMarkerListener;
    let LocalInverterImage = 'https://i.ibb.co/q5b1Qfy/Picto-onduleur-solaire.png';

    if(map !== null){
        if(localInverter){
            localInverterMarker = new google.maps.Marker({
                position: map.getCenter(),
                icon: {
                    url:LocalInverterImage,
                },
                map: map
            });
            dragLocalInverterMarkerListener = google.maps.event.addListener(map, 'mousemove', function (e) {
                let lat;
                const zoom = map.getZoom();
                if(zoom > 20){
                    lat = e.latLng.lat() - 0.0001 / map.getZoom()
                }else if(zoom === 20){
                    lat = e.latLng.lat() - 0.0005 / map.getZoom()
                }else if (zoom > 15 && zoom < 20){
                    lat = e.latLng.lat() - 0.001 / map.getZoom()
                }else if (zoom > 10 && zoom <= 15){
                    lat = e.latLng.lat() - 0.01 / map.getZoom()
                }
                else{
                    lat = e.latLng.lat() - 0.1 / map.getZoom();
                }
                const lng = e.latLng.lng();
                localInverterMarker.setPosition({lat:lat,lng:lng});
                //localInverterMarker.setPosition(e.latLng);
            });
            clickLocalInverterMarkerListener = google.maps.event.addListener(localInverterMarker, 'click', function (e) {
                google.maps.event.removeListener(dragLocalInverterMarkerListener);
            });
            google.maps.event.addListener(localInverterMarker, 'dblclick', function (e) {
                localInverterMarker.setMap(null);
            });
            dispatch(localInverterActive(false));
        }
        else{
            google.maps.event.removeListener(clickLocalInverterMarkerListener);
        }
    }

    return(
        <div>
            {localInverter}
        </div>
    );
}

export default React.memo(LocalInverterMarker);