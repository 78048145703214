import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./_reducers";
import { persistStore } from 'redux-persist'

const initalState = {};
const middleware = [thunk];

let store;
let persistor;
var ReactReduxDevTools;
if(process.env.REACT_APP_STORE_PROD=== "DEV"){
  ReactReduxDevTools =    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
}else {
  ReactReduxDevTools = undefined;
}

if ((window.navigator.userAgent.includes("Chrome") || window.navigator.userAgent.includes("Firefox")) &&  ReactReduxDevTools !== undefined) {
  store = createStore( rootReducer, initalState, compose(applyMiddleware(...middleware), ReactReduxDevTools) );
  persistor = persistStore(store)
} else {
  store = createStore( rootReducer, initalState, compose(applyMiddleware(...middleware)));
  persistor = persistStore(store)
}

export default {store , persistor}