import {GET_CADASTRE_REQUEST, GET_CADASTRE_SUCESS, GET_CADASTRE_FAIL, DELETE_CADASTRE, EMPTY_STORE} from "../_types"

const initialState = {
    request: undefined,
    data: undefined,
    fail: undefined,
}

const cadastreApiReducer = (state = initialState , action) => {

    switch (action.type) {
        case GET_CADASTRE_REQUEST:
            return {
                ...state,
                request: true
            }
        
        case GET_CADASTRE_SUCESS:
            return {
                ...state,
                data: state.request ? action.payload : undefined
            }
        case GET_CADASTRE_FAIL:
            return {
                ...state,
                fail:  action.payload
            }
        case DELETE_CADASTRE:
            return {
                request: false,
                data: undefined,
                fail: undefined,
            }

        case EMPTY_STORE: 
            return initialState;
        default :
            return state;
    }
}

export default cadastreApiReducer;