import {
    ADD_TO_REDUX_FROM_DB,
    CALCULATE_MODULES_OF_RECTANGLE,
    CREATION_OF_REQUIREMENT,
    DELETE_BOOL,
    DELETE_RECTANGLE,
    DUPLICATE_RECTANGLE,
    FIX_MODULES,
    INDEX_TO_DELETE,
    RECTANGLE_ID_SELECTED,
    SAVE_FORMULAIRE_INFORMATION, UNDO_MODULES
} from "../_types"


export const setFixModules = () => {
    return {
        type: FIX_MODULES
    };
}

export const creationOfRequirement = (idRectangle) => {
    return {
        type: CREATION_OF_REQUIREMENT,
        payload: idRectangle
    };
}

export const setIdRectangleSelected = (idRectangle) => {
    return {
        type: RECTANGLE_ID_SELECTED,
        payload: idRectangle,
    };
}

export const computeModulesOfSelectedRectangle = (idRectangle, listOfModulesCoordonnates, undoable) => {
    return {
        type: CALCULATE_MODULES_OF_RECTANGLE,
        payload: listOfModulesCoordonnates,
        idRectangle: idRectangle,
        undoable: undoable
    };
}

export const saveFormInformation = (idRectangle, formInformation) => {
    return {
        type: SAVE_FORMULAIRE_INFORMATION,
        payload: formInformation,
        idRectangle: idRectangle
    };
}

export const deleteRectangle = (idRectangle) => {
    return {
        type: DELETE_RECTANGLE,
        payload: idRectangle
    };
}

export const duplicateRectangle = (idRectangle) => {
    return {
        type: DUPLICATE_RECTANGLE,
        payload: idRectangle
    };
}

export const addModulesAndFormsToReduxFromDb = (forms, modules) => {
    return {
        type: ADD_TO_REDUX_FROM_DB,
        forms: forms,
        modules: modules
    };
}

export const deleteBool = (bool) => {
    return {
        type: DELETE_BOOL,
        bool: bool
    };
}

export const saveIndexToDelete = (latLng, index) => {
    return {
        type: INDEX_TO_DELETE,
        index: index,
        latLng: latLng
    };
}

export const undoModules = () => {
    return {
        type: UNDO_MODULES
    };
}