import {computeAreaPoint, computePoints, constructPolygonsWith2Areas} from "./UtilityFunctions";

function computeSlopingRoofEastWest(instalationInfo) {

    /*
      p1-----------------------------------------p4
      |  pn1--------------------------------pn4  |
      |  |                                   |   |
      |  pn2--------------------------------pn3  |
      |  ps1--------------------------------ps4  |
      |  |                                   |   |
      |  ps2--------------------------------ps3  |
      p2-----------------------------------------p3
                       x beaucoup
    */

    // google variable api
    const google = window.google;

    const width = instalationInfo.width; // metre 10 * 5
    const length = instalationInfo.height;
    const margeBetweenModules = instalationInfo.margeBetweenModules;
    const betweenHat = instalationInfo.betweenHat;
    const margeBetweenSide = 0.5;

    const p1 = new google.maps.LatLng({
        lat: instalationInfo.coordinates[0].lat,
        lng: instalationInfo.coordinates[0].lng
    });
    const p2 = new google.maps.LatLng({
        lat: instalationInfo.coordinates[1].lat,
        lng: instalationInfo.coordinates[1].lng
    });
    const p3 = new google.maps.LatLng({
        lat: instalationInfo.coordinates[2].lat,
        lng: instalationInfo.coordinates[2].lng
    });
    const p4 = new google.maps.LatLng({
        lat: instalationInfo.coordinates[3].lat,
        lng: instalationInfo.coordinates[3].lng
    });

    // compute 4 different orientations
    const orientation12 = google.maps.geometry.spherical.computeHeading(p1, p2);
    const orientation21 = google.maps.geometry.spherical.computeHeading(p2, p1);
    const orientation23 = google.maps.geometry.spherical.computeHeading(p2, p3);
    const orientation32 = google.maps.geometry.spherical.computeHeading(p3, p2);

    // compute the first point of the solar panel area
    let pn1 = computeAreaPoint(p1, margeBetweenSide, margeBetweenSide, orientation12, orientation23);
    let pn4 = computeAreaPoint(p4, margeBetweenSide, margeBetweenSide, orientation12, orientation32);

    //  compute the last point of the solar panel area
    let ps2 = computeAreaPoint(p2, margeBetweenSide, margeBetweenSide, orientation21, orientation23);

    // compute width of the solar panel area
    const areaWidth = google.maps.geometry.spherical.computeDistanceBetween(pn1, ps2);

    const computeLines = (areaWidth, p1) => {
        const lineWidth = (width * 2) + margeBetweenModules;
        let pointsOfLines = [];

        const nextPoint = google.maps.geometry.spherical.computeOffset(p1, lineWidth, orientation12);
        if (areaWidth >= google.maps.geometry.spherical.computeDistanceBetween(p1, nextPoint))
            pointsOfLines.push(p1);
        else
            return pointsOfLines;

        while (true) {
            const nextPoint = google.maps.geometry.spherical.computeOffset(pointsOfLines[pointsOfLines.length - 1], (betweenHat + (lineWidth * 2)), orientation12);
            if (areaWidth >= google.maps.geometry.spherical.computeDistanceBetween(p1, nextPoint))
                pointsOfLines.push(google.maps.geometry.spherical.computeOffset(pointsOfLines[pointsOfLines.length - 1], (betweenHat + lineWidth), orientation12));
            else
                return pointsOfLines;
        }
    }

    // compute number of modules that fit in the area
    const numberOfModule = {
        col: Math.floor(google.maps.geometry.spherical.computeDistanceBetween(pn1, pn4) / length),
        row: 1
    };

    const beginningOfLines = computeLines(areaWidth, pn1);

    let eastPanelCoords = [];
    let westPanelCoords = [];
    
    beginningOfLines.forEach((beginningOfLine) => {
        eastPanelCoords.push(...constructPolygonsWith2Areas(computePoints(numberOfModule, {length: length, width: width}, beginningOfLine, {first: orientation23, second: orientation12})));
        westPanelCoords.push(...constructPolygonsWith2Areas(computePoints(numberOfModule, {length: length, width: width},
            google.maps.geometry.spherical.computeOffset(beginningOfLine, width + margeBetweenModules, orientation12), {first: orientation23, second: orientation12})));
    });  

    //console.log("Coords modules pan Est : ", eastPanelCoords);
    //console.log("Coords modules pan Ouest : ", westPanelCoords);

    return [westPanelCoords, eastPanelCoords];
}

export default computeSlopingRoofEastWest;
