import {CHANGE_MAP, PROJECT_MAP_INFORMATION} from "../_types"


export const registerMapInformation = (mapInformation) => {
    return {
        type : PROJECT_MAP_INFORMATION ,
        payload: mapInformation,
    }
}

export const changeMapKey = () => {
    return {
        type : CHANGE_MAP
    }
}