import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {charginPointActive} from "../../_actions";

function CharginPointMarker(props){

    const dispatch = useDispatch();

    const charginPoint = useSelector(state => state.charginPointReducer.charginPoint , shallowEqual)
    const google = window.google;
    const map = props.map;
    let charginPointMarker;
    let dragCharginPointMarkerListener;
    let clickCharginPointMarkerListener;
    let CharginPointImage = 'https://i.ibb.co/t3dTJLD/Picto-borne-de-recharge.png';


    if(map !== null){
        if(charginPoint){
            charginPointMarker = new google.maps.Marker({
                position: map.getCenter(),
                icon: {
                    url: CharginPointImage,
                },
                map: map
            });
            dragCharginPointMarkerListener = google.maps.event.addListener(map, 'mousemove', function (e) {
                let lat;
                const zoom = map.getZoom();
                if(zoom > 20){
                    lat = e.latLng.lat() - 0.0001 / map.getZoom()
                }else if(zoom === 20){
                    lat = e.latLng.lat() - 0.0005 / map.getZoom()
                }else if (zoom > 15 && zoom < 20){
                    lat = e.latLng.lat() - 0.001 / map.getZoom()
                }else if (zoom > 10 && zoom <= 15){
                    lat = e.latLng.lat() - 0.01 / map.getZoom()
                }
                else{
                    lat = e.latLng.lat() - 0.1 / map.getZoom();
                }
                const lng = e.latLng.lng();
                charginPointMarker.setPosition({lat:lat,lng:lng});
            });
            clickCharginPointMarkerListener = google.maps.event.addListener(charginPointMarker, 'click', function (e) {
                google.maps.event.removeListener(dragCharginPointMarkerListener);
            });
            google.maps.event.addListener(charginPointMarker, 'dblclick', function (e) {
                charginPointMarker.setMap(null);
            });
            dispatch(charginPointActive(false));

        }
        else{
            google.maps.event.removeListener(clickCharginPointMarkerListener);
            google.maps.event.removeListener(dragCharginPointMarkerListener);
        }
    }

    return(
        <div>
            {charginPoint}
        </div>
    );
}

export default React.memo(CharginPointMarker);