import {EMPTY_STORE, IS_EARTHQUAKE_ACTIVE} from "../_types";

const initialState = {
    earthquake: undefined,
}

const earthquakeReducer = (state = initialState , action) => {
    switch (action.type) {
        case IS_EARTHQUAKE_ACTIVE:
            return {
                ...state,
                earthquake: action.payload
            }
        case EMPTY_STORE:
            return initialState;
        default :
            return {...state};
    }
}

export default earthquakeReducer;