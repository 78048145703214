import {EMPTY_STORE, PROJECT_MAP_INFORMATION, CHANGE_MAP} from "../_types"
import uuid from 'react-uuid'

const initialState = {
    map: undefined,
    key: undefined,
}



const mapReducer = (state = initialState , action) => {

    switch (action.type) {
        case PROJECT_MAP_INFORMATION:
            return {
                ...state,
                map: action.payload
            }
        case CHANGE_MAP :
            return {
                ...state,
                key: uuid()
            }
        
        case EMPTY_STORE:
            return initialState
        default :
            return state;
    }
}

export default mapReducer;