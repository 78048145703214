const calculateCoordinates = (rectangleCoords, size) => {
    const google = window.google;

    const p1 = new google.maps.LatLng({lat: rectangleCoords[0].lat, lng: rectangleCoords[0].lng});
    const p2 = new google.maps.LatLng({lat: rectangleCoords[1].lat, lng: rectangleCoords[1].lng});
    const p3 = new google.maps.LatLng({lat: rectangleCoords[2].lat, lng: rectangleCoords[2].lng});
    const p4 = new google.maps.LatLng({lat: rectangleCoords[3].lat, lng: rectangleCoords[3].lng});

    const orientation12 = google.maps.geometry.spherical.computeHeading(p1, p2);
    const orientation23 = google.maps.geometry.spherical.computeHeading(p2, p3);

    let p2_prime = google.maps.geometry.spherical.computeOffset(p1, size.width, orientation12);
    let p3_prime = google.maps.geometry.spherical.computeOffset(p2_prime, size.length, orientation23);
    let p4_prime = google.maps.geometry.spherical.computeOffset(p1, size.length, orientation23);

    return [
        {lat: p1.lat(), lng: p1.lng()},
        {lat: p2_prime.lat(), lng: p2_prime.lng()},
        {lat: p3_prime.lat(), lng: p3_prime.lng()},
        {lat: p4_prime.lat(), lng: p4_prime.lng()}
    ];
}

export default calculateCoordinates;