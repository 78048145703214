import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {roofInstallationActive} from "../../_actions";

function RoofInstallationMarker(props){

    const dispatch = useDispatch();

    const roofInstallation = useSelector(state => state.roofInstallationReducer.roofInstallation , shallowEqual)
    const google = window.google;
    const map = props.map;
    let roofInstallationMarker;
    let dragRoofInstallationMarkerListener;
    let clickRoofInstallationMarkerListener;
    let RoofInstallationImage = 'https://i.ibb.co/TWmjBWR/Picto-installation-sur-toiture.png';

    if(map !== null){
        if(roofInstallation){
            roofInstallationMarker = new google.maps.Marker({
                position: map.getCenter(),
                icon: {
                    url:RoofInstallationImage,
                },
                map: map
            });
            dragRoofInstallationMarkerListener = google.maps.event.addListener(map, 'mousemove', function (e) {
                let lat;
                const zoom = map.getZoom();
                if(zoom > 20){
                    lat = e.latLng.lat() - 0.0001 / map.getZoom()
                }else if(zoom === 20){
                    lat = e.latLng.lat() - 0.0005 / map.getZoom()
                }else if (zoom > 15 && zoom < 20){
                    lat = e.latLng.lat() - 0.001 / map.getZoom()
                }else if (zoom > 10 && zoom <= 15){
                    lat = e.latLng.lat() - 0.01 / map.getZoom()
                }
                else{
                    lat = e.latLng.lat() - 0.1 / map.getZoom();
                }
                const lng = e.latLng.lng();
                roofInstallationMarker.setPosition({lat:lat,lng:lng});
                //roofInstallationMarker.setPosition(e.latLng);
            });
            clickRoofInstallationMarkerListener = google.maps.event.addListener(roofInstallationMarker, 'click', function (e) {
                google.maps.event.removeListener(dragRoofInstallationMarkerListener);
            });
            google.maps.event.addListener(roofInstallationMarker, 'dblclick', function (e) {
                roofInstallationMarker.setMap(null);
            });
            dispatch(roofInstallationActive(false));
        }
        else{
            google.maps.event.removeListener(clickRoofInstallationMarkerListener);
        }
    }

    return(
        <div>
            {roofInstallation}
        </div>
    );
}

export default React.memo(RoofInstallationMarker);