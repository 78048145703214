import computeOnTheGround from "./computeOnTheGround";
import computeSlopingRoof from "./computeSlopingRoof"
import computeOmbriereSimple from "./computeOmbriereSimple"
import computeOmbriereDouble from "./computeOmbriereDouble";
import computeSlopingRoofEastWest from "./computeSlopingRoofEastWest"
import computeSymmetricalShed from "./computeSymmetricalShed";
import computeUnsymmetricalShed from "./computeUnsymmetricalShed";
import computeCantal from "./computeCantal";

export default function computeModules(dataFormulaire) {
    dataFormulaire.margeBetweenModules = 0.02;

    switch (dataFormulaire.implantationType) {
        case "Toiture inclinée":
        case "Toiture terrasse à plat" :
            dataFormulaire.margeBetweenLinesOfModules = 0.02;
            return computeSlopingRoof(dataFormulaire);

        case "Installation au sol" :
            dataFormulaire.margeBetweenModules = 0.02;
            if (dataFormulaire.paysage) {
                dataFormulaire.margeBetweenLinesOfModules = dataFormulaire.width * 4 * Math.sin(dataFormulaire.inclinaison * Math.PI / 180) * Math.tan(69 * Math.PI / 180);
            } else {
                dataFormulaire.margeBetweenLinesOfModules = dataFormulaire.height * 2 * Math.sin(dataFormulaire.inclinaison * Math.PI / 180) * Math.tan(69 * Math.PI / 180);
            }
            return computeOnTheGround(dataFormulaire);

        case "Toiture terrasse plein sud":
            if (dataFormulaire.paysage) {
                dataFormulaire.margeBetweenLinesOfModules = dataFormulaire.width * Math.sin(dataFormulaire.inclinaison * Math.PI / 180) * Math.tan(69 * Math.PI / 180);
            } else {
                dataFormulaire.margeBetweenLinesOfModules = dataFormulaire.height * (Math.sin(dataFormulaire.inclinaison * Math.PI / 180)) * (Math.tan(69 * Math.PI / 180));
            }
            return computeSlopingRoof(dataFormulaire);

        case "Toiture terrasse Est-Ouest":
            // change hauteur et largeur des modules
            dataFormulaire.betweenHat = 0.5; // Calcule métier
            dataFormulaire.margeBetweenLinesOfModules = 0.02;
            return computeSlopingRoofEastWest(dataFormulaire);

        case "Ombrière simple place":
            console.log(dataFormulaire.width * 6 * Math.sin(dataFormulaire.inclinaison * Math.PI / 180) * Math.tan(69 * Math.PI / 180));
            if (dataFormulaire.paysage) {
                dataFormulaire.margeBetweenLinesOfModules = Math.max(dataFormulaire.width * 6 * Math.sin(dataFormulaire.inclinaison * Math.PI / 180) * Math.tan(69 * Math.PI / 180), 5);
            } else {
                dataFormulaire.margeBetweenLinesOfModules = Math.max(dataFormulaire.height * 3 * Math.sin(dataFormulaire.inclinaison * Math.PI / 180) * Math.tan(69 * Math.PI / 180), 5);
            }
            return computeOmbriereSimple(dataFormulaire);

        case "Ombrière double places" :
            if (dataFormulaire.paysage) {
                dataFormulaire.margeBetweenLinesOfModules = Math.max(dataFormulaire.width * 12 * Math.sin(dataFormulaire.inclinaison * Math.PI / 180) * Math.tan(69 * Math.PI / 180), 5);
            } else {
                dataFormulaire.margeBetweenLinesOfModules = Math.max(dataFormulaire.height * 6 * Math.sin(dataFormulaire.inclinaison * Math.PI / 180) * Math.tan(69 * Math.PI / 180), 5);
            }
            return computeOmbriereDouble(dataFormulaire);

        case "Hangar Symétrique 60m/26m":
            dataFormulaire.margeBetweenLinesOfModules = 0.02;
            dataFormulaire.paysage = true;
            dataFormulaire.coteCourt = false;
            dataFormulaire.inclinaison = 12;
            return computeSymmetricalShed(dataFormulaire);

        case "Hangar Symétrique 51m/26m":
            dataFormulaire.margeBetweenLinesOfModules = 0.02;
            dataFormulaire.paysage = true;
            dataFormulaire.coteCourt = false;
            dataFormulaire.inclinaison = 12;
            return computeSymmetricalShed(dataFormulaire);

        case "Hangar Asymétrique 60m/26m":
            dataFormulaire.margeBetweenLinesOfModules = 0.02;
            dataFormulaire.paysage = true;
            dataFormulaire.coteCourt = false;
            dataFormulaire.inclinaison = 17;
            return computeUnsymmetricalShed(dataFormulaire);

        case "Hangar Asymétrique 51m/26m":
            dataFormulaire.margeBetweenLinesOfModules = 0.02;
            dataFormulaire.paysage = true;
            dataFormulaire.coteCourt = false;
            dataFormulaire.inclinaison = 17;
            return computeUnsymmetricalShed(dataFormulaire);

        case "Hangar cantale 60m/28m":
            dataFormulaire.margeBetweenLinesOfModules = 0.02;
            dataFormulaire.paysage = true;
            dataFormulaire.coteCourt = false;
            dataFormulaire.inclinaison = 17;
            return computeCantal(dataFormulaire);
    }
}