import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {hot} from 'react-hot-loader/root';
import {
    AddUser,
    CorporateProjects,
    Home,
    ListOfProjects,
    ListOfUsers,
    MyProjects,
    Profile,
    Project,
    SignIn
} from "./screens"
import RenewPassword from './screens/RenewPassword';
import {ThemeProvider} from '@material-ui/core';
import theme from './theme';
import RouteSecured from './security/RouteSecured';

console.log = ()=>{};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route exact path="/signIn" element={<SignIn/>}/>
                    <Route exact path="/" element={<SignIn/>}/>

                    <Route path="/home"
                           element={
                        <RouteSecured>
                            <Home/>
                        </RouteSecured>
                    }/>
                    <Route path="/project"
                           element={<RouteSecured><Project/></RouteSecured>}/>
                    <Route path="/corporateProjects"
                           element={<RouteSecured><CorporateProjects/></RouteSecured>}/>
                    <Route path="/listOfProjects"
                           element={<RouteSecured><ListOfProjects/></RouteSecured>}/>
                    <Route path="/listOfUsers"
                           element={<RouteSecured><ListOfUsers/></RouteSecured>}/>
                    <Route path="/profile"
                           element={<RouteSecured><Profile/></RouteSecured>}/>
                    <Route path="/renewPassword"
                           element={<RouteSecured><RenewPassword/></RouteSecured>}/>
                    <Route path="/addUser"
                           element={<RouteSecured><AddUser/></RouteSecured>}/>
                    <Route path="/yourProjects"
                           element={<RouteSecured><MyProjects/></RouteSecured>}/>
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default hot(App);
