export const calcultaePowerAndModules = (modules, forms) => {
    console.log('MODULE CALCUL', modules, forms)
    let numberModules = 0;
    let power = 0;
    const numberModulesTab = [];
    const powerTab = [];
    modules.forEach((element, index) => {
        if (element && element.modules && element.modules.length !== 0) {

            if (Array.isArray(element.modules[0][0])) {
                let numberModulesTabTemp = [];
                let powerTabTemp = [];
                element.modules.forEach(nestedElement => {
                    numberModules = numberModules + nestedElement.length;
                    power = power + (forms[index].form.power * nestedElement.length);
                    numberModulesTabTemp.push(nestedElement.length);
                    powerTabTemp.push(((forms[index].form.power * nestedElement.length) / 1000).toFixed(1));
                });
                numberModulesTab.push(numberModulesTabTemp);
                powerTab.push(powerTabTemp);
            } else {
                numberModules = numberModules + element.modules.length;
                power = power + (forms[index].form.power * element.modules.length);
                numberModulesTab.push(element.modules.length);
                powerTab.push(((forms[index].form.power * element.modules.length) / 1000).toFixed(1));
            }
        }
    });

    return {modules: numberModules, power: (power / 1000).toFixed(1), modulesTab: numberModulesTab, powerTab: powerTab}
}