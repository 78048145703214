import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Tooltip } from '@material-ui/core';
import {useDispatch} from "react-redux";
import {
    charginPointActive,
    displayScreenActive,
    energyMeterActive,
    localInverterActive,
    roofInstallationActive,
    setIsDrawingTrench,
    solarPanelActive,
    writeTextActive
} from "../_actions";
import CharginPointImage from "../assets/Design/Picto-borne-de-recharge.png";
import DisplayScreenImage from "../assets/Design/Picto-ecran.png";
import EnergyMeterImage from "../assets/Design/Picto-compteur-energie.png";
import LocalInverterImage from "../assets/Design/Picto-onduleur-solaire.png";
import RoofInstallationImage from "../assets/Design/Picto-installation-sur-toiture.png";
import SolarPanelImage from "../assets/Design/Picto-panneau-solaire.png";
import TextImage from "../assets/Design/Picto-texte.png";
import TrenchImage from "../assets/Design/Picto-tranche.png";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

function DesignCardApi({trench, setTrench, charginPoint, setCharginPoint,
                           displayScreen, setDisplayScreen, energyMeter, setEnergyMeter,
                           localInverter, setLocalInverter, roofInstallation, setRoofInstallation,
                           solarPanel, setSolarPanel, writeText, setWriteText, hidden}){

    const dispatch = useDispatch();

    const onClickDrawTrench = () => {
        setTrench(true);
        dispatch(setIsDrawingTrench(true))
    }

    const onClickAddCharginPoint = () => {
        setCharginPoint(true);
        setDisplayScreen(false);
        setEnergyMeter(false);
        setLocalInverter(false);
        setRoofInstallation(false);
        setSolarPanel(false);
        setWriteText(false);
        setTrench(false);
        dispatch(charginPointActive(true))
    }

    const onClickAddDisplayScreen = () => {
        setCharginPoint(false);
        setDisplayScreen(true);
        setEnergyMeter(false);
        setLocalInverter(false);
        setRoofInstallation(false);
        setSolarPanel(false);
        setWriteText(false);
        setTrench(false);
        dispatch(displayScreenActive(true))
    }

    const onClickAddEnergyMeter = () => {
        setCharginPoint(false);
        setDisplayScreen(false);
        setEnergyMeter(true);
        setLocalInverter(false);
        setRoofInstallation(false);
        setSolarPanel(false);
        setWriteText(false);
        setTrench(false);
        dispatch(energyMeterActive(true))
    }

    const onClickAddLocalInverter = () => {
        setCharginPoint(false);
        setDisplayScreen(false);
        setEnergyMeter(false);
        setLocalInverter(true);
        setRoofInstallation(false);
        setSolarPanel(false);
        setWriteText(false);
        setTrench(false);
        dispatch(localInverterActive(true))
    }

    const onClickAddRoofInstallation = () => {
        setCharginPoint(false);
        setDisplayScreen(false);
        setEnergyMeter(false);
        setLocalInverter(false);
        setRoofInstallation(true);
        setSolarPanel(false);
        setWriteText(false);
        setTrench(false);
        dispatch(roofInstallationActive(true))
    }

    const onClickAddSolarPanel = () => {
        setCharginPoint(false);
        setDisplayScreen(false);
        setEnergyMeter(false);
        setLocalInverter(false);
        setRoofInstallation(false);
        setSolarPanel(true);
        setWriteText(false);
        setTrench(false);
        dispatch(solarPanelActive(true));
    }

    const onClickAddText = () => {
        setCharginPoint(false);
        setDisplayScreen(false);
        setEnergyMeter(false);
        setLocalInverter(false);
        setRoofInstallation(false);
        setSolarPanel(false);
        setWriteText(true);
        setTrench(false);
        dispatch(writeTextActive(true));
    }

    return(
        <Paper elevation={2} style={{display: hidden === true ? 'none' : 'flex', flexDirection: 'column', justifyContent:'center',  width: 315, height: 290, marginLeft: 10, marginTop: 190, padding: 20}}>
        <Grid container spacing={4}>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={4}>
                    <Tooltip title="Borne de recharge" placement={"right"}>
                    <Button onClick={onClickAddCharginPoint}
                            style={{ backgroundImage: 'url(' + CharginPointImage + ')', backgroundPosition: 'center', backgroundSize: 'cover', height: 70, backgroundRepeat: 'no-repeat'}}>
                    </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={4}>
                    <Tooltip title="Écran d'affichage" placement={"right"}>
                    <Button onClick={onClickAddDisplayScreen}
                            style={{backgroundImage: 'url(' + DisplayScreenImage + ')', backgroundPosition: 'center', backgroundSize: 'cover', height: 70, backgroundRepeat: 'no-repeat'}}>
                    </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={4}>
                    <Tooltip title="Compteur d'énergie" placement={"right"}>
                    <Button onClick={onClickAddEnergyMeter}
                            style={{backgroundImage: 'url(' + EnergyMeterImage + ')', backgroundPosition: 'center', backgroundSize: 'cover', height: 70, backgroundRepeat: 'no-repeat'}}>
                    </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={4}>
                    <Tooltip title="Onduleur local technique" placement={"right"}>
                    <Button onClick={onClickAddLocalInverter}
                            style={{backgroundImage: 'url(' + LocalInverterImage + ')', backgroundPosition: 'center', backgroundSize: 'cover', height: 70, backgroundRepeat: 'no-repeat'}}>
                    </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={4}>
                    <Tooltip title="Installation sur toiture" placement={"right"}>
                    <Button onClick={onClickAddRoofInstallation}
                            style={{backgroundImage: 'url(' + RoofInstallationImage + ')', backgroundPosition: 'center', backgroundSize: 'cover', height: 70, backgroundRepeat: 'no-repeat'}}>
                    </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={4}>
                    <Tooltip title="Panneau solaire" placement={"right"}>
                    <Button onClick={onClickAddSolarPanel}
                            style={{backgroundImage: 'url(' + SolarPanelImage + ')', backgroundPosition: 'center', backgroundSize: 'cover', height: 70, backgroundRepeat: 'no-repeat'}}>
                    </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={4}>
                    <Tooltip title="Ajouter du texte" placement={"right"}>
                        <Button onClick={onClickAddText}
                                style={{backgroundImage: 'url(' + TextImage + ')', backgroundPosition: 'center', backgroundSize: 'cover', height: 70, backgroundRepeat: 'no-repeat'}}/>
                    </Tooltip>
                </Grid>
                <Grid item xs={4}>
                    <Tooltip title="Tracer une tranchée" placement={"right"}>
                        <Button id="trench-button-id"
                            //onClick={onClickDrawTrench}
                            style={{backgroundImage: 'url(' + TrenchImage + ')', backgroundPosition: 'center', backgroundSize: 'cover', height: 70, backgroundRepeat: 'no-repeat'}}>
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={4}>
                    <Tooltip title="Arrêter le traçage" placement={"right"}>
                        <Button id="stop-trench-button-id"
                                variant="outlined"
                                style={{ backgroundPosition: 'center', border: '1px solid #FF9300', backgroundSize: 'cover', height: 50, backgroundRepeat: 'no-repeat'}}>
                            <HighlightOffIcon fontSize='medium'/>
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
        </Paper>
    );
}


export default React.memo(DesignCardApi);