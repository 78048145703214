import {LOGIN_USER} from "../_types"
import { EMPTY_USER_STORE } from "../_types/userType"


export const loginUser = (user) => {
    return {
        type : LOGIN_USER ,
        payload: user,
    }
}

export const emptyUserStore = () => {
    return {
        type : EMPTY_USER_STORE ,
    }
}