import {ADD_POINT, EMPTY_STORE, IS_MEASURING} from "../_types";

const initialState = {
    measure: undefined,
    points: [],
}


const drawRulerReducer = (state = initialState , action) => {

    switch (action.type) {
        case IS_MEASURING:
            return {
                ...state,
                measure: action.payload
            }
        case ADD_POINT:
            return {
                ...state,
                points: [...state.points , action.payload]
            }
        case EMPTY_STORE:
            return initialState;
        default :
            return state;
    }

}

export default drawRulerReducer;