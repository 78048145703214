import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import usePOST from "../hooks/usePOST";
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router";
import Copyright from "../components/Copyright"
import {springboot} from "../config/api"
import {loginUser} from '../_actions';
import jwt_decode from "jwt-decode";
import logo from "../assets/logo.png";
import UpdatePopup from "../components/UpdatePopup";


/**
 * Component SignIn user
 */
function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState({email: false, password: false});
    const {response, error: errorPost, sendPostRequest: setRequest} = usePOST();

    let navigate = useNavigate();

    const dispatch = useDispatch();

    const classes = useStyles();

    const submitHandler = async (event) => {
        event.preventDefault();

        if (!email.includes('@')) {
            setError({email: true, password: false});
            console.log(error)
            return;
        } else if (password.trim() === '') {
            setError({email: false, password: true});
            console.log(error)
            return;
        }

        const loginForm = {
            email: email,
            password: password,
        }

        await setRequest({url: `/api/users/signin`, data: loginForm, authorization: {}, api: springboot});
    }

    useEffect(() => {
        if (response !== null && response !== undefined) {
            let decoded = jwt_decode(response.token);
            dispatch(loginUser({...response, tokenId: decoded.tokenId}))
            navigate('/home')
        } else {
            if (errorPost.error) {
                console.log("Error during auth : ");
                console.log(errorPost);
                document.getElementById("wrong-password").style.display = "block";
            }
        }
    }, [response, errorPost]);

    // When updated is needed <UpdatePopup />
    return (
        <div>
            <img src={logo} alt="Logo" style={{width: 100}}/>
            <Grid container className={classes.root}>
                <div className={classes.paper}>
                    <Box mb={3}>
                        <Typography variant="h1" align="center"><b> SE CONNECTER</b></Typography>

                    </Box>
                    <form onSubmit={submitHandler} className={classes.form}>
                        <Typography id="wrong-password" className={classes.message} align="center">
                            Adresse mail ou mot de passe non valide !
                        </Typography>
                        <CssTextField
                            label="Adresse Email"
                            variant="outlined"
                            required
                            error={error.email || errorPost.isError}
                            fullWidth
                            margin="normal"
                            onChange={(event) => {
                                setEmail(event.target.value)
                            }}
                        />

                        <CssTextField
                            label="Mot de passe"
                            variant="outlined"
                            required
                            error={error.password || errorPost.isError}
                            fullWidth
                            margin="normal"
                            type="password"
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}
                        />
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center",}}>
                            <Button type="submit" variant="contained" className={classes.submit}>
                                SE CONNECTER
                            </Button>
                        </div>
                        {/*<Grid container>*/}
                        {/*    <Grid item xs>*/}
                        {/*        <Link href="#" variant="body2" href="/renewPassword">Mot de passe oublié? </Link>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </form>
                    <Box mt={5}>
                        <Copyright/>
                    </Box>
                </div>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        marginTop: "100px",
        alignItems: 'center',

    },
    message: {
        color: "#BE1616",
        display: "none",
    },
    paper: {
        width: "30%",
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    submit: {
        width: "300px",
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#FF9300',
        fontFamily: "neutra-bold",
        fontSize: 18,
        color: "#FFF",
        fontWeight: "bold",
        border: "1px solid #FF9300",
        borderRadius: "50px",
        '&:hover': {
            background: "#FF9300",
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#FF9300',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#FF9300',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: "20px",
            '& fieldset': {
                borderColor: '#FF9300',
            },
            '&:hover fieldset': {
                borderColor: '#FF9300',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#FF9300',
            },
        },

    },
})(TextField);

export default SignIn;
