import {useState, useEffect} from 'react'
import axios from 'axios'
/**
 * Hook personaliser : Pour tous les requete put vers les apis.
 * @param {*} url  Uri de l'api
 * @param {*} data Data body si necessaire
 * @param {*} type Type pour
 */
function usePUT(url, data, authorization, api) {

    const [initialRequest, setInitialRequest] = useState({url : url,data: data, authorization: authorization, api: api});
    const [response, setResponse] = useState();
    let result ;

    useEffect(() => {

        const callApi = async ()  =>{
            try {
                if(initialRequest.api === "solargis-proxy"){
                    result = await axios.put(initialRequest.url , initialRequest.data, initialRequest.authorization)
                    setResponse(result.data)
                }else {

                    // Le cas ou la requete est sans body
                    if (initialRequest.url !== '' && Object.keys(initialRequest.data).length === 0){
                        console.log(initialRequest.url)
                        result = await initialRequest.api.put(initialRequest.url, initialRequest.authorization)
                        setResponse(result.data)
                    }
                    // La requete est avec un body
                    else if(initialRequest.url !== ''){
                        console.log(initialRequest.url, initialRequest.data, initialRequest.authorization)
                        result = await initialRequest.api.put(initialRequest.url , initialRequest.data, initialRequest.authorization)
                        setResponse(result.data)
                    }
                }

            } catch (error) {
                // error
                console.log("erreur", error.response)
            }

        }

        callApi();

    }, [initialRequest])

    return [response, setInitialRequest];
}

export default usePUT