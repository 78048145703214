export const calculateOrientation = (listCoordinates) => {
    const google = window.google;
    let orientation;

    const p1 = new google.maps.LatLng({lat: listCoordinates[0].lat, lng: listCoordinates[0].lng});
    const p2 = new google.maps.LatLng({lat: listCoordinates[1].lat, lng: listCoordinates[1].lng});

    orientation = Math.trunc(google.maps.geometry.spherical.computeHeading(p1,p2));

    return orientation
}