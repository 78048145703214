import React , {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { useNavigate } from "react-router";
import usePOST from "../hooks/usePOST"
import {Header} from "../components"
import ComingSoon from './ComingSoon'

/**
 * Component for Add  user.
 */
function AddUser() {

    const userRedux = undefined;

    let navigate = useNavigate();
    const classes = useStyles();

    const [firstName] = useState();
    const [lastName] = useState();
    const [phone] = useState();
    const [email] = useState();
    const [password] = useState();
    const [confirmPassword] = useState();

    const [response, setRequest] = usePOST({url :'', data :{}, authorization: {}});

    const submit = (event) =>{
        event.preventDefault();
        if (password !== confirmPassword){
            console.log("error : password not identique")
        }else {
            const user = {
                idUserAdmin:userRedux.id ,
                firstname : firstName,
                lastname : lastName,
                email : email,
                phone : phone,
                password: password,
                roles : ["ROLE_USER"],
                status : true,
            }

            setRequest({url :`/api/users/addNewUser`, data :user, headers: { Authorization: `Bearer ${userRedux.token}` }})
        }
    }

    useEffect(() => {
        if(response !== undefined && response?.toString() !== 'NOT_ACCEPTABLE'){
            navigate('/home')
        } 
    }, [response])

    return (
        
        <Grid container direction='column'>
            <Grid item>
                <Header/>
            </Grid>
            <Grid item style={{marginTop: 20}}>
                <ComingSoon/>
            </Grid>
        </Grid>
    );
}

export default AddUser ;

const theme = createTheme({
    palette: {
      primary: {main: '#f76540'}
    },
});
const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(20),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: '#f76540',
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      backgroundColor: '#f76540'
    },
}));

{/*<Container component="main" maxWidth="sm">
            <Header/>
        <div className={classes.paper}>
            <Typography component="h1" variant="h3">Ajouter un utilisateur</Typography>
            <form className={classes.form} required>
                <ThemeProvider theme={theme}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField variant="outlined" required fullWidth label="Prenom" onChange={(event)=>{setFirstName(event.target.value)}} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField variant="outlined" required fullWidth label="Nom" onChange={(event)=>{setLastName(event.target.value)}} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" required fullWidth label="Numéro de téléphone" onChange={(event)=>{setPhone(event.target.value)}} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" required fullWidth label="Adresse Email" onChange={(event)=>{setEmail(event.target.value)}} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" required fullWidth label="Mot de passe" type="password" onChange={(event)=>{setPassword(event.target.value)}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" required fullWidth label="Confirmer le mot de passe" type="password" onChange={(event)=>{setConfirmPassword(event.target.value)}}/>
                    </Grid>
                </Grid>
                </ThemeProvider>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={submit} > Envoyer </Button>
                
            </form>
        </div>
        <Box mt={5}>
            <Copyright />
        </Box>
    </Container>*/}