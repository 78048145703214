import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import {Header} from "../components"

import ComingSoon from './ComingSoon';


function ListOfUsers() {

    /*const userRedux = useSelector(state => state.userReducer.user);

    const classes = useStyles();

    const [data, setData] = useState(null);

    const [response, setRequest] = useGET(`/api/users/getUsersByCompany?id=${userRedux.id}`, {}, { Authorization: `Bearer ${userRedux.token}`});

    const columns = [
        { field: 'firstname', headerName: 'Prénom', width: 130 },
        { field: 'lastname', headerName: 'Nom', width: 130 },
        { field: 'email', headerName: 'Adresse email', width: 300 },
        { field: 'phone', headerName: 'Téléphone', type: 'number',width: 130,},
        { field: 'status', headerName: 'Validité', width: 130 },
    ];

    useEffect(() => {
        console.log("response" , response)
        setData(response)
    }, [response])

    useEffect(() => {
        console.log("data" , data)
    }, [data])*/

    return (
        <Grid container direction='column'>
            <Grid item>
                <Header/>
            </Grid>
            <Grid item style={{marginTop: 20}}>
                <ComingSoon/>
            </Grid>
        </Grid>


    )
}

export default ListOfUsers;

const theme = createTheme({
    palette: {
      primary: {main: '#f76540'}
    },
});
const useStyles = makeStyles((theme) => ({
    title :{
        textAlign: 'center', 
        fontFamily: 'monospace', 
        fontWeight: 'bold'
    },
}));

        {/*<Grid container direction='column'>
            <Grid item>
                <Header/>
            </Grid>
            <Grid item style={{paddingTop: 100, paddingBottom: 20}}>
                <Typography variant='h4' className={classes.title}>Liste des utilisateurs</Typography>
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style={{width : 870, height: 500}}>
                    {data !== null && data !== undefined ? <DataGrid rows={data} columns={columns} pageSize={5} checkboxSelection /> : null}
                </div>   
            </Grid>
    </Grid>*/}