import React from 'react'
import {useSelector} from "react-redux"
import {Navigate} from "react-router";
import {Route} from "react-router-dom";

function RouteSecured({children}) {

    const user = useSelector(state => state.userReducer.user);
    // tester si le token est valide. TO DO
    return (
        user?.token ? <>{children}</> : <Navigate to="/signIn"/>
    );
}

export default RouteSecured
