export const FIX_MODULES = "FIX_MODULES";
export const CREATION_OF_REQUIREMENT = "CREATION_OF_REQUIREMENT";
export const RECTANGLE_ID_SELECTED = "RECTANGLE_ID_SELECTED";
export const CALCULATE_MODULES_OF_RECTANGLE = "CALCULATE_MODULES_OF_RECTANGLE";
export const SAVE_FORMULAIRE_INFORMATION = "SAVE_FORMULAIRE_INFORMATION";
export const DELETE_RECTANGLE = "DELETE_RECTANGLE";
export const DUPLICATE_RECTANGLE = "DUPLICATE_RECTANGLE";
export const ADD_TO_REDUX_FROM_DB = "ADD_TO_REDUX_FROM_DB";
export const DELETE_BOOL = "DELETE_BOOL";
export const INDEX_TO_DELETE= "INDEX_TO_DELETE";
export const UNDO_MODULES = "UNDO";