import {GET_POST_HTA_REQUEST, GET_POST_HTA_SUCCESS,GET_POST_HTA_FAIL, DELETE_POST_HTA, EMPTY_STORE} from "../_types"

const initialState = {
    request: undefined,
    data: undefined,
    fail: undefined,
}



const posteHtaReducer = (state = initialState , action) => {

    switch (action.type) {
        case GET_POST_HTA_REQUEST:
            return {
                ...state,
                request: true
            }
        
        case GET_POST_HTA_SUCCESS:
            return {
                ...state,
                data: state.request ? action.payload : undefined
            }
        case GET_POST_HTA_FAIL:
            return {
                ...state,
                fail: action.payload
            }
        case DELETE_POST_HTA:
            return {
                request: undefined,
                data: undefined,
                fail: undefined,
            }
        case EMPTY_STORE: 
            return initialState;
        default :
            return state;
    }
}

export default posteHtaReducer;