import React from 'react';
import {LoadScript} from '@react-google-maps/api';
import MyGoogleMap from './MyGoogleMap';

const LIBRARIES = ["drawing", "places", "geometry"];

const MapProject = () => {
    return (
        <LoadScript
            googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY_PROD}
            libraries={LIBRARIES}>
            <MyGoogleMap/>
        </LoadScript>
    );
}

export default MapProject;