import {EMPTY_STORE, ROOF_INSTALLATION_ACTIVE} from "../_types";

const initialState = {
    roofInstallation: undefined,
}

const roofInstallationReducer = (state = initialState , action) => {
    switch (action.type) {
        case ROOF_INSTALLATION_ACTIVE:
            return {
                ...state,
                roofInstallation: action.payload
            }
        case EMPTY_STORE:
            return initialState;
        default :
            return {...state};
    }
}

export default roofInstallationReducer;