import {
    IS_DRAWING,
    CORRECTION_POLYGON,
    DELETE_RECTANGLE,
    DUPLICATE_RECTANGLE,
    EMPTY_STORE,
    FIX_RECTANGLES,
    UNDO_POLYGON,
    UPDATE_RECTANGLE
} from "../_types";

const initialState = {
    draw: undefined,
    rectangles: [],
    undo: []
};

const drawPolygonReducer = (state = initialState, action) => {
    let stateRectangles = [];
    let newRectangles = [];
    let undoState;

    switch (action.type) {
        case IS_DRAWING:
            return {
                ...state,
                draw: action.payload
            };

        case FIX_RECTANGLES:
            stateRectangles = [...state.rectangles];
            newRectangles = stateRectangles.filter(rectangle => rectangle.length !== 0); // FIX POUR CLEAR LE TABLEAU EXISTANT DANS CHACUN DES PROJET DEJA EN ACTIVITE

            undoState = {
                ...state,
                rectangles: structuredClone(newRectangles)
            };

            return {
                ...state,
                rectangles: newRectangles,
                undo: [...state.undo, undoState]
            };

        case CORRECTION_POLYGON:
            return {
                ...state,
                rectangles: [...state.rectangles, action.payload]
            };

        case UPDATE_RECTANGLE:
            stateRectangles = [...state.rectangles];
            stateRectangles.forEach((element, index) => {
                if (index === action.payload.idRectangle) {
                    stateRectangles[index] = action.payload.rectangle;
                }
            });

            undoState = {
                ...state,
                rectangles: structuredClone(stateRectangles)
            };

            return {
                ...state,
                rectangles: stateRectangles,
                undo: [...state.undo, undoState]
            };

        case DELETE_RECTANGLE:
            stateRectangles = [...state.rectangles];
            newRectangles = stateRectangles.filter((rectangle, index) => index !== action.payload);
            console.log(newRectangles);

            undoState = {
                ...state,
                rectangles: structuredClone(newRectangles)
            };

            return {
                ...state,
                rectangles: newRectangles,
                undo: [...state.undo, undoState]
            };

        case DUPLICATE_RECTANGLE:
            stateRectangles = [...state.rectangles];
            if (action.payload >= stateRectangles.length)
                return state;

            const originalCoords = stateRectangles[action.payload];
            const newCoords = originalCoords.map(coord => ({...coord}));

            undoState = {
                ...state,
                rectangles: structuredClone([...state.rectangles, newCoords])
            };

            return {
                ...state,
                rectangles: [...state.rectangles, newCoords],
                undo: [...state.undo, undoState]
            };

        case EMPTY_STORE:
            return initialState;

        case UNDO_POLYGON:
            const undo = state.undo[state.undo.length - 2];
            console.log("UNDO POLYGON", undo, state.undo);

            if (undo) {
                let limitedUndo = state.undo.slice(0, -1);
                if (state.undo.length > 10) {
                    limitedUndo = state.undo.slice(-10, -1); // Limit to the last 10 entries
                }

                return {
                    ...state,
                    draw: undefined,
                    rectangles: structuredClone(undo.rectangles),
                    undo: limitedUndo,
                };
            }

            return { ...state };

        default :
            return state;
    }
}

export default drawPolygonReducer;