import {EMPTY_STORE, LOCAL_INVERTER_ACTIVE} from "../_types";

const initialState = {
    localInverter: undefined,
}

const localInverterReducer = (state = initialState , action) => {
    switch (action.type) {
        case LOCAL_INVERTER_ACTIVE:
            return {
                ...state,
                localInverter: action.payload
            }
        case EMPTY_STORE:
            return initialState;
        default :
            return {...state};
    }
}

export default localInverterReducer;