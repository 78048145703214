import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {energyMeterActive} from "../../_actions";

function EnergyMeterMarker(props){

    const dispatch = useDispatch();

    const energyMeter = useSelector(state => state.energyMeterReducer.energyMeter , shallowEqual)
    const google = window.google;
    const map = props.map;
    let energyMeterMarker;
    let dragEnergyMeterMarkerListener;
    let clickEnergyMeterMarkerListener;
    let EnergyMeterImage = 'https://i.ibb.co/b3PmCP4/Picto-compteur-d-e-nergie.png';


    if(map !== null){
        if(energyMeter){
            energyMeterMarker = new google.maps.Marker({
                position: map.getCenter(),
                icon: {
                    url:EnergyMeterImage,
                },
                map: map
            });
            dragEnergyMeterMarkerListener = google.maps.event.addListener(map, 'mousemove', function (e) {
                let lat;
                const zoom = map.getZoom();
                if(zoom > 20){
                    lat = e.latLng.lat() - 0.0001 / map.getZoom()
                }else if(zoom == 20){
                    lat = e.latLng.lat() - 0.0005 / map.getZoom()
                }else if (zoom > 15 && zoom < 20){
                    lat = e.latLng.lat() - 0.001 / map.getZoom()
                }else if (zoom > 10 && zoom <= 15){
                    lat = e.latLng.lat() - 0.01 / map.getZoom()
                }
                else{
                    lat = e.latLng.lat() - 0.1 / map.getZoom();
                }
                const lng = e.latLng.lng();
                energyMeterMarker.setPosition({lat:lat,lng:lng});
                //energyMeterMarker.setPosition(e.latLng);
            });
            clickEnergyMeterMarkerListener = google.maps.event.addListener(energyMeterMarker, 'click', function (e) {
                google.maps.event.removeListener(dragEnergyMeterMarkerListener);
            });
            google.maps.event.addListener(energyMeterMarker, 'dblclick', function (e) {
                energyMeterMarker.setMap(null);
            });
            dispatch(energyMeterActive(false));
        }
        else{
            google.maps.event.removeListener(clickEnergyMeterMarkerListener);
        }
    }

    return(
        <div>
            {energyMeter}
        </div>
    );
}

export default React.memo(EnergyMeterMarker);