import {
    GET_GEOPORTAIL0005_REQUEST,
    GET_GEOPORTAIL0005_SUCESS,
    GET_GEOPORTAIL0005_FAIL,
    DELETE_GEOPORTAIL0005,
    EMPTY_STORE
} from "../_types"

const initialState = {
    request: undefined,
    geoportail0005: undefined,
    data: undefined,
    fail: undefined,
}

const geoportail0005ApiReducer = (state = initialState , action) => {

    switch (action.type) {
        case GET_GEOPORTAIL0005_REQUEST:
            return {
                ...state,
                request: true
            }

        case GET_GEOPORTAIL0005_SUCESS:
            return {
                ...state,
                geoportail0005: action.payload
            }
        case GET_GEOPORTAIL0005_FAIL:
            return {
                ...state,
                fail:  action.payload
            }
        case DELETE_GEOPORTAIL0005:
            return {
                request: false,
                data: undefined,
                fail: undefined,
            }

        case EMPTY_STORE:
            return initialState;
        default :
            return state;
    }
}

export default geoportail0005ApiReducer;