import {
    GET_POST_HTA_REQUEST,
    GET_POST_HTA_SUCCESS,
    GET_POST_HTA_FAIL,
    DELETE_POST_HTA,
    GET_CABLE_HTA_AERIAL_REQUEST,
    GET_CABLE_HTA_AERIAL_SUCCESS,
    DELETE_CABLE_AERIAL_HTA,
    DELETE_CABLE_UNDERGROUND_HTA,
    GET_CABLE_HTA_AERIAL_FAIL,
    GET_CABLE_HTA_UNDERGROUND_FAIL,
    GET_CABLE_HTA_UNDERGROUND_REQUEST,
    GET_CABLE_HTA_UNDERGROUND_SUCCESS,
    GET_CABLE_BT_UNDERGROUND_REQUEST,
    GET_CABLE_BT_UNDERGROUND_SUCCESS,
    GET_CABLE_BT_UNDERGROUND_FAIL,
    DELETE_CABLE_UNDERGROUND_BT,
    GET_CABLE_BT_AERIAL_REQUEST,
    GET_CABLE_BT_AERIAL_SUCCESS,
    GET_CABLE_BT_AERIAL_FAIL,
    DELETE_CABLE_AERIAL_BT,
    GET_CADASTRE_REQUEST,
    GET_CADASTRE_SUCESS,
    GET_CADASTRE_FAIL,
    DELETE_CADASTRE,
    DELETE_GEOPORTAIL,
    GET_GEOPORTAIL_SUCESS,
    GET_GEOPORTAIL5065_SUCESS,
    DELETE_GEOPORTAIL5065,
    DELETE_GEOPORTAIL0005,
    GET_GEOPORTAIL0005_SUCESS,
    GET_GEOPORTAIL0610_SUCESS,
    DELETE_GEOPORTAIL0610,
    GET_GEOPORTAIL1115_SUCESS,
    DELETE_GEOPORTAIL1115,
    IS_WIND_ACTIVE,
    DELETE_WIND,
    IS_SNOW_ACTIVE, DELETE_SNOW, DELETE_EARTHQUAKE, IS_EARTHQUAKE_ACTIVE
} from "../_types"
import {apicarto, enedis} from "../config/api"

function coordinates(response){
    const coordinates = []
    response.records.forEach( record => {
        coordinates.push({lat : record.geometry.coordinates[1], lng: record.geometry.coordinates[0]})
    })
    return coordinates;
}

function responseApiToInputMapEnedis(apiResponse){
    if (apiResponse.nhits > apiResponse.parameters.start-1){
        var listCoord = []
        var result = []
        apiResponse.records.forEach((record) => {
            record.fields.geo_shape.coordinates.forEach(coordinate => {
                listCoord.push({lat : coordinate[1], lng: coordinate[0]})
            })
            result.push(listCoord)
            listCoord = [];
        })
        return result ;
    }
}

export const getPostHta = (parameter) => (dispatch) => {
    try {
        dispatch({type: GET_POST_HTA_REQUEST})
        enedis.get(`/api/records/1.0/search/?dataset=poste-electrique&q=&rows=30&geofilter.polygon=${parameter.point1.replace(/,/g , '%2C')}%2C${parameter.point2.replace(/,/g , '%2C')}%2C${parameter.point3.replace(/,/g , '%2C')}%2C${parameter.point4.replace(/,/g , '%2C')}`)
            .then((data) => {
                return dispatch({type: GET_POST_HTA_SUCCESS, payload : coordinates(JSON.parse(data.request.response))})
            })
            .catch((err) => dispatch({type: GET_POST_HTA_FAIL, payload: err}))
    } catch (error) {
        
    }
  
}

export const deletePostHta = () => {
    return {
        type: DELETE_POST_HTA
    }
}

export const getCableHtaUnderground = (parameter) => (dispatch) => {
    try {
        dispatch({type: GET_CABLE_HTA_UNDERGROUND_REQUEST})
        enedis.get(`/api/records/1.0/search/?dataset=reseau-souterrain-hta&q=&rows=1000&geofilter.polygon=${parameter.point1.replace(/,/g , '%2C')}%2C${parameter.point2.replace(/,/g , '%2C')}%2C${parameter.point3.replace(/,/g , '%2C')}%2C${parameter.point4.replace(/,/g , '%2C')}`)
            .then((data) => {
                return dispatch({type: GET_CABLE_HTA_UNDERGROUND_SUCCESS, payload : responseApiToInputMapEnedis(JSON.parse(data.request.response))})
            })
            .catch((err) => dispatch({type: GET_CABLE_HTA_UNDERGROUND_FAIL, payload: err}))
    } catch (error) {
        
    }

}

export const deleteCableHtaUnderground = () => {
    return {
        type: DELETE_CABLE_UNDERGROUND_HTA
    }
}

export const getCableHtaAerial= (parameter) => (dispatch) => {
    try {
        dispatch({type: GET_CABLE_HTA_AERIAL_REQUEST})
        enedis.get(`/api/records/1.0/search/?dataset=reseau-hta&q=&rows=1000&geofilter.polygon=${parameter.point1.replace(/,/g , '%2C')}%2C${parameter.point2.replace(/,/g , '%2C')}%2C${parameter.point3.replace(/,/g , '%2C')}%2C${parameter.point4.replace(/,/g , '%2C')}`)
            .then((data) => {
                return dispatch({type: GET_CABLE_HTA_AERIAL_SUCCESS, payload : responseApiToInputMapEnedis(JSON.parse(data.request.response))})
            })
            .catch((err) => dispatch({type: GET_CABLE_HTA_AERIAL_FAIL, payload: err}))
        
    } catch (error) {
        
    }

}

export const deleteCableHtaAerial = () => {
    return {
        type: DELETE_CABLE_AERIAL_HTA
    }
}

export const getCableBtUnderground = (parameter) => (dispatch) => {
    try {
        dispatch({type: GET_CABLE_BT_UNDERGROUND_REQUEST})
        enedis.get(`/api/records/1.0/search/?dataset=reseau-souterrain-bt&q=&rows=1000&geofilter.polygon=${parameter.point1.replace(/,/g , '%2C')}%2C${parameter.point2.replace(/,/g , '%2C')}%2C${parameter.point3.replace(/,/g , '%2C')}%2C${parameter.point4.replace(/,/g , '%2C')}`)
            .then((data) => {
                return dispatch({type: GET_CABLE_BT_UNDERGROUND_SUCCESS, payload : responseApiToInputMapEnedis(JSON.parse(data.request.response))})
            })
            .catch((err) => dispatch({type: GET_CABLE_BT_UNDERGROUND_FAIL, payload: err}))
    } catch (error) {
        
    }

}

export const deleteCableBtUnderground = () => {
    return {
        type: DELETE_CABLE_UNDERGROUND_BT
    }
}

export const getCableBtAerial= (parameter) => (dispatch) => {
    try {
        dispatch({type: GET_CABLE_BT_AERIAL_REQUEST})
        enedis.get(`/api/records/1.0/search/?dataset=reseau-bt&q=&rows=1000&geofilter.polygon=${parameter.point1.replace(/,/g , '%2C')}%2C${parameter.point2.replace(/,/g , '%2C')}%2C${parameter.point3.replace(/,/g , '%2C')}%2C${parameter.point4.replace(/,/g , '%2C')}`)
            .then((data) => {
                return dispatch({type: GET_CABLE_BT_AERIAL_SUCCESS, payload : responseApiToInputMapEnedis(JSON.parse(data.request.response))})
            })
            .catch((err) => dispatch({type: GET_CABLE_BT_AERIAL_FAIL, payload: err}))
    } catch (error) {
        
    }

}

export const deleteCableBtAerial = () => {
    return {
        type: DELETE_CABLE_AERIAL_BT
    }
}

export const getTotalCadastreInfo= (parameter) => (dispatch) => {
    try {
        dispatch({type: GET_CADASTRE_REQUEST})
        //Pour PROD
        //apicarto.get(`/api/cadastre/parcelle?apikey=0skh99buqiuj9k125e4d5q9h&geom=${JSON.stringify(parameter)}`)

        //Pour DEV
        apicarto.get(`/api/cadastre/parcelle?apikey=&geom=${JSON.stringify(parameter)}`)
            .then((data) => {
                return dispatch({type: GET_CADASTRE_SUCESS, payload : data.data})
            })
            .catch((err) => dispatch({type: GET_CADASTRE_FAIL, payload: err}))
    } catch (error) {

    }
}

export const deleteCadastre = () => {
    return {
        type: DELETE_CADASTRE
    }
}


export const getGeoportail = (geoportail) => {
    return {
        type : GET_GEOPORTAIL_SUCESS ,
        payload: geoportail,
    }
}

export const deleteGeoportail = () => {
    return{
        type: DELETE_GEOPORTAIL
    }
}

export const getGeoportail5065 = (geoportail5065) => {
    return {
        type : GET_GEOPORTAIL5065_SUCESS ,
        payload: geoportail5065,
    }
}

export const deleteGeoportail5065 = () => {
    return{
        type: DELETE_GEOPORTAIL5065
    }
}

export const getGeoportail0005 = (geoportail0005) => {
    return {
        type : GET_GEOPORTAIL0005_SUCESS ,
        payload: geoportail0005,
    }
}

export const deleteGeoportail0005 = () => {
    return{
        type: DELETE_GEOPORTAIL0005
    }
}

export const getGeoportail0610 = (geoportail0610) => {
    return {
        type : GET_GEOPORTAIL0610_SUCESS ,
        payload: geoportail0610,
    }
}

export const deleteGeoportail0610 = () => {
    return{
        type: DELETE_GEOPORTAIL0610
    }
}

export const getGeoportail1115 = (geoportail1115) => {
    return {
        type : GET_GEOPORTAIL1115_SUCESS ,
        payload: geoportail1115,
    }
}

export const deleteGeoportail1115 = () => {
    return{
        type: DELETE_GEOPORTAIL1115
    }
}

export const setIsWindActive = (wind) => {
    return {
        type : IS_WIND_ACTIVE ,
        payload: wind,
    };
}

export const deleteWind = () => {
    return{
        type: DELETE_WIND
    }
}

export const setIsSnowActive = (snow) => {
    return {
        type : IS_SNOW_ACTIVE ,
        payload: snow,
    };
}

export const deleteSnow = () => {
    return{
        type: DELETE_SNOW
    }
}

export const setIsEarthquakeActive = (earthquake) => {
    return {
        type : IS_EARTHQUAKE_ACTIVE ,
        payload: earthquake,
    };
}

export const deleteEarthquake = () => {
    return{
        type: DELETE_EARTHQUAKE
    }
}