import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import MeasureTool from "measuretool-googlemaps-v3";
import "../../assets/styles/Ruler.css"
import StraightenIcon from "@material-ui/icons/Straighten";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FilterHdrIcon from "@material-ui/icons/FilterHdr";
import MenuIcon from '@material-ui/icons/Menu';
import "../../assets/styles/ContextMenu.css";

function displayLocationElevation(location, elevator) {
    elevator.getElevationForLocations(
        {
            locations: [location],
        },
        (results, status) => {
            if (status === "OK" && results) {
                if (results[0]) {
                    document.getElementById("altitude").innerText = results[0].elevation.toFixed(2) + " m"
                } else {
                    document.getElementById("altitude").innerText = "No results found"
                }
            } else {
                document.getElementById("altitude").innerText = "Elevation service failed due to: " + status
            }
        }
    ).then(r => "ok");
}

const MeasureRuler = (props) => {
    const [isMeasuring, setIsMeasuring] = useState(false);
    const [isElevation, setIsElevation] = useState(false);
    const [contextMenuInfo, setContextMenuInfo] = useState({isShow: false, x: '', y: ''});

    const measure = useSelector(state => state.drawRulerReducer.measure);

    const showElevationListener = useRef();

    const map = props.map;
    const google = window.google;
    const elevator = new google.maps.ElevationService();

    let measureTool = useRef(null);

    useEffect(() => {
        if (map !== null) {
            google.maps.event.addListener(map, 'contextmenu', function (e) {
                setContextMenuInfo({isShow: true, x: `${e.pixel.x}px`, y: `${e.pixel.y}px`});
            });

            measureTool.current = new MeasureTool(map, {
                showSegmentLength: true,
                unit: MeasureTool.UnitTypeId.METRIC,
                language: "fr",
                contextMenu: false,
                invertColor: true,
            });
            console.log(measureTool.current)
        }
    }, [map]);

    console.log("measure tool : " + measureTool.current)
    console.log(contextMenuInfo)

    useEffect(() => {
        if (map !== null && isElevation) {
            showElevationListener.current = google.maps.event.addListener(map, 'click', function (e) {
                displayLocationElevation(e.latLng, elevator);
            });
        } else {
            google.maps.event.removeListener(showElevationListener.current);
            document.getElementById("altitude").innerText = " ";
        }
    }, [isElevation, map]);

    function measureHandler() {
        setIsMeasuring(prevState => {
            !prevState ? measureTool.current.start() : measureTool.current.end();
            console.log("mesure" + !prevState)
            return !prevState;
        });
    }

    function showElevationHandler() {
        setIsElevation(prevState => !prevState);
    }

    function closeMenuHandler() {
        setContextMenuInfo({isShow: false, x: '', y: ''});
    }

    return (
        <>
            {contextMenuInfo.isShow &&
                <div>
                    {measure}
                    <div style={{
                        top: contextMenuInfo.y,
                        left: contextMenuInfo.x,
                        boxSizing: `border-box`,
                        width: `300px`,
                        fontSize: `16px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                        position: "absolute",
                        backgroundColor: "#fff",
                    }}>
                        <ul>
                            {isMeasuring ?
                                <li className="menu-item" onClick={measureHandler}>
                                    <HighlightOffIcon fontSize={'small'} style={{paddingTop: "5px"}}/>
                                    Arrêter la mesure
                                </li>
                                :
                                <li className="menu-item" onClick={measureHandler}>
                                    <StraightenIcon fontSize={'small'} style={{paddingTop: "5px"}}/>
                                    Mesurer une distance
                                </li>}

                            {isElevation ?
                                <li className="menu-item" onClick={showElevationHandler}>
                                    <HighlightOffIcon fontSize={'small'} style={{paddingTop: "5px"}}/>
                                    Arrêter l'affichage d'altitude
                                </li>
                                :
                                <li className="menu-item" onClick={showElevationHandler}>
                                    <FilterHdrIcon fontSize={'small'} style={{paddingTop: "5px"}}/>
                                    Afficher l'altitude
                                </li>}

                            <li className="close-menu-item" onClick={closeMenuHandler}>
                                <MenuIcon fontSize={'small'} style={{paddingTop: "5px"}}/>
                                Fermer le menu
                            </li>
                        </ul>
                    </div>
                </div>
            }
        </>
    );
}

export default React.memo(MeasureRuler);