import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import {useState} from "react";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const UpdatePopup = () => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                    Mise à jour importante
                    <RocketLaunchIcon fontSize={"large"} sx={{
                        position: 'absolute',
                        right: 15
                    }}/>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Rapport de mise à jour :
                    </Typography>
                    <Typography gutterBottom>
                        - Possibilité de revenir jusqu'a 10 actions (ctrl + z).<br/>
                        - Ajout de hangars standardisés pour le calepinage.<br/>
                        - Mise à jour du calcul du productible.<br/>
                        - Repeuplement manuel des zones.<br/>
                        - Multiples améliorations de performance et d'accessibilité.<br/>
                    </Typography>
                    <Typography gutterBottom>
                        <br/>Attention :<br/>
                        Certains projets trop anciens peuvent rencontrer des problèmes de compatibilité.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Accepter
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default UpdatePopup;