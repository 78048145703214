import {Autocomplete, Marker} from '@react-google-maps/api'
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {registerMapInformation} from "../../_actions"
import {useGeoCode} from '../../hooks';
import {saveAddress} from '../../_actions';
import uuid from 'react-uuid'
import '../../assets/styles/autocomplete.css';

const initialMarkerKey = uuid();

function displayLocationElevation(location, elevator) {
    elevator.getElevationForLocations(
        {
            locations: [location],
        },
        (results, status) => {
            if (status === "OK" && results) {
                if (results[0]) {
                    console.log("HELLLOOOOOOOO");
                    document.getElementById("altitude").innerText = results[0].elevation.toFixed(2) + " m";
                } else {
                    document.getElementById("altitude").innerText = "No results found";
                }
            } else {
                document.getElementById("altitude").innerText = "Elevation service failed due to: " + status;
            }
        }
    );
}

function SearchAutocomplete() {

    const addressName = useSelector(state => state.headerProjectReducer?.addressProject?.completeAddress);

    //personal Hooks
    const [location, setLocation] = useGeoCode('');
    //Local state
    const [address, setAddress] = useState();

    const dispatch = useDispatch();

    const google = window.google;
    const elevator = new google.maps.ElevationService();

    const onLoadAuto = autocomplete => {
        console.log('autocomplete: ', autocomplete);
        setAddress(autocomplete);
    };

    const onPlaceChangedAuto = () => {
        if (address != null) {
            let place = address.getPlace();
            if (Object.keys(place).length > 1) {
                setLocation(address.getPlace().formatted_address);
                let latlng = address.getPlace().geometry.location;
                displayLocationElevation(latlng, elevator);
            } else {
                let coordinates = place.name.split(',');
                if (coordinates.length === 2) {
                    let latlng = {lat: parseFloat(coordinates[0]), lng: parseFloat(coordinates[1])};
                    console.log(latlng)
                    setLocation(latlng);
                    displayLocationElevation(latlng, elevator);
                }
            }

        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    useEffect(() => {
        if (location.completeAddress !== "") {
            console.log(location.completeAddress, location.completeAddress === 'France')
            dispatch(saveAddress(location));
            dispatch(registerMapInformation({
                center: location.location,
                zoom: location.completeAddress === 'France' ? 6 : 18 + Math.random(),
                geoJson: {type: "Polygon", coordinates: []},
                geoFilterPolygon: ""
            }));
        }
    }, [location]);

    console.log("Location : ");
    console.log(location.location);

    return (
        <>
            {
                <Autocomplete
                    className={"autocomplete.pac-container"}
                    onLoad={onLoadAuto}
                    onPlaceChanged={onPlaceChangedAuto}
                    options={
                        {
                            componentRestrictions: {country: "fr"}
                        }
                    }
                >
                    <input
                        id="auto-complete"
                        type="text"
                        placeholder="Rechercher l'adresse"
                        defaultValue={addressName && addressName}
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `350px`,
                            height: `32px`,
                            marginTop: '10px',
                            padding: `0 20px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: "absolute",
                            left: "46%",
                            marginLeft: "-120px"
                        }}
                    />
                </Autocomplete>
            }
            {Object.keys(location.location).length > 0 &&
                <Marker draggable={true} key={initialMarkerKey} position={location.location}/>
            }
        </>
    );
}

export default React.memo(SearchAutocomplete);