import {Grid, makeStyles, Tooltip, Typography} from '@material-ui/core'
import React from 'react'
import {Copyright} from "../components";
import AltitudeIcon from "../assets/icons/Picto-altitude-.png";
import NeigeIcon from "../assets/icons/Picto-neige-02.png";
import VentIcon from "../assets/icons/Picto-vent.png";
import SismiqueIcon from "../assets/icons/Picto-sismique.png";
import DownloadIcon from "../assets/icons/Picto-telechargement.png";
import CadastreIcon from "../assets/icons/Picto-cadastre.png";
import ClearIcon from "../assets/icons/Picto-poubelle.png";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {LinkedIn} from "@material-ui/icons";


function Footer() {

    const classes = useStyles();

    /* API météo down - attente de retour
    const dispatch = useDispatch();

    const [wind, setWind] = useState(false);
    const [snow, setSnow] = useState(false);
    const [earthquake, setEarthquake] = useState(false);

        const onClickWind = () => {
            setWind(!wind);
            setSnow(false);
            setEarthquake(false);
            dispatch(setIsWindActive(!wind));
        };

        const onClickSnow = () => {
            setSnow(!snow);
            setWind(false);
            setEarthquake(false);
            dispatch(setIsSnowActive(!snow));
        };

        const onClickSismique = () => {
            setEarthquake(!earthquake);
            setSnow(false);
            setWind(false);
            dispatch(setIsEarthquakeActive(!earthquake));
        };

    const onClickClear = () => {

                setEarthquake(false);
                setSnow(false);
                setWind(false);
                dispatch(setIsEarthquakeActive(false));
                dispatch(setIsSnowActive(false));
                dispatch(setIsWindActive(false));
                dispatch(changeMapKey());
    };
    */

    return (
        <Box sx={{flexGrow: 1}} className={classes.footer}>
            <Grid container spacing={1} justifyContent="center" alignItems="center">
                <div id="cadastre-info">
                </div>

                <Grid container xs={4} justifyContent="center" alignItems="center">
                    <Tooltip title="Parcelle" placement={"top"}>
                        <img src={CadastreIcon} width="50" height="50" alt="parcelle"/>
                    </Tooltip>

                    <span className={classes.typography}>
                        Parcelle:
                            <span id="parcelNumber"> </span>
                        </span>
                    <span className={classes.typography}>
                        Section:
                            <span id="section"> </span>
                        </span>
                    <span className={classes.typography}>
                        Feuille:
                            <span id="feuille"> </span>
                        </span>

                    <Tooltip title="Télécharger le DXF" placement={"top"}>
                        <Button id="dxf" target="_blank" href="#" className={classes.dxfButton}/>
                    </Tooltip>
                </Grid>

                <Grid container xs={3} justifyContent="center" alignItems="center">
                    <Tooltip title="Altitude" placement={"top"}>
                        <img src={AltitudeIcon} width="50" height="50" alt="Altitude"/>
                    </Tooltip>

                    <span id='altitude' className={classes.typography}> </span>
                </Grid>

                <Grid container spacing={1} xs={4} justifyContent="center" alignItems="center">
                    <Grid item xs={6}><Copyright/></Grid>

                    <Grid item xs={3}>
                        <Typography align="center">
                            <a href="mailto:contact@daisun.solar" style={{color: "#FF9300"}}>Contact</a>
                        </Typography>
                    </Grid>

                    <LinkedIn style={{color: "#FF9300"}} fontSize="medium"/>

                </Grid>
            </Grid>
        </Box>
    )
}

/* API météo down - attente de retour
<Grid item>
                    <Tooltip title="Neige" placement={"top"}>
                        <Button id="neige-button" className={classes.snowButton} onClick={onClickSnow}/>
                    </Tooltip>
                </Grid>

                <Grid item >
                    <Tooltip title="Vent" placement={"top"}>
                        <Button id="vent-button" className={classes.windButton} onClick={onClickWind}/>
                    </Tooltip>
                </Grid>

                <Grid item xs={1}>
                    <Tooltip title="Sismicité" placement={"top"}>
                        <Button id="sismique-button" className={classes.earthQuakeButton} onClick={onClickSismique}/>
                    </Tooltip>
                </Grid>
                 <Tooltip title="Supprimer" placement={"top"}>
                        <Button className={classes.clearButton} onClick={onClickClear}/>
                 </Tooltip>

*/

export default Footer

const useStyles = makeStyles((theme) => ({
    typography: {
        fontFamily: 'neutra-alt', fontSize: 15, paddingLeft: 10,
    },
    footer: {
        position: 'absolute', backgroundColor: '#FFF', width: '100%', bottom: 0, height: 60, paddingTop: 5,
    },
    icon: {color: '#f1f1f1'},
    iconActive: {color: '#ff8a65'},
    dxfButton: {
        backgroundImage: 'url(' + DownloadIcon + ')',
        backgroundColor: "white",
        backgroundPosition: 'center',
        backgroundSize: 50,
        height: 50,
        backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    windButton: {
        backgroundImage: 'url(' + VentIcon + ')',
        backgroundColor: "white",
        backgroundPosition: 'center',
        backgroundSize: 50,
        height: 50,
        backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    snowButton: {
        backgroundImage: 'url(' + NeigeIcon + ')',
        backgroundColor: "white",
        backgroundPosition: 'center',
        backgroundSize: 50,
        height: 50,
        backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    earthQuakeButton: {
        backgroundImage: 'url(' + SismiqueIcon + ')',
        backgroundColor: "white",
        backgroundPosition: 'center',
        backgroundSize: 50,
        height: 50,
        backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
    clearButton: {
        backgroundImage: 'url(' + ClearIcon + ')',
        backgroundColor: "white",
        backgroundPosition: 'center',
        backgroundSize: 50,
        height: 50,
        backgroundRepeat: 'no-repeat',
        '&:hover': {
            border: "1px solid #000",
        },
    },
}));