import { Polyline } from '@react-google-maps/api'
import React from 'react'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'


function PolylineCableHta({onClickPolyline}) {
    
    const cableHtaUnderground =  useSelector(state => state.cableHtaBtReducer.dataUndergroundHta)    
    const cableHtaAerial =  useSelector(state => state.cableHtaBtReducer.dataAerialdHta)


    return (
        <div>
        {cableHtaUnderground && cableHtaUnderground.map((coordinateLine) => {
            console.log("render polyline Hta")
            return (
              <Polyline
                key={uuid()}
                path={coordinateLine}
                options={{strokeColor: '#FF0000',  strokeWeight: 5}}
                onClick={(point)=>{onClickPolyline(point , 'enedisLineUndergroundHTA')}}
            />)
        })}
        {cableHtaAerial && cableHtaAerial.map((coordinateLine) => {
            console.log("render polyline Hta")
            return (
              <Polyline
                key={uuid()}
                path={coordinateLine}
                options={{strokeColor: '#08FF00',  strokeWeight: 5}}
                onClick={(point)=>onClickPolyline(point , 'enedisLineAerialHTA')}
            />)
        })}
        </div>
    )

}

export default React.memo(PolylineCableHta)
