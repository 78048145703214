import React from 'react';
import {shallowEqual, useSelector} from "react-redux";

function Geoportail0610(props){

    const geoportail0610 = useSelector(state => state.geoportail0610ApiReducer.geoportail0610 , shallowEqual)

    const google = window.google;
    const map = props.map;
    let geoportail0610Data;


    function geoportail0610Map(map) {
        if(map != null){
            if(geoportail0610){
                geoportail0610Data = new google.maps.ImageMapType({
                    maxZoom: 19,
                    minZoom: 0,
                    name: "Geoportail0610",
                    tileSize: new google.maps.Size(256, 256),
                    getTileUrl: function (coordinates, zoom) {
                        // Pour DEV
                        //return 'https://wxs.ign.fr/an7nvfzojv5wa96dsga5nk8w/geoportail/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&&LAYER=ORTHOIMAGERY.ORTHOPHOTOS2006-2010&TILEMATRIXSET=PM&TILEMATRIX=' + zoom + '&TILECOL=' + coordinates.x + '&TILEROW=' + coordinates.y +'&STYLE=normal&FORMAT=image/jpeg';

                        // Pour PROD
                        return 'https://wxs.ign.fr/0skh99buqiuj9k125e4d5q9h/geoportail/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&&LAYER=ORTHOIMAGERY.ORTHOPHOTOS2006-2010&TILEMATRIXSET=PM&TILEMATRIX=' + zoom + '&TILECOL=' + coordinates.x + '&TILEROW=' + coordinates.y +'&STYLE=normal&FORMAT=image/jpeg';
                    }
                });
                map.overlayMapTypes.insertAt(0, geoportail0610Data);
            }else{
                map.overlayMapTypes.forEach(function (object, index) {
                    map.overlayMapTypes.removeAt(index, geoportail0610Data);
                });
            }
        }
    }


    return(
        <div>
            {geoportail0610Map(map)}
        </div>
    )
}
export default React.memo(Geoportail0610);