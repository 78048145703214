import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';

const MapHome = ({listData}) => {  
  
  const defaultCenter = {
    lat: 46.232192999999995, lng: 2.209666999999996
  }

  return (
     <LoadScript
       googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY_PROD}>
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={6}
          center={defaultCenter}
          options={{ scrollwheel: false, streetViewControl: false, zoomControl :false, mapTypeControl: false, disableDoubleClickZoom: true}}>
        {listData?.map((data) =>
            <Marker  key={data.id} position={data?.address?.location}/>
        )}
        </GoogleMap>
     </LoadScript>
  );
}

const mapStyles = {        
  height: "40em",
  width: "100%"
};

export default MapHome;