import {IS_DRAWING, CORRECTION_POLYGON, UPDATE_RECTANGLE, UNDO_POLYGON, FIX_RECTANGLES} from "../_types";

export const setFixRetangles = () => {
    return {
        type: FIX_RECTANGLES
    };
}

export const setIsDrawing = (draw) => {
    return {
        type : IS_DRAWING ,
        payload: draw,
    };
}

export const sendRectanglesToStore = (rectangles) => {
    return {
        type : CORRECTION_POLYGON ,
        payload: rectangles,
    };
}

export const updateRectangle = (id , rectangle) => {
    return {
        type : UPDATE_RECTANGLE,
        payload : {idRectangle : id, rectangle : rectangle}
    };
}

export const undoPlygon = () => {
    return {
        type: UNDO_POLYGON
    };
}