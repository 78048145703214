import {EMPTY_STORE, IS_DRAWING_TRENCH} from "../_types";

const initialState = {
    trench: undefined,
}

const drawTrenchReducer = (state = initialState , action) => {
    switch (action.type) {
        case IS_DRAWING_TRENCH:
            return {
                ...state,
                trench: action.payload
            }
        case EMPTY_STORE:
            return initialState;
        default :
            return {...state};
    }
}

export default drawTrenchReducer;