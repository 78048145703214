import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {solarPanelActive} from "../../_actions";

function SolarPanelMarker(props){

    const dispatch = useDispatch();

    const solarPanel = useSelector(state => state.solarPanelReducer.solarPanel , shallowEqual)
    const google = window.google;
    const map = props.map;
    let solarPanelMarker;
    let dragSolarPanelMarkerListener;
    let clickSolarPanelMarkerListener;
    let SolarPanelImage = 'https://i.ibb.co/7vypGnb/Picto-panneau-solaire.png';

    if(map !== null){
        if(solarPanel){
            solarPanelMarker = new google.maps.Marker({
                position: map.getCenter(),
                icon: {
                    url:SolarPanelImage,
                },
                map: map
            });
            dragSolarPanelMarkerListener = google.maps.event.addListener(map, 'mousemove', function (e) {
                let lat;
                const zoom = map.getZoom();
                if(zoom > 20){
                    lat = e.latLng.lat() - 0.0001 / map.getZoom()
                }else if(zoom === 20){
                    lat = e.latLng.lat() - 0.0005 / map.getZoom()
                }else if (zoom > 15 && zoom < 20){
                    lat = e.latLng.lat() - 0.001 / map.getZoom()
                }else if (zoom > 10 && zoom <= 15){
                    lat = e.latLng.lat() - 0.01 / map.getZoom()
                }
                else{
                    lat = e.latLng.lat() - 0.1 / map.getZoom();
                }
                const lng = e.latLng.lng();
                solarPanelMarker.setPosition({lat:lat,lng:lng});
                //solarPanelMarker.setPosition(e.latLng);
            });
            clickSolarPanelMarkerListener = google.maps.event.addListener(solarPanelMarker, 'click', function (e) {
                google.maps.event.removeListener(dragSolarPanelMarkerListener);
            });
            google.maps.event.addListener(solarPanelMarker, 'dblclick', function (e) {
                solarPanelMarker.setMap(null);
            });
            dispatch(solarPanelActive(false));
        }
        else{
            google.maps.event.removeListener(clickSolarPanelMarkerListener);
        }
    }

    return(
        <div>
            {solarPanel}
        </div>
    );
}

export default React.memo(SolarPanelMarker);