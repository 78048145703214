
import { DELETE_CABLE_AERIAL_BT, DELETE_CABLE_AERIAL_HTA, DELETE_CABLE_UNDERGROUND_BT, DELETE_CABLE_UNDERGROUND_HTA, EMPTY_STORE, GET_CABLE_BT_AERIAL_FAIL, GET_CABLE_BT_AERIAL_REQUEST, GET_CABLE_BT_AERIAL_SUCCESS, GET_CABLE_BT_UNDERGROUND_FAIL, GET_CABLE_BT_UNDERGROUND_REQUEST, GET_CABLE_BT_UNDERGROUND_SUCCESS, GET_CABLE_HTA_AERIAL_FAIL, GET_CABLE_HTA_AERIAL_REQUEST, GET_CABLE_HTA_AERIAL_SUCCESS, GET_CABLE_HTA_UNDERGROUND_FAIL, GET_CABLE_HTA_UNDERGROUND_REQUEST, GET_CABLE_HTA_UNDERGROUND_SUCCESS } from "../_types"

const initialState = {
    requestUndergroundHta: undefined,
    dataUndergroundHta: undefined,
    failUndergroundHta: undefined,

    requestAerialdHta: undefined,
    dataAerialdHta: undefined,
    failAerialdHta: undefined,

    requestUndergroundBt: undefined,
    dataUndergroundBt: undefined,
    failUndergroundBt: undefined,

    requestAerialdBt: undefined,
    dataAerialdBt: undefined,
    failAerialdBt: undefined,
}

const cableHtaBtReducer = (state = initialState , action) => {

    switch (action.type) {
        case GET_CABLE_HTA_UNDERGROUND_REQUEST:
            return {
                ...state,
                requestUndergroundHta: true
            }
        
        case GET_CABLE_HTA_UNDERGROUND_SUCCESS:
            return {
                ...state,
                dataUndergroundHta: state.requestUndergroundHta ? action.payload : undefined
            }
        case GET_CABLE_HTA_UNDERGROUND_FAIL:
            return {
                ...state,
                failUndergroundHta: action.payload
            }
        case DELETE_CABLE_UNDERGROUND_HTA:
            return {
                requestUndergroundHta: undefined,
                dataUndergroundHta: undefined,
                failUndergroundHta: undefined,
            }



        case GET_CABLE_HTA_AERIAL_REQUEST:
            return {
                ...state,
                requestAerialdHta: true
            }
        case GET_CABLE_HTA_AERIAL_SUCCESS:
            return {
                ...state,
                dataAerialdHta: state.requestAerialdHta ? action.payload : undefined
            }
        case GET_CABLE_HTA_AERIAL_FAIL:
            return {
                ...state,
                failAerialdHta: action.payload
            }
        case DELETE_CABLE_AERIAL_HTA:
            return {
                requestAerialdHta: undefined,
                dataAerialdHta: undefined,
                failAerialdHta: undefined,
            }


        case GET_CABLE_BT_UNDERGROUND_REQUEST:
            return {
                ...state,
                requestUndergroundBt: true
            }
        
        case GET_CABLE_BT_UNDERGROUND_SUCCESS:
            return {
                ...state,
                dataUndergroundBt: state.requestUndergroundBt ? action.payload : undefined
            }
        case GET_CABLE_BT_UNDERGROUND_FAIL:
            return {
                ...state,
                failUndergroundBt: action.payload
            }
        case DELETE_CABLE_UNDERGROUND_BT:
            return {
                requestUndergroundBt: undefined,
                dataUndergroundBt: undefined,
                failUndergroundBt: undefined,
            }



        case GET_CABLE_BT_AERIAL_REQUEST:
            return {
                ...state,
                requestAerialdBt: true
            }
        case GET_CABLE_BT_AERIAL_SUCCESS:
            return {
                ...state,
                dataAerialdBt: state.requestAerialdBt ? action.payload : undefined
            }
        case GET_CABLE_BT_AERIAL_FAIL:
            return {
                ...state,
                failAerialdBt: action.payload
            }
        case DELETE_CABLE_AERIAL_BT:
            return {
                requestAerialdBt: undefined,
                dataAerialdBt: undefined,
                failAerialdBt: undefined,
            }
        case EMPTY_STORE: 
            return initialState;
        
        default :
            return state;
    }
}

export default cableHtaBtReducer;