import {
    GET_GEOPORTAIL0610_REQUEST,
    GET_GEOPORTAIL0610_SUCESS,
    GET_GEOPORTAIL0610_FAIL,
    DELETE_GEOPORTAIL0610,
    EMPTY_STORE
} from "../_types"

const initialState = {
    request: undefined,
    geoportail0610: undefined,
    data: undefined,
    fail: undefined,
}

const geoportail0610ApiReducer = (state = initialState , action) => {

    switch (action.type) {
        case GET_GEOPORTAIL0610_REQUEST:
            return {
                ...state,
                request: true
            }

        case GET_GEOPORTAIL0610_SUCESS:
            return {
                ...state,
                geoportail0610: action.payload
            }
        case GET_GEOPORTAIL0610_FAIL:
            return {
                ...state,
                fail:  action.payload
            }
        case DELETE_GEOPORTAIL0610:
            return {
                request: false,
                data: undefined,
                fail: undefined,
            }

        case EMPTY_STORE:
            return initialState;
        default :
            return state;
    }
}

export default geoportail0610ApiReducer;