import { combineReducers } from "redux";
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import mapReducer from "./mapReducer"
import drawPolygonReducer from './drawPolygonReducer'
import headerProjectReducer from "./headerProjectReducer"
import modulesReducer from "./modulesReducer"
import posteHtaReducer from "./postHtaReducer"
import cableHtaBtReducer from "./cableHtaBtReducer"
import cadastreApiReducer from "./cadastreApiReducer"
import userReducer from "./userReducer"
import drawRulerReducer from "./drawRulerReducer";
import geoportailApiReducer from "./geoportailApiReducer"
import geoportail5065ApiReducer from "./geoportail5065ApiReducer";
import geoportail0005ApiReducer from "./geoportail0005ApiReducer";
import geoportail0610ApiReducer from "./geoportail0610ApiReducer";
import geoportail1115ApiReducer from "./geoportail1115ApiReducer";
import drawTrenchReducer from "./drawTrenchReducer";
import charginPointReducer from "./charginPointReducer";
import displayScreenReducer from "./displayScreenReducer";
import energyMeterReducer from "./energyMeterReducer";
import localInverterReducer from "./localInverterReducer";
import roofInstallationReducer from "./roofInstallationReducer";
import solarPanelReducer from "./solarPanelReducer";
import writeTextReducer from "./writeTextReducer";
import windReducer from "./windReducer";
import snowReducer from "./snowReducer";
import earthquakeReducer from "./earthquakeReducer";
/**
 * Persist config for chose what reducer should persist
 */
const persistConfig = {
    key: 'user',
    storage,
    blacklist: ['mapReducer', 'drawPolygonReducer', 'drawRulerReducer', 'headerProjectReducer', 'modulesReducer',
        'posteHtaReducer', 'cableHtaBtReducer', 'cadastreApiReducer', 'drawTrenchReducer', 'geoportail1115ApiReducer',
        'geoportailApiReducer', 'geoportail5065ApiReducer', 'geoportail0005ApiReducer', 'geoportail0610ApiReducer',
        'charginPointReducer', 'displayScreenReducer', 'energyMeterReducer', 'localInverterReducer',
        'roofInstallationReducer', 'solarPanelReducer', 'writeTextReducer']
}

const rootReducer = combineReducers({
    mapReducer: mapReducer,
    drawPolygonReducer: drawPolygonReducer,
    headerProjectReducer: headerProjectReducer,
    modulesReducer: modulesReducer,
    posteHtaReducer: posteHtaReducer,
    cableHtaBtReducer: cableHtaBtReducer,
    cadastreApiReducer: cadastreApiReducer,
    geoportailApiReducer: geoportailApiReducer,
    userReducer: userReducer,
    drawRulerReducer: drawRulerReducer,
    geoportail5065ApiReducer: geoportail5065ApiReducer,
    geoportail0005ApiReducer: geoportail0005ApiReducer,
    geoportail0610ApiReducer: geoportail0610ApiReducer,
    geoportail1115ApiReducer: geoportail1115ApiReducer,
    drawTrenchReducer: drawTrenchReducer,
    charginPointReducer: charginPointReducer,
    displayScreenReducer: displayScreenReducer,
    energyMeterReducer: energyMeterReducer,
    localInverterReducer: localInverterReducer,
    roofInstallationReducer: roofInstallationReducer,
    solarPanelReducer: solarPanelReducer,
    writeTextReducer: writeTextReducer,
    windReducer: windReducer,
    snowReducer: snowReducer,
    earthquakeReducer: earthquakeReducer,
});

export default persistReducer(persistConfig, rootReducer)