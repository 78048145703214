import React from 'react';
import { useDispatch, useSelector } from "react-redux"
import {FormControl, InputBase} from '@material-ui/core';
import { saveProjectName } from '../_actions';

export default function ProjectNameInput() {
  
    const reduxNameProject = useSelector(state => state.headerProjectReducer.projectName);

    //Redux
    const dispatch = useDispatch();

    //Functions
    const sendToStore = (value) => {
      dispatch(saveProjectName(value));
    }

    return (
          <div>
            <FormControl style={{margin: 5}}>
              <InputBase
                style={{color: "#000", fontSize: 15, fontFamily: 'neutra-alt, sans-serif'}}
                onChange={(event)=>{sendToStore(event.target.value)}}
                placeholder="Nom du projet..."
                defaultValue={reduxNameProject}
              />
            </FormControl>
          </div>
    );
}

