export const computePoints = (numberOfModule, sizeOfModule, p1, orientation) => {
    const google = window.google;

    let colOfPoints = [];
    colOfPoints.push(p1);
    let rowOfPoints = [];

    // +1 because if not this will not create the points for the last column
    for (let i = 0; i < numberOfModule.col + 1; i++) {
        // after the first iteration it needs a coordinate for work...
        if (colOfPoints.length === 0) {
            // ...so we get the coords of the first element from the previous iteration
            colOfPoints.push(google.maps.geometry.spherical.computeOffset(
                {
                    lat: rowOfPoints[i - 1][0].lat(),
                    lng: rowOfPoints[i - 1][0].lng()
                },
                sizeOfModule.length,
                orientation.first
            ));
        }

        for (let j = 0; j < numberOfModule.row; j++) {
            colOfPoints.push(google.maps.geometry.spherical.computeOffset(
                {
                    lat: colOfPoints[colOfPoints.length - 1].lat(),
                    lng: colOfPoints[colOfPoints.length - 1].lng()
                },
                sizeOfModule.width,
                orientation.second
            ));
        }
        rowOfPoints.push(colOfPoints);
        colOfPoints = [];
    }

    return rowOfPoints;
}

export const constructPolygons = (listOfCoordinates) => {
    let listPolygons = [];
    let polygon = [];
    let i = 0;
    let j = 0;

    listOfCoordinates.forEach(list => {

        if (j % 2 === 0) {
            list.forEach((coord) => {
                polygon.push({lat: coord.lat(), lng: coord.lng()})
                if (i % 2 !== 0) {
                    if (listOfCoordinates[j + 1] !== undefined) {
                        polygon.push({lat: listOfCoordinates[j + 1][i].lat(), lng: listOfCoordinates[j + 1][i].lng()})
                        polygon.push({
                            lat: listOfCoordinates[j + 1][i - 1].lat(),
                            lng: listOfCoordinates[j + 1][i - 1].lng()
                        })
                        listPolygons.push(polygon)
                        polygon = []
                    }
                }
                i = i + 1;
            })
        }

        i = 0
        j = j + 1
    })

    return listPolygons;
}

export const constructPolygonsWith2Areas = (matrixOfCoordinates) => {
    const panelCoords = [];

    // loop through the rows of the matrix
    for (let i = 0; i < matrixOfCoordinates.length - 1; i++) {
        // loop through the columns of the matrix
        for (let j = 0; j < matrixOfCoordinates[i].length - 1; j++) {
            // create an array of the four coordinates for the current panel
            const panel = [
                {
                    lat: matrixOfCoordinates[i][j].lat(),
                    lng: matrixOfCoordinates[i][j].lng()
                },
                {
                    lat: matrixOfCoordinates[i][j + 1].lat(),
                    lng: matrixOfCoordinates[i][j + 1].lng()
                },
                {
                    lat: matrixOfCoordinates[i + 1][j + 1].lat(),
                    lng: matrixOfCoordinates[i + 1][j + 1].lng()
                },
                {
                    lat: matrixOfCoordinates[i + 1][j].lat(),
                    lng: matrixOfCoordinates[i + 1][j].lng()
                }
            ];
            panelCoords.push(panel);
        }
    }
    return panelCoords;
}

export const computeAreaPoint = (startingPoint, lengthMarge, widthMarge, lengthOrientation, widthOrientation) => {
    const google = window.google;

    const point_tmp = google.maps.geometry.spherical.computeOffset(startingPoint, lengthMarge, lengthOrientation);
    return google.maps.geometry.spherical.computeOffset(point_tmp, widthMarge, widthOrientation);
};

export const getMiddleLine = (rectangle, implantationType) => {
    const exceptions = ["Hangar Symétrique 60m/26m", "Hangar Symétrique 51m/26m", "Hangar Asymétrique 60m/26m", "Hangar Asymétrique 51m/26m", "Hangar cantale 60m/28m"];

    if(!exceptions.includes(implantationType))
        return null;

    const google = window.google;

    const p1 = new google.maps.LatLng({
        lat: rectangle[0].lat,
        lng: rectangle[0].lng
    });
    const p2 = new google.maps.LatLng({
        lat: rectangle[1].lat,
        lng: rectangle[1].lng
    });
    const p4 = new google.maps.LatLng({
        lat: rectangle[3].lat,
        lng: rectangle[3].lng
    });

    let divider = 0;

    switch (implantationType){
        case "Hangar Symétrique 60m/26m" :
        case "Hangar Symétrique 51m/26m" :
            divider = 2;
            break;
        case "Hangar Asymétrique 60m/26m" :
        case "Hangar Asymétrique 51m/26m":
            divider = 5;
            break;
        case "Hangar cantale 60m/28m" :
            divider = 3;
            break;
    }
    const p6 = new google.maps.LatLng(google.maps.geometry.spherical.computeOffset(p1,
        google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / divider,
        google.maps.geometry.spherical.computeHeading(p1, p2))
    );
    const p5 = new google.maps.LatLng(google.maps.geometry.spherical.computeOffset(p6,
        google.maps.geometry.spherical.computeDistanceBetween(p1, p4),
        google.maps.geometry.spherical.computeHeading(p1, p4))
    );

    return [
        {lat: p6.lat(), lng: p6.lng()},
        {lat: p5.lat(), lng: p5.lng()},
    ];
}