import {EMPTY_STORE, SOLAR_PANEL_ACTIVE} from "../_types";

const initialState = {
    solarPanel: undefined,
}

const solarPanelReducer = (state = initialState , action) => {
    switch (action.type) {
        case SOLAR_PANEL_ACTIVE:
            return {
                ...state,
                solarPanel: action.payload
            }
        case EMPTY_STORE:
            return initialState;
        default :
            return {...state};
    }
}

export default solarPanelReducer;