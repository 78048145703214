import { Button, Checkbox, FormControlLabel, makeStyles, Paper } from '@material-ui/core'
import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getTotalCadastreInfo} from "../_actions/externalApiAction";


function CardCardApi({cadastre, setCadastre, hidden, setCardCard}) {

    // Faire les actions coté side Bar
    const geoJson =  useSelector(state => state.mapReducer.map?.geoJson)
    const zoom =  useSelector(state => state.mapReducer.map?.zoom)
    const dispatch = useDispatch();

    useEffect(() => {
        if(cadastre && zoom > 17){
            dispatch(getTotalCadastreInfo(geoJson))
        }
    }, [geoJson, cadastre])

    return (
        <Paper elevation={3} style={{display: hidden === true ? 'none' : 'flex', flexDirection: 'column', justifyContent:'center',  width: 200, height: 70, marginLeft: 10, marginTop: 110}}>
            <div style={{paddingLeft: 10}}>
                <FormControlLabel
                   control={ <Checkbox checked={cadastre} id="Cadastre-checked" color="primary" onClick={()=>{setCadastre(!cadastre)} } />}
                    label="Cadastre"
                />
            </div>
        </Paper>
    )
}


export default React.memo(CardCardApi)
