import { Polyline } from '@react-google-maps/api'
import React from 'react'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'

function PolylineCableBt({onClickPolyline}) {

    const cableBtUnderground =  useSelector(state => state.cableHtaBtReducer.dataUndergroundBt)    
    const cableBtAerial =  useSelector(state => state.cableHtaBtReducer.dataAerialdBt)


    return (
        <div>
        {cableBtUnderground && cableBtUnderground.map((coordinateLine) => {
            console.log("render polyline")
            return (
              <Polyline
                key={uuid()}
                path={coordinateLine}
                options={{strokeColor: '#C100FF',  strokeWeight: 5}}
                onClick={(point)=>{onClickPolyline(point , 'enedisLineUndergroundBT')}}
            />)
        })}
        {cableBtAerial && cableBtAerial.map((coordinateLine) => {
                        console.log("render polyline")

            return (
              <Polyline
                key={uuid()}
                path={coordinateLine}
                options={{strokeColor: '#0068FF',  strokeWeight: 5}}
                onClick={(point)=>onClickPolyline(point , 'enedisLineAerialBT')}
            />)
        })}
        </div>
    )
}

export default React.memo(PolylineCableBt)
