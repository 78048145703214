export const GET_CADASTRE_REQUEST = "GET_CADASTRE_REQUEST" ;
export const GET_CADASTRE_SUCESS = "GET_CADASTRE_SUCESS" ;
export const GET_CADASTRE_FAIL = "GET_CADASTRE_FAIL" ;
export const DELETE_CADASTRE  = "DELETE_CADASTRE" ;

export const GET_GEOPORTAIL_REQUEST = "GET_GEOPORTAIL_REQUEST" ;
export const GET_GEOPORTAIL_SUCESS = "GET_GEOPORTAIL_SUCESS" ;
export const GET_GEOPORTAIL_FAIL = "GET_GEOPORTAIL_FAIL" ;
export const DELETE_GEOPORTAIL  = "DELETE_GEOPORTAIL" ;

export const GET_GEOPORTAIL5065_REQUEST = "GET_GEOPORTAIL5065_REQUEST" ;
export const GET_GEOPORTAIL5065_SUCESS = "GET_GEOPORTAIL5065_SUCESS" ;
export const GET_GEOPORTAIL5065_FAIL = "GET_GEOPORTAIL5065_FAIL" ;
export const DELETE_GEOPORTAIL5065  = "DELETE_GEOPORTAIL5065" ;

export const GET_GEOPORTAIL0005_REQUEST = "GET_GEOPORTAIL0005_REQUEST" ;
export const GET_GEOPORTAIL0005_SUCESS = "GET_GEOPORTAIL0005_SUCESS" ;
export const GET_GEOPORTAIL0005_FAIL = "GET_GEOPORTAIL0005_FAIL" ;
export const DELETE_GEOPORTAIL0005  = "DELETE_GEOPORTAIL0005" ;

export const GET_GEOPORTAIL0610_REQUEST = "GET_GEOPORTAIL0610_REQUEST" ;
export const GET_GEOPORTAIL0610_SUCESS = "GET_GEOPORTAIL0610_SUCESS" ;
export const GET_GEOPORTAIL0610_FAIL = "GET_GEOPORTAIL0610_FAIL" ;
export const DELETE_GEOPORTAIL0610  = "DELETE_GEOPORTAIL0610" ;

export const GET_GEOPORTAIL1115_REQUEST = "GET_GEOPORTAIL1115_REQUEST" ;
export const GET_GEOPORTAIL1115_SUCESS = "GET_GEOPORTAIL1115_SUCESS" ;
export const GET_GEOPORTAIL1115_FAIL = "GET_GEOPORTAIL1115_FAIL" ;
export const DELETE_GEOPORTAIL1115  = "DELETE_GEOPORTAIL1115" ;

export const GET_POST_HTA_REQUEST = "GET_POST_HTA_REQUEST";
export const GET_POST_HTA_SUCCESS = "GET_POST_HTA_SUCCESS";
export const GET_POST_HTA_FAIL = "GET_POST_HTA_FAIL";
export const DELETE_POST_HTA = "DELETE_POST_HTA";

export const GET_CABLE_HTA_UNDERGROUND_REQUEST = "GET_CABLE_HTA_UNDERGROUND_REQUEST";
export const GET_CABLE_HTA_UNDERGROUND_SUCCESS = "GET_CABLE_HTA_UNDERGROUND_SUCCESS";
export const GET_CABLE_HTA_UNDERGROUND_FAIL = "GET_CABLE_HTA_UNDERGROUND_FAIL";
export const DELETE_CABLE_UNDERGROUND_HTA = "DELETE_CABLE_UNDERGROUND_HTA";

export const GET_CABLE_HTA_AERIAL_REQUEST = "GET_CABLE_HTA_AERIAL_REQUEST";
export const GET_CABLE_HTA_AERIAL_SUCCESS = "GET_CABLE_HTA_AERIAL_SUCCESS";
export const GET_CABLE_HTA_AERIAL_FAIL = "GET_CABLE_HTA_AERIAL_FAIL";
export const DELETE_CABLE_AERIAL_HTA = "DELETE_CABLE_AERIAL_HTA";

export const GET_CABLE_BT_UNDERGROUND_REQUEST = "GET_CABLE_BT_UNDERGROUND_REQUEST";
export const GET_CABLE_BT_UNDERGROUND_SUCCESS = "GET_CABLE_BT_UNDERGROUND_SUCCESS";
export const GET_CABLE_BT_UNDERGROUND_FAIL = "GET_CABLE_BT_UNDERGROUND_FAIL";
export const DELETE_CABLE_UNDERGROUND_BT = "DELETE_CABLE_UNDERGROUND_BT";

export const GET_CABLE_BT_AERIAL_REQUEST = "GET_CABLE_BT_AERIAL_REQUEST";
export const GET_CABLE_BT_AERIAL_SUCCESS = "GET_CABLE_BT_AERIAL_SUCCESS";
export const GET_CABLE_BT_AERIAL_FAIL = "GET_CABLE_BT_AERIAL_FAIL";
export const DELETE_CABLE_AERIAL_BT = "DELETE_CABLE_AERIAL_BT";

export const IS_WIND_ACTIVE = "IS_WIND_ACTIVE";
export const DELETE_WIND = "DELETE_WIND";

export const IS_SNOW_ACTIVE = "IS_SNOW_ACTIVE";
export const DELETE_SNOW = "DELETE_SNOW";

export const IS_EARTHQUAKE_ACTIVE = "IS_EARTHQUAKE_ACTIVE";
export const DELETE_EARTHQUAKE = "DELETE_EARTHQUAKE";



