import React, {useCallback, useEffect, useRef, useState} from 'react';
import {GoogleMap} from '@react-google-maps/api';
import {registerMapInformation} from "../../_actions"
import {useDispatch, useSelector} from "react-redux"
import Rectangle from './Rectangle';
import Modules from './Modules';
import PolylineCableBt from './PolylineCableBt';
import MyInfoWindow from './MyInfoWindow';
import PolylineCableHta from './PolylineCableHta';
import MarkerPosteHta from './MarkerPosteHta';
import PolylineCadastre from './PolylineCadastre';
import MeasureRuler from "./MeasureRuler";
import SearchAutocomplete from './SearchAutocomplete';
import Geoportail from "./Geoportail";
import Geoportail5065 from "./Geoportail5065";
import Geoportail0005 from "./Geoportail0005";
import Geoportail0610 from "./Geoportail0610";
import Geoportail1115 from "./Geoportail1115";
import Trench from "./Trench"
import CharginPointMarker from "./CharginPointMarker";
import DisplayScreenMarker from "./DisplayScreenMarker";
import EnergyMeterMarker from "./EnergyMeterMarker";
import LocalInverterMarker from "./LocalInverterMarker";
import RoofInstallationMarker from "./RoofInstallationMarker";
import SolarPanelMarker from "./SolarPanelMarker";
import WriteText from "./WriteText";
import {useReactToPrint} from "react-to-print";
import Logo from "../../assets/logo.png";
import Grid from "@material-ui/core/Grid";

function MyGoogleMap() {
    // selectors
    const reduxMap = useSelector(state => state.mapReducer)

    // ###### Local State  #######
    const [map, setMap] = useState(null)
    const dispatch = useDispatch()
    const mapRef = useRef(null);
    const [positionInfoWindow, setPositionInfoWindow] = useState(null);

    // ###### Functions  #######

    // on load maps
    const onLoad = useCallback(function callback(map) {
        setMap(map)
        mapRef.current = map;
    }, [])

    // onUnmout map
    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    /**
     * Change Drag/zoom Map
     */
    const handleDragEndZoomChanged = (map) => {
        if (map?.getCenter() !== undefined) {
            const bounds = map?.getBounds()?.toJSON();
            const coordinates = [[bounds?.east, bounds?.north], [bounds?.east, bounds?.south], [bounds?.west, bounds?.south], [bounds?.west, bounds?.north], [bounds?.east, bounds?.north]];
            let geoFilterPolygon = {
                point1: [bounds?.north, bounds?.east],
                point2: [bounds?.south, bounds?.east],
                point3: [bounds?.south, bounds?.west],
                point4: [bounds?.north, bounds?.west]
            };

            geoFilterPolygon = JSON.stringify(geoFilterPolygon)
            geoFilterPolygon = geoFilterPolygon.replace(/\[/g, '"(')
            geoFilterPolygon = geoFilterPolygon.replace(/\]/g, ')"')

            dispatch(registerMapInformation({
                center: map?.getCenter().toJSON(),
                zoom: map?.getZoom(),
                geoJson: {type: "Polygon", coordinates: [coordinates]},
                geoFilterPolygon: JSON.parse(geoFilterPolygon)
            }));
        }
    }

    const onClickPolyline = useCallback((point, polylineType) => {
        console.log("$$$$ Use calback");
        if (polylineType === 'enedisLineUndergroundHTA') {
            setPositionInfoWindow({
                position: {lat: point.latLng.lat(), lng: point.latLng.lng()},
                title: "Ligne HTA souterraine"
            });
        } else if (polylineType === 'enedisLineAerialHTA') {
            setPositionInfoWindow({
                position: {lat: point.latLng.lat(), lng: point.latLng.lng()},
                title: "Ligne HTA aérienne"
            });
        } else if (polylineType === 'enedisLineAerialBT') {
            setPositionInfoWindow({
                position: {lat: point.latLng.lat(), lng: point.latLng.lng()},
                title: "Ligne BT aérienne"
            });
        } else if (polylineType === 'enedisLineUndergroundBT') {
            setPositionInfoWindow({
                position: {lat: point.latLng.lat(), lng: point.latLng.lng()},
                title: "Ligne BT souterraine"
            });
        }
    }, [setPositionInfoWindow]);

    let printButton = document.getElementById("print-pdf");
    const pageStyle = `@page {size: 550mm 300mm;}`;
    const google = window.google;
    const elevator = new google.maps.ElevationService();

    const reduxUser = useSelector(state => state.userReducer.user);
    const addressName = useSelector(state => state.headerProjectReducer?.addressProject?.completeAddress);
    const power = useSelector(state => state.headerProjectReducer?.power);
    const productible = useSelector(state => state.headerProjectReducer?.productible);
    const gps = useSelector(state => state.headerProjectReducer?.addressProject?.location);

    const [elevation, setElevation] = useState("");
    const [modulesPower, setModulesPower] = useState(0);

    useEffect(() => {
        if (power && power.length > 0) {
            let totPower = 0;
            power.forEach((subPower, index) => {
                if (Array.isArray(subPower))
                    totPower += power[index].reduce((partialSum, a) => partialSum + parseFloat(a), 0);
                else
                    totPower += parseFloat(power[index]);
            });
            totPower = parseFloat(totPower.toFixed(1));
            setModulesPower(totPower);
        } else
            setModulesPower(0);
    }, [power]);

    function displayLocationElevation(location, elevator) {
        let elevationVar;
        return elevator.getElevationForLocations(
            {locations: [location],},
            (results, status) => {
                if (status === "OK" && results) {
                    if (results[0]) {
                        elevationVar = results[0].elevation.toFixed(2) + " m";
                    } else {
                        elevationVar = "0 m";
                    }
                } else {
                    elevationVar = "Erreur";
                }
            }
        );
    }

    const addUserInfo = () => {
        document.getElementById("page-break").style.display = "block";
        document.getElementById("auto-complete").style.display = "none";
        if (gps) {
            displayLocationElevation({"lat": gps.lat, "lng": gps.lng}, elevator).then(
                value => {
                    setElevation(value.results[0].elevation.toFixed(2));
                    console.log("elevation", elevation);
                }
            );
        }
    };

    const removeUserInfo = () => {
        document.getElementById("page-break").style.display = "none";
        document.getElementById("auto-complete").style.display = "block";
    };

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "gammaPV",
        onBeforeGetContent: addUserInfo,
        onAfterPrint: removeUserInfo,
    });

    return (
        <div id="My-map-print" ref={componentRef}>
            <GoogleMap
                id="google-map"
                mapContainerStyle={mapStyles}
                onLoad={onLoad}
                onUnmount={onUnmount}
                zoom={reduxMap.map ? reduxMap.map.zoom : 6}
                center={reduxMap.map ? reduxMap.map.center : {lat: 46.47789028028652, lng: 3.2605458094376605}}
                options={{rotateControl: false, mapTypeId: "satellite", scaleControl: true, fullscreenControl: false}}
                tilt={0}
                onZoomChanged={() => {
                    handleDragEndZoomChanged(map)
                }}
                onDragEnd={() => {
                    handleDragEndZoomChanged(map)
                }}
            >
                <div id="page-break" style={{
                    display: "none",
                    color: "#000",
                    boxSizing: `border-box`,
                    padding: "10px",
                    width: `100%`,
                    height: '150px',
                    fontSize: `18px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: "absolute",
                    bottom: "0%",
                    backgroundColor: "#fff"
                }}>
                    <Grid container justifyContent="flex-start">
                        <Grid item xs={1}>
                            <img src={reduxUser.company.logo} width="100" height="100" alt="companyLogo"/>
                        </Grid>
                        <Grid item xs={2} style={{marginTop: "30px"}}>
                            <b> Adresse: </b> <span>{addressName ? addressName : "Non renseignée"}</span>
                        </Grid>
                        <Grid item xs={2} style={{marginTop: "30px"}}>
                            <b> Puissance: </b> <span>{modulesPower ? modulesPower : "0"} Kwc</span>
                        </Grid>
                        <Grid item xs={2} style={{marginTop: "30px"}}>
                            <b> Productible: </b> <span>{productible ? productible : "0"} kWh/kWc/an</span>
                        </Grid>
                        <Grid item xs={2} style={{marginTop: "30px"}}>
                            <b> Coordonnées GPS: </b>
                            <span>{gps ? gps.lat.toFixed(2) + " , " + gps.lng.toFixed(2) : "0,0"}</span>
                        </Grid>
                        <Grid item xs={2} style={{marginTop: "30px"}}>
                            <b> Altitude: </b> <span>{elevation} m</span>
                        </Grid>
                        <Grid item>
                            <img src={Logo} width="100" height="100" alt="Logo"/>
                        </Grid>
                    </Grid>
                </div>
                <style>{pageStyle}</style>
                {printButton.onclick = handlePrint}
                <Rectangle/>
                <MeasureRuler map={map}/>
                <Modules/>
                <PolylineCableBt onClickPolyline={onClickPolyline}/>
                <PolylineCableHta onClickPolyline={onClickPolyline}/>
                <PolylineCadastre map={map}/>
                <MarkerPosteHta/>
                <Geoportail map={map}/>
                <Geoportail5065 map={map}/>
                <Geoportail0005 map={map}/>
                <Geoportail0610 map={map}/>
                <Geoportail1115 map={map}/>
                <Trench map={map}/>
                <CharginPointMarker map={map}/>
                <DisplayScreenMarker map={map}/>
                <EnergyMeterMarker map={map}/>
                <LocalInverterMarker map={map}/>
                <RoofInstallationMarker map={map}/>
                <SolarPanelMarker map={map}/>
                <WriteText map={map}/>
                <MyInfoWindow positionInfoWindow={positionInfoWindow} setPositionInfoWindow={setPositionInfoWindow}/>
                <SearchAutocomplete/>
            </GoogleMap>
        </div>
    );
}

/* API météo
<DlubalApi map={map}/>
*/


export default MyGoogleMap

const mapStyles = {
    position: "absolute",
    top: "140px",
    left: "90px",
    right: "90px",
    bottom: "60px",
    border: "2px solid #FF9300"
};