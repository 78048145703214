import {EMPTY_STORE, IS_WIND_ACTIVE} from "../_types";

const initialState = {
    wind: undefined,
}

const windReducer = (state = initialState , action) => {
    switch (action.type) {
        case IS_WIND_ACTIVE:
            return {
                ...state,
                wind: action.payload
            }
        case EMPTY_STORE:
            return initialState;
        default :
            return {...state};
    }
}

export default windReducer;
