import {
    RECTANGLE_ID_SELECTED,
    CALCULATE_MODULES_OF_RECTANGLE,
    SAVE_FORMULAIRE_INFORMATION,
    DELETE_RECTANGLE,
    DUPLICATE_RECTANGLE,
    EMPTY_STORE,
    ADD_TO_REDUX_FROM_DB,
    DELETE_BOOL,
    INDEX_TO_DELETE,
    CREATION_OF_REQUIREMENT,
    UNDO_MODULES,
    FIX_MODULES
} from "../_types"

const initialState = {
    rectangleIdSecelted: undefined,// for active tools
    modules: [],
    forms: [],
    deleteBool: false,
    indexToDelete: undefined,
    undo: []
}

const modulesReducer = (state = initialState, action) => {
    let exist = false;
    let stateModules = [];
    let stateForms = [];
    let newRectangleModules = [];
    let newRectangleForm = [];
    let undoState;

    switch (action.type) {
        case FIX_MODULES:
            stateModules = [...state.modules];
            if (stateModules.length === 0) return {...state}
            stateModules.forEach((module, index) => {
                module.idRectangle = index
            });

            stateForms = [...state.forms];
            stateForms.forEach((form, index) => {
                form.idRectangle = index;
                form.form.idRectangle = index;
            });

            undoState = {
                ...state,
                modules: structuredClone(stateModules),
                forms: structuredClone(stateForms)
            };

            return {
                ...state,
                modules: stateModules,
                forms: stateForms,
                undo: [...state.undo, undoState]
            };

        case CREATION_OF_REQUIREMENT:
            return {
                ...state,
                modules: [...state.modules, {idRectangle: action.payload, modules: []}],
                forms: [...state.forms, {idRectangle: action.payload, form: {}}],
                undo: [...state.undo, {...state}]
            };

        case RECTANGLE_ID_SELECTED:
            return {
                ...state,
                rectangleIdSecelted: action.payload
            };

        case CALCULATE_MODULES_OF_RECTANGLE:
            stateModules = state.modules.map((item) => ({...item}));

            stateModules.forEach((item, index) => {
                if (action.idRectangle === item.idRectangle) {
                    exist = true;
                    stateModules[index] = {idRectangle: action.idRectangle, modules: action.payload};
                }
            });

            if (!exist) {
                if (Array.isArray(action.payload[0][0])) {
                    stateModules.push({
                        idRectangle: action.idRectangle,
                        modules: [action.payload[0], action.payload[1]]
                    });
                } else {
                    stateModules.push({idRectangle: action.idRectangle, modules: action.payload});
                }
            }

            if (action.undoable) {
                undoState = {
                    ...state,
                    modules: structuredClone(stateModules),
                    forms: structuredClone(state.forms)
                };

                return {
                    ...state,
                    modules: stateModules,
                    undo: [...state.undo, undoState]
                };
            }

            return {
                ...state,
                modules: stateModules,
            };

        case SAVE_FORMULAIRE_INFORMATION:
            stateForms = state.forms.map((item) => ({...item}));

            exist = false;
            stateForms.forEach((item, index) => {
                if (action.idRectangle === item.idRectangle) {
                    exist = true;
                    stateForms[index] = {idRectangle: structuredClone(action.idRectangle), form: structuredClone(action.payload)};
                }
            });

            if (!exist) {
                stateForms.push({idRectangle: action.idRectangle, form: action.payload});
            }

            undoState = {
                ...state,
                modules: structuredClone(state.modules),
                forms: structuredClone(stateForms)
            };

            return {
                ...state,
                forms: stateForms,
                undo: [...state.undo, undoState]
            };

        case DELETE_RECTANGLE:
            stateModules = [...state.modules];
            newRectangleModules = stateModules.filter(module => module.idRectangle !== action.payload);
            if (newRectangleModules.length === stateModules.length) return {...state};

            stateForms = [...state.forms];
            newRectangleForm = stateForms.filter(form => form.idRectangle !== action.payload);

            undoState = {
                ...state,
                rectangleIdSecelted: undefined,
                modules: structuredClone(newRectangleModules),
                forms: structuredClone(newRectangleForm)
            };

            return {
                ...state,
                rectangleIdSecelted: undefined,
                modules: newRectangleModules,
                forms: newRectangleForm,
                undo: [...state.undo, undoState]
            };

        case DUPLICATE_RECTANGLE:
            console.log('DUPLICATION DE : ', action.payload)
            stateModules = [...state.modules];
            newRectangleModules = stateModules.filter(module => module.idRectangle === action.payload);
            if (newRectangleModules.length > 0) {
                newRectangleModules = {...newRectangleModules[0]};
                newRectangleModules.idRectangle = stateModules.length;
            } else return {...state};
            console.log('MODULE DUPLIQUER : ', newRectangleModules)

            stateForms = [...state.forms];
            newRectangleForm = stateForms.filter(form => form.idRectangle === action.payload);
            if (newRectangleForm.length > 0) {
                newRectangleForm = {...newRectangleForm[0]};
                newRectangleForm.idRectangle = stateForms.length;
            }
            console.log('FORM DUPLIQUER : ', newRectangleForm)

            undoState = {
                ...state,
                modules: structuredClone([...state.modules, newRectangleModules]),
                forms: structuredClone([...state.forms, newRectangleForm])
            };

            return {
                ...state,
                modules: [...state.modules, structuredClone(newRectangleModules)],
                forms: [...state.forms, structuredClone(newRectangleForm)],
                undo: [...state.undo, undoState]
            };

        case ADD_TO_REDUX_FROM_DB:
            return {
                ...state,
                modules: action.modules,
                forms: action.forms
            };

        case DELETE_BOOL: {
            return {
                ...state,
                deleteBool: action.bool
            };
        }

        case INDEX_TO_DELETE: {
            return {
                ...state,
                indexToDelete: {index: action.index, latLng: action.latLng}
            };
        }

        case EMPTY_STORE:
            return initialState;

        case UNDO_MODULES :
            const undo = state.undo[state.undo.length - 2];
            console.log("UNDO MODULES", undo, state.undo);

            if (undo) {
                let limitedUndo = state.undo.slice(0, -1);
                if (state.undo.length > 10)
                    limitedUndo = state.undo.slice(-10, -1); // Limit to the last 10 entries

                return {
                    ...state,
                    rectangleIdSecelted: undo.rectangleIdSecelted,
                    modules: structuredClone(undo.modules),
                    forms: structuredClone(undo.forms),
                    indexToDelete: undefined,
                    undo: limitedUndo
                };
            }

            return {...state};

        default :
            return state;
    }
}

export default modulesReducer;