import {
    GET_GEOPORTAIL5065_REQUEST,
    GET_GEOPORTAIL5065_SUCESS,
    GET_GEOPORTAIL5065_FAIL,
    DELETE_GEOPORTAIL5065,
    EMPTY_STORE
} from "../_types"

const initialState = {
    request: undefined,
    geoportail5065: undefined,
    data: undefined,
    fail: undefined,
}

const geoportail5065ApiReducer = (state = initialState , action) => {

    switch (action.type) {
        case GET_GEOPORTAIL5065_REQUEST:
            return {
                ...state,
                request: true
            }

        case GET_GEOPORTAIL5065_SUCESS:
            return {
                ...state,
                geoportail5065: action.payload
            }
        case GET_GEOPORTAIL5065_FAIL:
            return {
                ...state,
                fail:  action.payload
            }
        case DELETE_GEOPORTAIL5065:
            return {
                request: false,
                data: undefined,
                fail: undefined,
            }

        case EMPTY_STORE:
            return initialState;
        default :
            return state;
    }
}

export default geoportail5065ApiReducer;