import React, {useEffect} from 'react';
import {getGeoportail, getGeoportail0005, getGeoportail0610, getGeoportail1115, getGeoportail5065} from "../_actions";
import {useDispatch} from "react-redux";
import {Paper} from "@material-ui/core";
import Select from "react-select";

function GeoCardApi({geoportail, setGeoportail,
                     geoportail5065, setGeoportail5065,
                     geoportail0005, setGeoportail0005,
                     geoportail0610, setGeoportail0610,
                     geoportail1115, setGeoportail1115,
                     hidden}){

    const dispatch = useDispatch();

    useEffect( () => {
        console.log("geoportail", geoportail)
        if(geoportail){
            dispatch(getGeoportail(geoportail))
        }
    }, [geoportail])

    useEffect( () => {
        console.log("geoportail5065", geoportail5065)
        if(geoportail5065){
            dispatch(getGeoportail5065(geoportail5065))
        }
    }, [geoportail5065])

    useEffect( () => {
        console.log("geoportail0005", geoportail0005)
        if(geoportail0005){
            dispatch(getGeoportail0005(geoportail0005))
        }
    }, [geoportail0005])

    useEffect( () => {
        console.log("geoportail0610", geoportail0610)
        if(geoportail0610){
            dispatch(getGeoportail0610(geoportail0610))
        }
    }, [geoportail0610])

    useEffect( () => {
        console.log("geoportail1115", geoportail1115)
        if(geoportail1115){
            dispatch(getGeoportail1115(geoportail1115))
        }
    }, [geoportail1115])

    const options = [
        { value: 'clear', label: 'Google Maps' },
        { value: 'geo-latest', label: 'Geoportail' },
        { value: 'geo-15', label: 'Geoportail 2011-2015' },
        { value: 'geo-06/10', label: 'Geoportail 2006-2010' },
        { value: 'geo-00/05', label: 'Geoportail 2000-2005' },
        { value: 'geo-50/65', label: 'Geoportail 1950-1965' },
    ]

    const handleChange = (selectedOption) => {
        console.log("selected option", selectedOption)
        if(selectedOption.value === "geo-latest"){
            setGeoportail(true);
            setGeoportail5065(false);
            setGeoportail0005(false);
            setGeoportail0610(false);
            setGeoportail1115(false);
        }else if(selectedOption.value === "geo-15"){
            setGeoportail(false);
            setGeoportail5065(false);
            setGeoportail0005(false);
            setGeoportail0610(false);
            setGeoportail1115(true);
        }else if(selectedOption.value === "geo-06/10"){
            setGeoportail(false);
            setGeoportail5065(false);
            setGeoportail0005(false);
            setGeoportail0610(true);
            setGeoportail1115(false);
        }else if(selectedOption.value === "geo-00/05"){
            setGeoportail(false);
            setGeoportail5065(false);
            setGeoportail0005(true);
            setGeoportail0610(false);
            setGeoportail1115(false);
        }else if(selectedOption.value === "geo-50/65"){
            setGeoportail(false);
            setGeoportail5065(true);
            setGeoportail0005(false);
            setGeoportail0610(false);
            setGeoportail1115(false);
        }else if(selectedOption.value === "clear"){
            setGeoportail(false);
            setGeoportail5065(false);
            setGeoportail0005(false);
            setGeoportail0610(false);
            setGeoportail1115(false);
        }
    }

    return (
        <Paper elevation={2} style={{display: hidden === true ? 'none' : 'flex', flexDirection: 'column', justifyContent:'center',  width: 200, height: 70, marginLeft: 10, marginTop: 30}}>
            <div style={{paddingLeft: 10, paddingRight: 10}}>
                <Select
                    options={options}
                    onChange={handleChange}
                />
            </div>
        </Paper>
    )

}
export default React.memo(GeoCardApi);