import {
    GET_GEOPORTAIL1115_REQUEST,
    GET_GEOPORTAIL1115_SUCESS,
    GET_GEOPORTAIL1115_FAIL,
    DELETE_GEOPORTAIL1115,
    EMPTY_STORE
} from "../_types"

const initialState = {
    request: undefined,
    geoportail1115: undefined,
    data: undefined,
    fail: undefined,
}

const geoportail1115ApiReducer = (state = initialState , action) => {

    switch (action.type) {
        case GET_GEOPORTAIL1115_REQUEST:
            return {
                ...state,
                request: true
            }

        case GET_GEOPORTAIL1115_SUCESS:
            return {
                ...state,
                geoportail1115: action.payload
            }
        case GET_GEOPORTAIL1115_FAIL:
            return {
                ...state,
                fail:  action.payload
            }
        case DELETE_GEOPORTAIL1115:
            return {
                request: false,
                data: undefined,
                fail: undefined,
            }

        case EMPTY_STORE:
            return initialState;
        default :
            return state;
    }
}

export default geoportail1115ApiReducer;