import {
    EMPTY_STORE,
    GET_GHI,
    GET_PRODUCTIBLE,
    REORIENTE_RECTANGLE,
    SAVE_ADDRESS_PROJECT,
    SAVE_ID_PROJECT,
    SAVE_MODULES_AND_POWER,
    SAVE_PROJECT_NAME,
    SET_ORIENTATION
} from "../_types"

const initialState = {
    idProject: undefined,
    orientation: undefined,
    addressProject: undefined,
    projectName: undefined,
    modules: undefined,
    power: undefined,
    productible: undefined,
    reorienteRectangle: undefined,
    ghi: undefined,
};

const headerProjectReducer = (state = initialState , action) => {
    switch (action.type) {
        case SAVE_ID_PROJECT:
            return {
                ...state,
                idProject: action.id
            };

        case SET_ORIENTATION:
            return {
                ...state,
                orientation: action.payload
            };

        case SAVE_ADDRESS_PROJECT:
            return {
                ...state,
                addressProject: action.payload
            };

        case SAVE_PROJECT_NAME:
            return {
                ...state,
                projectName: action.payload
            };

        case SAVE_MODULES_AND_POWER:
            return {
                ...state,
                modules: action.payload.modulesTab,
                power: action.payload.powerTab
            };

        case GET_PRODUCTIBLE: 
            return {
                ...state,
                productible: action.payload
            };

        case GET_GHI:
            return {
                ...state,
                ghi: action.payload
            };

        case REORIENTE_RECTANGLE:
            return {
                ...state,
                orientation: action.payload[1],
                reorienteRectangle: action.payload
            };

        case EMPTY_STORE: 
            return initialState;

        default :
            return state;
    }
}

export default headerProjectReducer;