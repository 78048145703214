import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {displayScreenActive} from "../../_actions";

function DisplayScreenMarker(props){

    const dispatch = useDispatch();

    const displayScreen = useSelector(state => state.displayScreenReducer.displayScreen , shallowEqual)
    const google = window.google;
    const map = props.map;
    let displayScreenMarker;
    let dragDisplayScreenMarkerListener;
    let clickDisplayScreenMarkerListener;
    let DisplayScreenImage = 'https://i.ibb.co/9Y8063W/Picto-Ecran.png';

    if(map !== null){
        if(displayScreen){
            displayScreenMarker = new google.maps.Marker({
                position: map.getCenter(),
                icon: {
                    url:DisplayScreenImage,
                },
                map: map
            });
            dragDisplayScreenMarkerListener = google.maps.event.addListener(map, 'mousemove', function (e) {
                let lat;
                const zoom = map.getZoom();
                if(zoom > 20){
                    lat = e.latLng.lat() - 0.0001 / map.getZoom()
                }else if(zoom === 20){
                    lat = e.latLng.lat() - 0.0005 / map.getZoom()
                }else if (zoom > 15 && zoom < 20){
                    lat = e.latLng.lat() - 0.001 / map.getZoom()
                }else if (zoom > 10 && zoom <= 15){
                    lat = e.latLng.lat() - 0.01 / map.getZoom()
                }
                else{
                    lat = e.latLng.lat() - 0.1 / map.getZoom();
                }
                const lng = e.latLng.lng();
                displayScreenMarker.setPosition({lat:lat,lng:lng});
                //displayScreenMarker.setPosition(e.latLng);
            });
            clickDisplayScreenMarkerListener = google.maps.event.addListener(displayScreenMarker, 'click', function (e) {
                google.maps.event.removeListener(dragDisplayScreenMarkerListener);
            });
            google.maps.event.addListener(displayScreenMarker, 'dblclick', function (e) {
                displayScreenMarker.setMap(null);
            });
            dispatch(displayScreenActive(false));
        }
        else{
            google.maps.event.removeListener(clickDisplayScreenMarkerListener);
        }
    }

    return(
        <div>
            {displayScreen}
        </div>
    );
}

export default React.memo(DisplayScreenMarker);