import {useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/core/styles';
import logo from "../assets/logo.png"
import {MenuItem, Menu} from '@material-ui/core';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {EMPTY_STORE} from "../_types"
import {saveIdProject} from '../_actions/headerProjectAction';
import {changeMapKey} from '../_actions/mapAction';
import uuid from 'react-uuid'
import {EMPTY_USER_STORE} from '../_types/userType';
import {springboot} from "../config/api";
import usePOST from "../hooks/usePOST";

export default function Header() {

    const reduxUser = useSelector(state => state.userReducer.user);
    const {response, error: errorPost, sendPostRequest: setRequest} = usePOST();

    const dispatch = useDispatch();

    const classes = useStyles();
    // local state
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    let navigate = useNavigate();

    // Functions
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const viderStoreRedux = () => {
        dispatch({type: EMPTY_STORE})
        dispatch(saveIdProject(uuid()))
    }

    const changeMapKeyRedux = () => {
        dispatch(changeMapKey())
    }

    // Logout
    const logout = () => {
        setRequest({
            url: `/api/users/signout`,
            data: {},
            headers: {Authorization: `Bearer ${reduxUser.token}`},
            api: springboot
        })
            .then(res => console.log("Logout complete " + res));

        localStorage.removeItem('userReducer');
        navigate('/');
        dispatch({type: EMPTY_USER_STORE})
    };

    return (
        <>
            <CssBaseline/>
            <AppBar position="static" color="inherit" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Grid container direction='row'>
                        <Grid className={classes.toolbarTitle}>
                            <Link
                                to='#'
                                onClick={() => {
                                    navigate('/home');
                                    viderStoreRedux()
                                }}>
                                <img src={logo} alt="Logo" style={{width: 80}}/>
                            </Link>
                        </Grid>
                        <Button size='medium'
                                variant="outlined"
                                onClick={() => {
                                    viderStoreRedux();
                                    changeMapKeyRedux();
                                    navigate('/project')
                                }}
                                className={classes.link}>
                            <Typography>
                                <b>NOUVEAU PROJET</b>
                            </Typography>
                        </Button>
                        <Button size='medium'
                                variant="outlined"
                                onClick={() => {
                                    navigate('/home');
                                    viderStoreRedux()
                                }}
                                className={classes.link}>
                            <Typography>
                                <b>VOS PROJETS</b>
                            </Typography>
                        </Button>
                        <Button size='medium'
                                variant="outlined"
                                onClick={handleMenu}
                                className={classes.link}>
                            <Typography>
                                <b>{reduxUser.firstname}{" "}{reduxUser.lastname}</b>
                            </Typography>
                        </Button>
                        <Menu anchorEl={anchorEl}
                              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                              keepMounted
                              transformOrigin={{vertical: 'top', horizontal: 'right'}}
                              open={open}
                              onClose={handleClose}>
                            {/*<MenuItem onClick={()=>{history.push('/Profile')}}>Mon profile</MenuItem>*/}
                            {/*<MenuItem onClick={()=> {history.push('/addUser')}}>Ajouter des utilisateurs</MenuItem>*/}
                            {/*<MenuItem onClick={()=>{history.push('/listOfUsers')}} >Liste des utilisateurs</MenuItem>*/}
                            <MenuItem onClick={() => {
                                logout()
                            }}>Déconnexion</MenuItem>
                        </Menu>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `2px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        marginTop: "20px",
        marginBottom: "20px",
        marginLeft: "10px",
        marginRight: "10px",
        display: 'flex', alignItems: 'center',
        color: '#000',
        borderRadius: "15px",
        borderColor: "#FF9300",
        '&:hover': {
            background: "#FFF2E0",
        },
    },
    linkNewProject: {
        margin: theme.spacing(1, 50),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor: "white",
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    typography: {color: '#000', padding: 8, fontWeight: 'bold', fontSize: 18},

}));