import { InfoWindow } from '@react-google-maps/api'
import React from 'react'
import uuid from 'react-uuid'

function MyInfoWindow({positionInfoWindow, setPositionInfoWindow}) {
    return (
        <div>
            {positionInfoWindow && 
            <InfoWindow key={ uuid() } position={positionInfoWindow.position} onCloseClick={()=> setPositionInfoWindow(undefined)} >
                <div>
                    <h5>{positionInfoWindow.title}</h5>
                </div>
            </InfoWindow>
            }
        </div>
    )
}

export default MyInfoWindow
