import {EMPTY_STORE, WRITE_TEXT_ACTIVE} from "../_types";

const initialState = {
    writeTexte: undefined,
}

const writeTextReducer = (state = initialState , action) => {
    switch (action.type) {
        case WRITE_TEXT_ACTIVE:
            return {
                ...state,
                writeTexte: action.payload
            }
        case EMPTY_STORE:
            return initialState;
        default :
            return {...state};
    }
}

export default writeTextReducer;