export const correctionPolygon = (polygon) => {
    const google = window.google;
    let coordinates = [];

    polygon.getPath().getArray().forEach((point) => {
        coordinates.push({lat :point.lat(), lng: point.lng()});
    })

    const p1 = new google.maps.LatLng({lat: coordinates[0].lat, lng: coordinates[0].lng});
    const p2 = new google.maps.LatLng({lat: coordinates[1].lat, lng: coordinates[1].lng});
    let p3 = new google.maps.LatLng({lat: coordinates[2].lat, lng: coordinates[2].lng});

    const heading = google.maps.geometry.spherical.computeHeading(p1, p2);

    p3 = google.maps.geometry.spherical.computeOffset(p2, google.maps.geometry.spherical.computeDistanceBetween(p2, p3), - ( 90 - heading));
    let p4 = google.maps.geometry.spherical.computeOffset(p3, google.maps.geometry.spherical.computeDistanceBetween(p1, p2), 180 + heading);

    return [{lat :p1.lat() , lng : p1.lng()} , {lat :p2.lat() , lng : p2.lng()}, {lat :p3.lat() , lng : p3.lng()}, {lat :p4.lat() , lng : p4.lng()}]
}