import {computeAreaPoint, computePoints, constructPolygonsWith2Areas} from "./UtilityFunctions";

function computeUnsymmetricalShed (instalationInfo) {

    /*
                        60m / 51m
      p1---------------------------------------p4
      |  pn1------------------------------pn4  |
      |  |                                 |   |
      |  pn2------------------------------pn3  |
      p6---------------------------------------p5  26m
      |  ps1------------------------------ps4  |
      |  |                                 |   |
      |  |                                 |   |
      |  |                                 |   |
      |  |                                 |   |
      |  ps2------------------------------ps3  |
      p2---------------------------------------p3

    */

    // google variable api
    const google = window.google;

    const margeBetweenModules = instalationInfo.margeBetweenModules; // 0.02m
    const inclination = instalationInfo.inclinaison; // 17°
    const margeBetweenSide = 0.1;
    const margeMiddleRidge = 0.5;

    const p1 = new google.maps.LatLng({
        lat: instalationInfo.coordinates[0].lat,
        lng: instalationInfo.coordinates[0].lng
    });
    const p2 = new google.maps.LatLng({
        lat: instalationInfo.coordinates[1].lat,
        lng: instalationInfo.coordinates[1].lng
    });
    const p3 = new google.maps.LatLng({
        lat: instalationInfo.coordinates[2].lat,
        lng: instalationInfo.coordinates[2].lng
    });
    const p4 = new google.maps.LatLng({
        lat: instalationInfo.coordinates[3].lat,
        lng: instalationInfo.coordinates[3].lng
    });

    // compute 4 different orientations
    const orientation12 = google.maps.geometry.spherical.computeHeading(p1, p2);
    const orientation21 = google.maps.geometry.spherical.computeHeading(p2, p1);
    const orientation23 = google.maps.geometry.spherical.computeHeading(p2, p3);
    const orientation32 = google.maps.geometry.spherical.computeHeading(p3, p2);

    // compute the middle point (p5 is not necessary because we don't need to compute pn3 or ps4)
    const p6 = new google.maps.LatLng(google.maps.geometry.spherical.computeOffset(p1, google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 5 , orientation12));

    // compute the first solar panel area (little one)
    let pn1 = computeAreaPoint(p1, margeBetweenSide, margeBetweenSide, orientation12, orientation23);
    let pn2 = computeAreaPoint(p6, margeMiddleRidge, margeBetweenSide, orientation21, orientation23);
    let pn4 = computeAreaPoint(p4, margeBetweenSide, margeBetweenSide, orientation21, orientation32);

    // size of the little one (north for reference)
    const sizeOfAreaNorth = {
        width: google.maps.geometry.spherical.computeDistanceBetween(pn1, pn2),
        length: google.maps.geometry.spherical.computeDistanceBetween(pn1, pn4)
    };

    // compute the second solar panel area (big one)
    let ps1 = computeAreaPoint(p6, margeMiddleRidge, margeBetweenSide, orientation12, orientation23);
    let ps2 = computeAreaPoint(p2, margeBetweenSide, margeBetweenSide, orientation21, orientation23);
    let ps3 = computeAreaPoint(p3, margeBetweenSide, margeBetweenSide, orientation12, orientation32);

    // size of the big one (South for reference)
    const sizeOfAreaSouth = {
        width: google.maps.geometry.spherical.computeDistanceBetween(ps1, ps2),
        length: google.maps.geometry.spherical.computeDistanceBetween(ps2, ps3)
    };

    console.log("Taille des zones de bases : ", sizeOfAreaNorth, sizeOfAreaSouth);

    // size of one module
    let sizeOfModule = {
        length: instalationInfo.height + margeBetweenModules, // 1.722,
        width: instalationInfo.width + margeBetweenModules // 1.134
    };

    console.log("Taille des modules calculé : ", sizeOfModule);

    // compute number of modules that fit in the north area
    const numberOfModuleNorth = {
        col: Math.floor(sizeOfAreaNorth.length / sizeOfModule.length),
        row: Math.floor((sizeOfAreaNorth.width / Math.cos(inclination * Math.PI /180).toFixed(2)) / sizeOfModule.width)
    };

    // compute number of modules that fit in the south area
    const numberOfModuleSouth = {
        col: Math.floor(sizeOfAreaSouth.length / sizeOfModule.length),
        row: Math.floor((sizeOfAreaSouth.width / Math.cos(inclination * Math.PI /180).toFixed(2)) / sizeOfModule.width)
    };

    console.log("Nombre de modules calculé : ", numberOfModuleNorth, numberOfModuleSouth);

    // compute the new size for the North area
    const newSizeOfAreaNorth = {
        width: numberOfModuleNorth.row * (sizeOfModule.width * Math.cos(inclination * Math.PI /180).toFixed(2)),
        length: numberOfModuleNorth.col * sizeOfModule.length
    };

    // compute the new size for the South area
    const newSizeOfAreaSouth = {
        width: numberOfModuleSouth.row * (sizeOfModule.width * Math.cos(inclination * Math.PI /180).toFixed(2)),
        length: numberOfModuleSouth.col * sizeOfModule.length
    };

    console.log("Taille des nouvelles zones : ", newSizeOfAreaNorth, newSizeOfAreaSouth);

    sizeOfModule = {
        length: newSizeOfAreaNorth.length / numberOfModuleNorth.col,
        width: newSizeOfAreaNorth.width / numberOfModuleNorth.row
    };

    // compute the new starting point (north)
    pn1 = computeAreaPoint(pn1, (sizeOfAreaNorth.width - newSizeOfAreaNorth.width) / 2, (sizeOfAreaNorth.length - newSizeOfAreaNorth.length) / 2, orientation12, orientation23);

    // compute the new starting point (south)
    ps1 = computeAreaPoint(ps1, (sizeOfAreaSouth.width - newSizeOfAreaSouth.width) / 2, (sizeOfAreaSouth.length - newSizeOfAreaSouth.length) / 2, orientation12, orientation23);

    // compute the coords of all the panels
    const northPanelCoords = constructPolygonsWith2Areas(computePoints(numberOfModuleNorth, sizeOfModule, pn1, {first: orientation23, second: orientation12}));
    const southPanelCoords = constructPolygonsWith2Areas(computePoints(numberOfModuleSouth, sizeOfModule, ps1, {first: orientation23, second: orientation12}));

    //console.log("Coords modules pan nord : ", northPanelCoords);
    //console.log("Coords modules pan sud : ", southPanelCoords);

    return [southPanelCoords, northPanelCoords];
}

export default computeUnsymmetricalShed;