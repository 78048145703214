import {Grid, makeStyles, Typography} from "@material-ui/core";
import NumericInput from "react-numeric-input";
import {useDispatch, useSelector} from "react-redux";
import React, {useState, useEffect, useRef} from 'react';
import {calcultaePowerAndModules} from "../business/calculatePowerAndModules";
import ProjectNameInput from "./ProjectNameInput";
import {saveModulesAndPower, setFixModules, setFixRetangles} from "../_actions";
import {reorienteRectangle} from "../_actions";
import ButtonRegisterProject from "./ButtonRegisterProject";

function usePrevious(data) {
    const ref = useRef();
    useEffect(() => {
        ref.current = data
    }, [data])
    return ref.current
}

const HeaderProject = () => {
    const reduxOrientation = useSelector(state => state.headerProjectReducer.orientation);
    const reduxModules = useSelector(state => state.modulesReducer.modules);
    const reduxForms = useSelector(state => state.modulesReducer.forms);
    const rectangleIdSecelted = useSelector(state => state.modulesReducer.rectangleIdSecelted);
    const productible = useSelector(state => state.headerProjectReducer.productible);
    const ghi = useSelector(state => state.headerProjectReducer.ghi);

    const [modulesAndPower, setModulesAndPower] = useState({modules: [], power: []});
    const [inclinaison, setInclinaison] = useState(undefined);

    const [orientationConverted, setOrientationConverted] = useState(0);

    const dispatch = useDispatch();

    const classes = useStyles();

    useEffect(() => {
        dispatch(setFixRetangles());
        dispatch(setFixModules());
    }, []);

    useEffect(() => {
        if (reduxOrientation < 0)
            setOrientationConverted(reduxOrientation + 360);
        else
            setOrientationConverted(reduxOrientation);
    }, [reduxOrientation]);

    useEffect(() => {
        const moduleAndPower = calcultaePowerAndModules(reduxModules, reduxForms);
        setModulesAndPower(moduleAndPower);
        dispatch(saveModulesAndPower(moduleAndPower));
    }, [reduxModules, reduxForms]);

    const onChangeOrientation = (value) => {
        if (value > 180)
            dispatch(reorienteRectangle([reduxOrientation, value - 360]));
        else
            dispatch(reorienteRectangle([reduxOrientation, value]));
        console.log(value, value - 360)
    }

    useEffect(() => {
        setInclinaison(reduxForms.find(form => form?.idRectangle === rectangleIdSecelted)?.form?.inclinaison);
    }, [rectangleIdSecelted, reduxForms]);

    return (
        <Grid container justifyContent='space-between' className={classes.containerSecondHeader}>
            <Grid item lg={'auto'} md={'auto'} className={classes.gridSecondHeader}>
                <ProjectNameInput/>
                <ButtonRegisterProject/>
            </Grid>
            <Grid item lg={'auto'} md={'auto'} className={classes.gridSecondHeader}>
                <Typography className={classes.typography}>
                    {modulesAndPower?.modules}
                </Typography>
                <Typography className={classes.typography}>
                    Modules
                </Typography>
                <Typography className={classes.typography}>
                    ,
                </Typography>
                <Typography className={classes.typography}>
                    {modulesAndPower?.power}
                </Typography><Typography
                className={classes.typography}>
                KWc
            </Typography>
            </Grid>
            {/*<Grid item lg={'auto'} md={'auto'} className={classes.gridSecondHeader}>*/}
            {/*    <Typography  className={classes.typography}>GHI:</Typography><Typography className={classes.typography}>{ghi}</Typography><Typography  className={classes.typography}>kWh/m²</Typography>*/}
            {/*</Grid>*/}
            <Grid item lg={'auto'} md={'auto'} className={classes.gridSecondHeader}>
                <Typography className={classes.typography}>
                    Productible:
                </Typography>
                <Typography className={classes.typography}>
                    {productible ? productible : 0}
                </Typography>
                <Typography className={classes.typography}>
                    kWh/kWc/an
                </Typography>
            </Grid>
            <Grid item lg={'auto'} md={'auto'} className={classes.gridSecondHeader}>
                <Typography className={classes.typography}>
                    Inclinaison
                </Typography>
                <NumericInput
                    readOnly
                    min={0}
                    max={100}
                    value={inclinaison}
                    size={3}
                />
                <Typography className={classes.typography}
                            style={{paddingLeft: 10}}>
                    Orientation
                </Typography>
                <NumericInput
                    min={0}
                    max={360}
                    size={3}
                    value={orientationConverted}
                    onChange={onChangeOrientation}
                />
            </Grid>
        </Grid>
    )
}

export default React.memo(HeaderProject);

const useStyles = makeStyles(() => ({
        containerSecondHeader: {
            backgroundColor: '#fff'
        },
        gridSecondHeader: {
            display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 5
        },
        typography: {
            fontFamily: 'neutra-alt, sans-serif', fontSize: 15, paddingRight: 10
        },

    })
);