import { Grid } from '@material-ui/core'
import React from 'react'
import {Header} from "../components"
import ComingSoon from './ComingSoon'

function Profile() {
    return (
        <Grid container direction='column'>
            <Grid item>
                <Header/>
            </Grid>
            <Grid item style={{marginTop: 20}}>
                <ComingSoon/>
            </Grid>
        </Grid>
    )
}

export default Profile
