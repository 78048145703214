import {EMPTY_STORE, DISPLAY_SCREEN_ACTIVE} from "../_types";

const initialState = {
    displayScreen: undefined,
}

const displayScreenReducer = (state = initialState , action) => {
    switch (action.type) {
        case DISPLAY_SCREEN_ACTIVE:
            return {
                ...state,
                displayScreen: action.payload
            }
        case EMPTY_STORE:
            return initialState;
        default :
            return {...state};
    }
}

export default displayScreenReducer;