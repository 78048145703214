export const IS_DRAWING_TRENCH = "IS_DRAWING_TRENCH";
export const DELETE_TRENCH = "DELETE_TRENCH";

export const CHARGIN_POINT_ACTIVE = "CHARGIN_POINT_ACTIVE";
export const DELETE_CHARGIN_POINT = "DELETE_CHARGIN_POINT";

export const DISPLAY_SCREEN_ACTIVE = "DISPLAY_SCREEN_ACTIVE";
export const DELETE_DISPLAY_SCREEN = "DELETE_DISPLAY_SCREEN";

export const ENERGY_METER_ACTIVE = "ENERGY_METER_ACTIVE";
export const DELETE_ENERGY_METER = "DELETE_ENERGY_METER";

export const LOCAL_INVERTER_ACTIVE = "LOCAL_INVERTER_ACTIVE";
export const DELETE_LOCAL_INVERTER = "DELETE_LOCAL_INVERTER";

export const ROOF_INSTALLATION_ACTIVE = "ROOF_INSTALLATION_ACTIVE";
export const DELETE_ROOF_INSTALLATION = "DELETE_ROOF_INSTALLATION";

export const SOLAR_PANEL_ACTIVE = "SOLAR_PANEL_ACTIVE";
export const DELETE_SOLAR_PANEL = "DELETE_SOLAR_PANEL";

export const WRITE_TEXT_ACTIVE = "WRITE_TEXT_ACTIVE";
export const DELETE_WRITE_TEXT = "DELETE_WRITE_TEXT";