import React from 'react'
import ComingSoon from './ComingSoon'

function RenewPassword() {
    return (
        <div>
           <ComingSoon/> 
        </div>
    )
}

export default RenewPassword
