import {Polygon} from '@react-google-maps/api'
import React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {saveIndexToDelete} from '../../_actions/modulesAction'

function Modules() {

    const modules = useSelector(state => state.modulesReducer.modules, shallowEqual)
    const deleteBool = useSelector(state => state.modulesReducer.deleteBool, shallowEqual)

    const dispatch = useDispatch();

    const onClickModule = (latlng, index) => {
        dispatch(saveIndexToDelete(latlng.latLng, index))
    }

    return (
        <div>
            {
                modules.map((module) => {
                    return (module?.modules?.map((polygon, i) => {
                        if (Array.isArray(polygon[0])) {
                            return polygon.map((nestedPolygon, j) => {
                                return (
                                    <Polygon
                                        key={j}
                                        paths={nestedPolygon}
                                        options={{
                                            draggable: false,
                                            clickable: deleteBool,
                                            fillColor: 'blue',
                                            zIndex: 2,
                                            fillOpacity: 0.5
                                        }}
                                        onClick={(latlng) => {onClickModule(latlng, j)}} />
                                )
                            });
                        } else
                            return (
                                <Polygon
                                    key={i}
                                    paths={polygon}
                                    options={{
                                        draggable: false,
                                        clickable: deleteBool,
                                        fillColor: 'blue',
                                        zIndex: 2,
                                        fillOpacity: 0.5
                                    }}
                                    onClick={(latlng) => {onClickModule(latlng, i)}} />
                            )
                    }))
                })
            }
        </div>
    )
}

export default React.memo(Modules)
