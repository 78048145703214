import {
    GET_GEOPORTAIL_REQUEST,
    GET_GEOPORTAIL_SUCESS,
    GET_GEOPORTAIL_FAIL,
    DELETE_GEOPORTAIL,
    EMPTY_STORE
} from "../_types"

const initialState = {
    request: undefined,
    geoportail: undefined,
    data: undefined,
    fail: undefined,
}

const geoportailApiReducer = (state = initialState , action) => {

    switch (action.type) {
        case GET_GEOPORTAIL_REQUEST:
            return {
                ...state,
                request: true
            }

        case GET_GEOPORTAIL_SUCESS:
            return {
                ...state,
                geoportail: action.payload
            }
        case GET_GEOPORTAIL_FAIL:
            return {
                ...state,
                fail:  action.payload
            }
        case DELETE_GEOPORTAIL:
            return {
                request: false,
                data: undefined,
                fail: undefined,
            }

        case EMPTY_STORE:
            return initialState;
        default :
            return state;
    }
}

export default geoportailApiReducer;