import {EMPTY_STORE, ENERGY_METER_ACTIVE} from "../_types";

const initialState = {
    energyMeter: undefined,
}

const energyMeterReducer = (state = initialState , action) => {
    switch (action.type) {
        case ENERGY_METER_ACTIVE:
            return {
                ...state,
                energyMeter: action.payload
            }
        case EMPTY_STORE:
            return initialState;
        default :
            return {...state};
    }
}

export default energyMeterReducer;